import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useDebounce } from "../../../../helpers/hooks";
import SelectInput from "../../../common/selectInput";
import Filter from "../filter";
import SelectTeam from "../../../selectTeam";
import styles from "../../listings.module.css";

export const ListingHead = ({
  isEmpty,
  setProfessionName,
  loading,
  openNewListing,
  listActiveDirectories,
  setListActiveDirectories,
  filters,
  listActiveListingType,
  setListActiveListingType,
  listActiveSponsorOptions,
  setListActiveSponsorOptions,
  setSort,
  sort,
  listingsListLength,
  appliedCount,
  isDraft,
  setIsDraft,
  setTeam,
  addListingText,
  listActiveChange,
  setListActiveChange,
  listActiveStatus,
  setListActiveStatus
}) => {
  const { t } = useTranslation();
  const { searchTerm, setSearchTerm, isHide } = useDebounce(setProfessionName, true);
  const isDisable = isEmpty && searchTerm === "" && !appliedCount && isHide && !loading;
  const [activeFilter, setActiveFilter] = useState(false);
  const disableAll = !listingsListLength && !appliedCount;

  const sortList = [
    { value: "desc", label: t("dashboard_listings_filter_latest") },
    { value: "asc", label: t("dashboard_listings_filter_oldest") }
  ];

  useEffect(() => {
    setSort(sortList[0]);
  }, []);

  return (
    <div className={styles.headerBox}>
      {!isDisable && (
        <div className={styles.searchField}>
          <button type="button" className={styles.searchFieldButton}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <circle cx="9.16683" cy="9.16671" r="5.83333" stroke="#ABAFB9" strokeWidth="1.6" />
              <path d="M16.6665 16.6666L14.1665 14.1666" stroke="#ABAFB9" strokeWidth="1.6" strokeLinecap="round" />
            </svg>
          </button>

          <input
            type="search"
            className={styles.inboxSearch}
            placeholder={t("dashboard_listings_search")}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            disabled={isDisable}
          />
        </div>
      )}

      <div className={styles.actions}>
        <SelectTeam handleChange={setTeam} unselectText="My Listing" />

        {!disableAll && (
          <SelectInput
            classNameWrap={styles.selectWrap}
            className={styles.select}
            name="latest"
            placeholder={t("dashboard_listings_filter_latest")}
            onChange={value => {
              setSort(value);
            }}
            options={sortList}
            value={sort}
          />
        )}

        <button type="button" className={styles.filterButton} onClick={() => setActiveFilter(true)}>
          Filters
          {appliedCount ? (
            <span className={styles.pin}>{appliedCount}</span>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M1.6665 5.625H18.3332" stroke="#1C1D40" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M4.375 10H15.625" stroke="#1C1D40" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M8.125 14.375H11.875" stroke="#1C1D40" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          )}
        </button>
        <div className={styles.headButton}>
          <button type="button" onClick={openNewListing} className={styles.buttonJoin}>
            Add Free {addListingText}
          </button>
        </div>
      </div>

      {activeFilter && (
        <Filter
          setActiveFilter={setActiveFilter}
          filters={filters}
          setListActiveDirectories={setListActiveDirectories}
          listActiveDirectories={listActiveDirectories}
          setListActiveListingType={setListActiveListingType}
          listActiveListingType={listActiveListingType}
          setListActiveSponsorOptions={setListActiveSponsorOptions}
          listActiveSponsorOptions={listActiveSponsorOptions}
          listActiveChange={listActiveChange}
          setListActiveChange={setListActiveChange}
          listActiveStatus={listActiveStatus}
          setListActiveStatus={setListActiveStatus}
          loading={loading}
          appliedCount={appliedCount}
          setIsDraft={setIsDraft}
          isDraft={isDraft}
        />
      )}
    </div>
  );
};
