import React from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";

import styles from "./pageNames.module.css";

const PageNames = ({ isListingsPage }) => {
  return (
    <div className={cn(styles.main, isListingsPage ? styles.mainListings : "")}>
      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/listings">
        Listings
      </NavLink>

      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/my-events">
        My Events
      </NavLink>

      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/my-organizations">
        My Organization Listings
      </NavLink>

      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/my-jobs">
        My Job Listings
      </NavLink>

      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/profile">
        My Profile
      </NavLink>
    </div>
  );
};

export default PageNames;
