import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../common/modal";

import { customStylesMultiMobile as selectStyle } from "../../../../constants/select";
import Checkbox from "../../../common/checkbox";
import ModalControl from "../../../modalControl";
import styles from "./filterMessage.module.css";

const FilterMessage = ({ onClose, activeListings, handleFilter, setFilter, filter, children }) => {
  const [activeListingsOptions, setActiveListingsOptions] = useState([]);
  const [isUnread, setIsUnread] = useState(false);
  const normalizeOptions = activeListings?.map(({ listing_id: value, title: label }) => ({ value, label }));
  const { t } = useTranslation();

  const onSelectListings = options => {
    if (options?.length) {
      setActiveListingsOptions(options);
    } else {
      setActiveListingsOptions([]);
    }
  };

  const onFilter = () => {
    handleFilter({ activeListings: activeListingsOptions.map(({ value }) => value), isUnread });
    setFilter({ activeListings: activeListingsOptions, isUnread });
    onClose();
  };

  useEffect(() => {
    if (filter.activeListings) {
      setActiveListingsOptions(filter.activeListings);
    }

    if (filter.isUnread) {
      setIsUnread(true);
    }
  }, [filter]);

  return (
    <Modal classNameMain={styles.modal} closeModal={onClose} clearAllModals={onClose}>
      <h2 className={styles.subTitle}>Filter Message</h2>
      <h1 className={styles.title}>{t("dashboard_proview_inbox_filtertitle")}</h1>

      <div className={styles.select}>
        <Select
          isMulti
          options={normalizeOptions}
          onChange={onSelectListings}
          placeholder="Select one or more listings"
          styles={selectStyle}
          value={activeListingsOptions}
        />
      </div>

      <h1 className={styles.title}>Filter By Message Type</h1>
      <Checkbox checked={isUnread} onChange={() => setIsUnread(prev => !prev)}>
        <span className={styles.checkboxValue}>Unread</span>
      </Checkbox>

      {children}

      <ModalControl onExit={onClose} onConfirm={onFilter} isClose>
        {t("dashboard_common_button_apply")}
      </ModalControl>
    </Modal>
  );
};

export default FilterMessage;
