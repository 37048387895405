import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { useTranslation } from "react-i18next";
import { apiKey } from "../../../constants/stripe";
import { Modal } from "../../common/modal";
import { AddCard } from "../../common/payment/addCard";
import emptyStateIcon from "../../../media/icons/payment-methods-empty.svg";
import { Button } from "../../common/button";
import stripeLogo from "../../../media/icons/stripe-logo.svg";
import styles from "../paymentMethods/paymentMethods.module.css";

const stripePromise = loadStripe(apiKey);

const EmptyState = ({
  defaultModals,
  setErrorToken,
  paymentSuccess,
  modals,
  setModal,
  addCard,
  clearTokenError,
  countries,
  errorToken,
  paymentMethods,
  paymentPreloaders
}) => {
  const { t } = useTranslation();

  return (
    <section className={styles.paymentMethod}>
      {modals.add && (
        <Elements stripe={stripePromise}>
          <Modal closeModal={() => setModal({ add: false })} clearAllModals={() => setModal({ ...defaultModals })}>
            <AddCard
              addCard={addCard}
              closeModal={() => setModal({ add: false })}
              clearTokenError={clearTokenError}
              countries={countries}
              errorToken={errorToken}
              isFirst={!paymentMethods?.length}
              paymentPreloaders={paymentPreloaders}
              paymentSuccess={paymentSuccess}
              setErrorToken={setErrorToken}
            />
          </Modal>
        </Elements>
      )}

      <div className={styles.sectionHeadingDesktop}>
        <h2 className="h2">{t("dashboard_payments_paymentmethods")}</h2>
      </div>

      <section className={styles.emptyState}>
        <img src={emptyStateIcon} alt="no cards added" />
        <h3 className="h3">{t("dashboard_payments_paymentmethodmsg")}</h3>

        <Button onClick={() => setModal({ add: true })} className={styles.emptyStateButton}>
          + {t("dashboard_payments_addcardbutton")}
        </Button>

        <div className="row flex-lg-row-reverse">
          <div className="show-tablet down-line mb30" />
          <div className="d-inline d-md-flex">
            <img className={styles.stripeIcon} src={stripeLogo} alt="Stripe" />
            <p className={styles.stripeDisclaimer}>{t("dashboard_payments_stripedesc")}</p>
          </div>
        </div>
      </section>
    </section>
  );
};

export default EmptyState;
