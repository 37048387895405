import React, { useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { Modal } from "../../../common/modal";
import { Button } from "../../../common/button";
import styles from "./changeEmailVerification.module.css";
import Input from "../../../common/input";
import { OverlayPreloader } from "../../../common/preloader";
import Counter from "../../../counter";
import ModalControl from "../../../modalControl";

const ChangeEmailVerification = ({
  checkEmailCode,
  clearAllModals,
  closeModal,
  handleResendCode,
  preloaders,
  openModal,
  newLoginData,
  cleanNewEmailPassword,
  verificationError,
  keycloak
}) => {
  const [showTimer, setShowTimer] = useState(false);
  const { t } = useTranslation();

  const validate = values => {
    const { code } = values;
    const errors = {};

    const requiredText = t("dashboard_common_required");

    if (!code) {
      errors.code = requiredText;
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      code: ""
    },
    onSubmit: () => {
      checkEmailCode(formik.values.code, newLoginData, keycloak);
    },
    validate
  });

  const { values, errors, touched, handleSubmit, setFieldTouched } = formik;

  const handleChange = e => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  const handleBlur = e => {
    setFieldTouched(e.target.name, true);
  };

  const handleBack = () => {
    openModal();
    closeModal();
  };

  const handleClose = () => {
    cleanNewEmailPassword();
    closeModal();
  };

  const handleRequestCode = () => {
    handleResendCode();
    setShowTimer(true);
  };

  return (
    <Modal classNameMain={styles.modal} clearAllModals={clearAllModals} closeModal={handleClose}>
      {preloaders.verifications && <OverlayPreloader />}

      <h2 className={styles.subTitle}>ACCOUNT SETTINGS</h2>
      <h1 className={styles.title}>Change Email</h1>
      <form onSubmit={handleSubmit}>
        <Input
          classNameWrap={styles.modalInput}
          error={errors.code}
          id="code"
          isInvalid={errors.code && touched.code}
          name="code"
          label={t("dashboard_home_verifyemail_modal_codeinput")}
          onBlur={handleBlur}
          onChange={handleChange}
          required
          value={values.code}
        />
      </form>
      {verificationError.checkEmailCode && <div className={styles.error}>THE CODE YOU HAVE ENTERED IS INCORRECT!</div>}
      {verificationError.changeEmail && <div className={styles.error}>THE EMAIL OR PASSWORD YOU HAVE ENTERED IS INCORRECT!</div>}

      <Counter setShowTimer={setShowTimer} showTimer={showTimer}>
        <Button className={styles.resendBtn} clearStyles onClick={handleRequestCode}>
          {t("dashboard_home_verifyemail_modal_resendbtn")}
        </Button>
      </Counter>

      <ModalControl onExit={handleBack} onConfirm={handleSubmit} isExit>
        {t("dashboard_common_savechanges")}
      </ModalControl>
    </Modal>
  );
};

export default ChangeEmailVerification;
