import axios from "axios";
import queryString from "query-string";

import { landing } from "../constants/actionTypes";

export const actions = {
  setLoading(section, isLoading) {
    return {
      type: landing.SET_LOADING,
      payload: { section, isLoading }
    };
  },

  getCityStateZip(cityStateZip) {
    return dispatch => {
      /*
      dispatch(this.setLoading("page", true));
      */
      axios({
        method: "get",
        responseType: "json",
        url: `/members/json-api/google-places/autocomplete${
          cityStateZip ? `?input=${cityStateZip}` : ""
        }&countries[]=us&countries[]=ca&countries[]=au&countries[]=fr&countries[]=ir&countries[]=gb`,
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(res => {
          dispatch({
            type: landing.GET_CITY_STATE,
            payload: res.data
          });
        })
        .catch(() => {
          dispatch({
            type: landing.GET_CITY_STATE_FAIL,
            payload: true
          });
        });
    };
  },

  getCountries() {
    return dispatch => {
      dispatch(this.setLoading("page", true));
      axios({
        method: "get",
        responseType: "json",
        url: "/members/json-api/public/heritages",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(res => {
          dispatch({
            type: landing.GET_COUNTRIES,
            payload: res.data
          });
        })
        .catch(() => {
          dispatch({
            type: landing.GET_COUNTRIES_FAIL,
            payload: true
          });
        });
    };
  },

  getPractices(professionsId) {
    return dispatch => {
      dispatch(this.setLoading("page", true));
      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/public/practices",
        data: JSON.stringify({ profession_id: professionsId })
      })
        .then(res => {
          dispatch({
            type: landing.GET_PRACTICES,
            payload: res.data
          });
        })
        .catch(() => {
          dispatch({
            type: landing.GET_PRACTICES_FAIL,
            payload: true
          });
        });
    };
  },

  getProfessions() {
    return dispatch => {
      dispatch(this.setLoading("page", true));
      axios({
        method: "get",
        responseType: "json",
        url: "/members/json-api/public/professions",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(res => {
          dispatch({
            type: landing.GET_PROFESSIONS,
            payload: res.data
          });

          const findLawyer = res.data.find(item => item.title === "Lawyers");

          if (findLawyer) {
            dispatch(this.getPractices(findLawyer.profession_id));
          }
        })
        .catch(() => {
          dispatch({
            type: landing.GET_PROFESSIONS_FAIL
          });
        });
    };
  },

  getSearch(params) {
    return dispatch => {
      dispatch(this.setLoading("page", true));
      const filterParams = queryString.stringify({ ...params }, { arrayFormat: "index" });

      axios({
        method: "get",
        responseType: "json",
        url: "/members/json-api/lead",
        params: { filterParams },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(res => {
          dispatch({
            type: landing.GET_SEARCH,
            payload: res.data
          });
        })
        .catch(() => {
          dispatch({
            type: landing.GET_SEARCH_FAIL,
            payload: true
          });
        });
    };
  }
};
