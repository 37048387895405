import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { WEBSITE } from "../constants/regex";
import { filterBySearchValue, filterBookmarked, getDataIntercom } from "./index";
import { QUESTION_TYPES } from "../constants/question";
import { track } from "./track";
import { eventName } from "../constants/track";
import { actions as mainActions } from "../actions/main";

export const useDocTitle = (title, keepOnUnmount = false) => {
  const defaultTitle = useRef(document.title);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t(title);
  }, [t, title]);

  useEffect(() => {
    return () => {
      if (!keepOnUnmount) {
        // eslint-disable-next-line
        document.title = defaultTitle.current;
      }
    };
  }, [keepOnUnmount]);
};

export const useNotification = (error, success) => {
  const [notes, setNotes] = useState({ success: null, error: null });

  useEffect(() => {
    if (Object.values(success).some(value => !!value)) {
      setNotes({ success: true });
      setTimeout(() => setNotes({ success: null }), 7000);
    }

    if (error) {
      setNotes({ error });
      setTimeout(() => setNotes({ error: null }), 7000);
    }
  }, [success, error]);

  return notes;
};

// question-answer page
export const useFiltrationQAndAPage = (activeFilters, searchValue, bookmarked, setQuestionsToRender) => {
  const { qa, answers } = useSelector(state => state.questions);

  useEffect(() => {
    if (!activeFilters.isFirstRender) {
      const { practices, categories, professions } = activeFilters;

      const filterByWebsiteUrl = item => categories.includes(item.website_url.replace(WEBSITE, "").split("/")[0]);
      const filterByPractices = ({ practice1, practice2, practice3 }) => {
        const toArrayPractices = [practice1, practice2, practice3];
        return practices.some(item => toArrayPractices.indexOf(item) >= 0);
      };
      const filterByProfessions = ({ profession_id: professionId }) => professions.includes(professionId);
      const filterByAllQuestions = item => filterByProfessions(item) && filterByWebsiteUrl(item);
      const filterByAllAnswers = item => filterByPractices(item) && filterByWebsiteUrl(item);

      let questionsList = [];
      let answersList = [];

      if (practices.length && categories.length && professions.length) {
        answersList = filterBySearchValue(answers, searchValue).filter(filterByAllAnswers);
        questionsList = filterBySearchValue(qa, searchValue).filter(filterByAllQuestions);
      } else if (practices.length && categories.length) {
        answersList = filterBySearchValue(answers, searchValue).filter(filterByAllAnswers);
        questionsList = filterBySearchValue(qa, searchValue);
      } else if (professions.length && categories.length) {
        questionsList = filterBySearchValue(qa, searchValue).filter(filterByAllQuestions);
        answersList = filterBySearchValue(answers, searchValue);
      } else if (professions.length && practices.length) {
        questionsList = filterBySearchValue(qa, searchValue).filter(filterByProfessions);
        answersList = filterBySearchValue(answers, searchValue).filter(filterByPractices);
      } else if (professions.length) {
        questionsList = filterBySearchValue(qa, searchValue).filter(filterByProfessions);
        answersList = filterBySearchValue(answers, searchValue);
      } else if (practices.length) {
        answersList = filterBySearchValue(answers, searchValue).filter(filterByPractices);
        questionsList = filterBySearchValue(qa, searchValue);
      } else if (categories.length) {
        questionsList = filterBySearchValue(qa, searchValue).filter(filterByWebsiteUrl);
        answersList = filterBySearchValue(answers, searchValue).filter(filterByWebsiteUrl);
      } else {
        questionsList = filterBySearchValue(qa, searchValue);
        answersList = filterBySearchValue(answers, searchValue);
      }

      setQuestionsToRender({
        qa: filterBookmarked(bookmarked, questionsList),
        answers: filterBookmarked(bookmarked, answersList)
      });
    }
    // eslint-disable-next-line
  }, [activeFilters.practices, activeFilters.categories, activeFilters.professions, searchValue]);
};

// question-answer detail page
export const useUpdateQuestionsIds = (id, tab) => {
  const [prevQuestionId, setPrevQuestionId] = useState(null);
  const [nextQuestionId, setNextQuestionId] = useState(null);
  const { qa, answers } = useSelector(state => state?.questions);
  const answeredQuestions = qa.filter(item => Object.keys(answers).includes(item.question_id));
  const currentQuestionIdx = useMemo(() => qa.findIndex(item => item.question_id === id), [qa, id]);

  const getPrevNextQuestionsId = useCallback(
    questionsArr => {
      setPrevQuestionId(questionsArr?.[currentQuestionIdx - 1]?.question_id);
      setNextQuestionId(questionsArr?.[currentQuestionIdx + 1]?.question_id);
    },
    [currentQuestionIdx]
  );

  useEffect(() => {
    if (!tab || tab === QUESTION_TYPES.QUESTIONS) {
      getPrevNextQuestionsId(qa);
    }
    if (tab === QUESTION_TYPES.MY_ANSWER) {
      getPrevNextQuestionsId(answeredQuestions);
    }
    // eslint-disable-next-line
  }, [qa, tab, id]);

  return { prevQuestionId, nextQuestionId };
};

export const useIntercom = ({ globalInfo, isLoggedIn, listings, profile }) => {
  useEffect(() => {
    /*
    if (globalInfo.name_f) {
      window.intercomSettings = {
        name: `${globalInfo.name_f} ${globalInfo.name_l}`, // Full name
        email: globalInfo.email, // Email address
        created_at: globalInfo.added // Signup date as a Unix timestamp
      };
    }
    */

    if (window.Intercom) {
      if (profile?.user_id && globalInfo.name_f) {
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: "lm1evs0k",
          user_id: profile.user_id,
          user_hash: profile.intercom_hash,
          name: `${globalInfo.name_f} ${globalInfo.name_l}`,
          email: globalInfo.email,
          created_at: globalInfo.added
        });
      }

      if (isLoggedIn === true) {
        getDataIntercom(listings);
      }

      window.Intercom("update");
    }
  }, [globalInfo, globalInfo.name_f, isLoggedIn, profile?.user_id]); // eslint-disable-line
};

export const useSidebar = ({ isOpenAccountDropdown }) => {
  const { t } = useTranslation();
  const { account } = useSelector(state => state);
  const { profile } = account;

  const dispatch = useDispatch();
  const toggleNewListing = useCallback(isActive => dispatch(mainActions.toggleNewListing(isActive)), [dispatch]);
  const toggleMobileMenu = useCallback(isActive => dispatch(mainActions.toggleMobileMenu(isActive)), [dispatch]);

  const handleOpenNewListing = () => {
    toggleNewListing(true);
    toggleMobileMenu(false);
  };

  const {
    globalInfo: { new_messages: newMessages }
  } = useSelector(state => state.main);

  const handleIntercom = () => {
    if (window && window.Intercom) {
      window.Intercom("show");
      window.Intercom("onShow", () => {
        track(eventName.viewContent, { em: profile.email, fn: profile.name_f, ln: profile.name_f });
      });
    }
  };

  const sidebarList = [
    {
      name: t("dashboard_nav_home"),
      link: "/"
    },
    {
      name: t("dashboard_nav_alllisting"),
      isDropdown: true,
      dropdownList: [
        {
          name: t("dashboard_nav_mylistings"),
          link: "/listings"
        },
        {
          name: t("dashboard_nav_addlisting"),
          isButton: true,
          onClick: handleOpenNewListing
        },
        {
          name: t("dashboard_nav_answers"),
          link: "/question-answer?page=0&tab=my-answers"
        },
        {
          name: t("dashboard_nav_leads"),
          link: "/paid-leads?page=0&tab=notpurchased"
        },
        {
          name: t("dashboard_nav_analytics"),
          link: "/performance"
        }
      ]
    },
    {
      name: t("dashboard_nav_inbox"),
      link: "/inbox",
      isNotification: Number(newMessages) > 0
    },
    /*
    {
      name: t("dashboard_nav_referrals"),
      link: "/referral-requests?page=0"
    },
    */
    {
      name: t("dashboard_nav_questions"),
      link: "/question-answer"
    },
    {
      name: t("dashboard_nav_accountsetting"),
      isDropdown: true,
      isDefaultOpen: isOpenAccountDropdown,
      dropdownList: [
        {
          name: t("dashboard_nav_myaccount"),
          link: "/account"
        },
        {
          name: t("dashboard_nav_notifications"),
          link: "/manage-notification"
        },
        {
          name: t("dashboard_nav_paymentmethod"),
          link: "/payment"
        },
        {
          name: t("dashboard_nav_paymenthistory"),
          link: "/payments"
        }
        /*
        {
          name: t("dashboard_nav_integration"),
          link: "/account?tab=integrations"
        }
        */
      ]
    },
    {
      name: t("dashboard_nav_helptitle"),
      isDropdown: true,
      dropdownList: [
        {
          name: t("dashboard_nav_knowledgebase"),
          link: "https://help.heritageweb.com/",
          isGlobalLink: true
        },
        {
          name: t("dashboard_nav_help"),
          isButton: true,
          onClick: handleIntercom
        }

        /* hide due this page https://heritageweb.atlassian.net/browse/GROWTH-2086

        {
          name: t("dashboard_nav_roadmap"),
          link: "/product-roadmap"
        }
        */
      ]
    }
  ];

  return { sidebarList };
};

export const useKeyDownEscape = callBack => {
  const handleKeyDown = useCallback(
    event => {
      if (event.key === "Escape") {
        callBack();
      }
    },
    [] // eslint-disable-line
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);

    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [handleKeyDown]);
};

export const useOnclickOutside = (ref, handler, reset) => {
  useEffect(() => {
    const listener = event => {
      event.stopPropagation();
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", listener);

    return () => {
      if (!reset) handler();
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

export const useKeyDownEnter = callBack => {
  const handleKeyDown = useCallback(
    event => {
      if (event.key === "Enter") {
        callBack();
      }
    },
    [callBack] // eslint-disable-line
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [handleKeyDown]);
};

export const useChangePage = callBack => {
  const currentPage = useRef("");
  const location = useLocation();

  useEffect(() => {
    if (Boolean(currentPage.current) && currentPage.current !== location.pathname) {
      callBack();
    }

    if (currentPage.current !== location.pathname) {
      currentPage.current = location.pathname;
    }
  }, [location.pathname, currentPage]); // eslint-disable-line
};

export const useDebounce = (callBack, isSendHide) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isHide, setIsHide] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      callBack(searchTerm);
      if (isSendHide) {
        if (searchTerm && isHide) {
          setIsHide(false);
        }

        if (!isHide && !searchTerm) {
          setIsHide(true);
        }
      }
    }, 1500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchTerm]); // eslint-disable-line

  return { searchTerm, setSearchTerm, isHide };
};

export const useTrack = (eventNameTrack, parameters = {}) => {
  const { account } = useSelector(state => state);
  const { profile } = account;

  useEffect(() => {
    if (window.fbq && profile) {
      window.fbq("track", eventNameTrack, { em: profile.email, fn: profile.name_f, ln: profile.name_f, ...parameters });
    }
  }, [profile]); // eslint-disable-line
};
