import React, { useEffect } from "react";
import { useSelector } from "react-redux";
// useDispatch
// import { useHistory } from "react-router-dom";
// useCallback
import PublicHeader from "../publicHeader";
import Footer from "../footer";
import Professions from "./components/professions";
import styles from "./communities.module.css";
import { professionalDirectoriesObject } from "../../constants/communitiesPage";
// import { actions as signUpActions } from "../../actions/signup";
import { OverlayPreloader } from "../common/preloader";

/* todo: remove commented code */
const Communities = () => {
  // const dispatch = useDispatch();

  // const getPublicProfessions = useCallback(profession => dispatch(signUpActions.getPublicProfessions(profession)), [dispatch]);
  // const getCountries = useCallback(profession => dispatch(signUpActions.getCountries(profession)), [dispatch]);
  const { professionsLoading, professionsList, countriesList } = useSelector(state => state.signUp);

  // const history = useHistory();
  /*
  const currentProfession = history.location.pathname
    .replace("-communities", "")
    .replace("/", "")
    .toLowerCase();
*/

  const normalizeProfessionalList = professionsList
    ?.filter(item => professionalDirectoriesObject[item.title])
    ?.map(item => {
      const objectItem = professionalDirectoriesObject[item.title];

      return { ...item, name: item.title, Icon: objectItem.Icon, link: `/${item.title.toLowerCase()}-communities` };
    });

  useEffect(() => {
    if (window.scrollY) {
      window.scroll(0, 0);
    }

    // getPublicProfessions(currentProfession);
    // getCountries(currentProfession);
  }, []); // eslint-disable-line

  const normalizeCountries = countriesList?.map(({ title, image_url: imageUrl }) => ({
    name: title,
    imageUrl,
    link: `/${title.toLowerCase()}-communities`
  }));

  return (
    <>
      {professionsLoading && <OverlayPreloader overlayClassName="sectionOverlay" />}

      <PublicHeader />
      <section className={styles.container}>
        <h2 className={styles.title}>Communities</h2>
        <p className={styles.description}>Search or select a profession to see a list of directories </p>
        <Professions professionalList={normalizeCountries} isLink />

        <h2 className={styles.title}>Select a Profession</h2>
        <p className={styles.description}>Search or select a profession to see a list of directories</p>
        <Professions professionalList={normalizeProfessionalList} isLink />
      </section>
      <Footer />
    </>
  );
};

export default Communities;
