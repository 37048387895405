import axios from "axios";
import qs from "qs";
import queryString from "query-string";
import { differenceInCalendarDays } from "date-fns";

import { payment } from "../constants/actionTypes";
import { paymentTypes } from "../constants/paymentTypes";
import { actions as listingActions } from "./listing";
import { actions as mainActions } from "./main";
import { modals as modalsConstant } from "../constants/listings";
import { normalizeSubscriptions } from "../helpers";
import { track, trackDataLayer } from "../helpers/track";
import { eventName, eventNameDataLayer } from "../constants/track";

export const actions = {
  addCard(cardData, isFirst) {
    return (dispatch, getState) => {
      const {
        account: { profile }
      } = getState();

      dispatch(this.setLoading("addCard", true));
      dispatch(mainActions.togglePaymentError(false, ""));
      axios({
        method: "post",
        url: "/members/json-api/payment/card-add",
        headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
        data: qs.stringify(cardData)
      })
        .then(res => {
          dispatch({
            type: payment.ADD_CARD_SUCCESS,
            payload: true
          });

          dispatch({ type: payment.ADD_CARD_LOCALLY, payload: res.data });

          track(eventName.addPaymentInfo, { em: profile.email, fn: profile.name_f, ln: profile.name_f });

          if (isFirst || cardData.default_source) {
            dispatch({
              type: payment.SET_DEFAULT_SOURCE,
              payload: res.data
            });
          }
        })
        .catch(error => {
          dispatch({
            type: payment.ADD_CARD_FAIL,
            payload: error?.response?.data.error
          });
          dispatch(mainActions.togglePaymentError(true, error?.response?.data?.error));
        })
        .finally(() => dispatch(this.setLoading("addCard", false)));
    };
  },

  removeAddCardSuccess() {
    return dispatch => {
      dispatch({
        type: payment.ADD_CARD_SUCCESS,
        payload: false
      });
    };
  },

  chargeForMembership(paymentData, paymentType, isReload, history) {
    return (dispatch, getState) => {
      const {
        account: { profile }
      } = getState();
      dispatch(this.setLoading("chargeForMembership", true));
      dispatch(mainActions.togglePaymentError(false, ""));
      let isActivePublish = true;
      const { added, trackingData, ...restPaymentData } = paymentData;
      const pattern = /jobs|events|organizations|profile/;
      const isProfile = history.location.pathname.includes("/profile");
      const listingName = isProfile ? "profiles" : history.location.pathname.match(pattern);
      let command = "upgrade";

      let urlUpgrade;
      switch (paymentType) {
        case paymentTypes.RENEW:
          urlUpgrade = "/members/json-api/listing/renew";
          isActivePublish = false;
          command = "renew";
          break;
        default:
          urlUpgrade = "/members/json-api/listing/upgrade";
      }

      const url = listingName ? `/members/api-v2/${listingName}` : urlUpgrade;

      axios({
        method: listingName ? "put" : "post",
        url,
        data: { ...restPaymentData, command }
      })
        .then(() => {
          const maxDateTracking = 30;
          const searchParams = queryString.parse(window.location.search);

          dispatch({
            type: payment.CHARGE_FOR_MEMBERSHIP_SUCCESS,
            payload: paymentData.listing_id
          });

          dispatch(listingActions.closeAllModals());
          dispatch(listingActions.toggleActivityOfModal(modalsConstant.paymentSuccess, true));

          if (isReload) {
            dispatch(
              listingActions.getExistingMembershipsNew(
                {},
                true,
                searchParams.page,
                [],
                [],
                [],
                "",
                "",
                10,
                [],
                [],
                "",
                history.location.pathname
              )
            );
          }

          if (isActivePublish) {
            dispatch(listingActions.publishListing(paymentData.listing_id, isReload, history));
          }

          if (
            differenceInCalendarDays(new Date(), new Date(added)) <= maxDateTracking &&
            [paymentTypes.UPGRADE, paymentTypes.PENDING].includes(paymentType)
          ) {
            track(eventName.purchase, { value: "300.00", currency: "USD", em: profile.email, fn: profile.name_f, ln: profile.name_f });
            trackDataLayer(eventNameDataLayer.listingUpgrade, {
              listing_status: "in review",
              heritage: trackingData._heritage,
              profession: trackingData._profession,
              directory: trackingData._directory,
              specialty: trackingData._specialty,
              country: trackingData._country,
              state: trackingData._state,
              city: trackingData._city,
              ecommerce: {
                currency: "USD",
                value: 25,
                transaction_id: "p115-20202000",
                coupon: "free_back_rub",
                items: [
                  {
                    item_name: trackingData.membership,
                    item_id: trackingData.listing_id,
                    price: trackingData.price,
                    item_category: "listing_upgrade",
                    quantity: "1"
                  }
                ]
              }
            });
          }
          // dispatch(listingActions.setSuccess("paymentSuccess", true));
        })
        .catch(error => {
          dispatch({
            type: payment.CHARGE_FOR_MEMBERSHIP_FAIL,
            payload: error
          });
          dispatch(mainActions.togglePaymentError(true, error?.response?.data?.error));
        });
    };
  },

  clearState() {
    return dispatch => {
      dispatch({
        type: payment.CLEAR_STATE
      });
    };
  },

  deleteCard(cardToDelete) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};

      dispatch(this.setLoading("deleteCard", true));
      dispatch(mainActions.togglePaymentError(false, ""));
      axios({
        method: "post",
        url: `/members/json-api${teamUrl}/payment/card-delete`,
        headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
        data: qs.stringify({ ...cardToDelete, ...userIdParams })
      })
        .then(res => {
          dispatch({
            type: payment.DELETE_CARD_SUCCESS,
            payload: true
          });

          dispatch({
            type: payment.DELETE_CARD_LOCALLY,
            payload: res.data
          });

          dispatch({
            type: payment.SET_DEFAULT_SOURCE,
            payload: "deleted"
          });

          dispatch(this.selectCard(null));

          // Close modal on success and reset state immediately
          setTimeout(
            () =>
              dispatch({
                type: payment.DELETE_CARD_SUCCESS,
                payload: null
              }),
            1
          );
        })
        .catch(error => {
          dispatch(mainActions.togglePaymentError(true, error?.response?.data?.error));
        })
        .finally(() => dispatch(this.setLoading("deleteCard", false)));
    };
  },

  getPaymentMethods(page, perPage, team = {}) {
    return dispatch => {
      dispatch(this.setLoading("paymentMethods", true));
      const pageParam = page || String(page) === "0" ? { page } : {};
      const perPageParam = perPage ? { per_page: perPage } : {};
      const teamUrl = team.value ? "/team" : "";

      const filterParams = queryString.stringify({ ...perPageParam, user_id: team.value, ...pageParam }, { arrayFormat: "index" });

      axios({
        method: "get",
        responseType: "json",
        url: `/members/json-api${teamUrl}/payment/cards-all?${filterParams}`
      })
        .then(res => {
          const { cards, subscriptions, expired_subscriptions: expiredSubscriptions, ...pagination } = res.data;

          dispatch({
            type: payment.GET_PAYMENT_METHODS,
            payload: {
              cards,
              subscriptions: normalizeSubscriptions(subscriptions, cards),
              expiredSubscriptions: normalizeSubscriptions(expiredSubscriptions, cards),
              subscriptionPagination: pagination
            }
          });

          const defaultSource = res.data.cards.find(({ default_source }) => default_source === true);

          dispatch({
            type: payment.SET_DEFAULT_SOURCE,
            payload: defaultSource
          });
        })
        .catch(error => {
          dispatch({
            type: payment.GET_PAYMENT_METHODS_FAIL,
            payload: error?.response?.data
          });
        });
    };
  },

  assignCardToSubscription(data) {
    return dispatch => {
      dispatch(this.setLoading("updatePaymentMethod", true));
      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/payment/card-assign",
        data
      })
        .then(() => {
          dispatch({
            type: payment.UPDATE_PAYMENT_METHOD
          });
          dispatch(this.getPaymentMethods());
        })
        .catch(() => {
          dispatch({
            type: payment.UPDATE_PAYMENT_METHOD_FAIL
          });
        });
    };
  },

  getPdfFile(url) {
    return dispatch => {
      dispatch(this.setLoading("paymentHistory", true));
      axios({
        method: "get",
        responseType: "json",
        url: `/members${url}`,
        headers: { "Content-Type": "application/pdf; charset=UTF-8" }
      })
        .then(result => {
          dispatch(this.setLoading("paymentHistory", false));

          const byteCharacters = result.data;
          const byteNumbers = new Array(byteCharacters.length);

          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const file = new Blob([byteArray], { type: "application/pdf;base64" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(() => {
          dispatch(this.setLoading("paymentHistory", false));
        });
    };
  },

  getPaymentHistory(page, perPage, team = {}) {
    return dispatch => {
      dispatch(this.setLoading("paymentHistory", true));
      const perPageParam = perPage ? { per_page: perPage } : {};
      const teamUrl = team.value ? "/team" : "";

      const filterParams = queryString.stringify({ page, user_id: team.value, ...perPageParam }, { arrayFormat: "index" });

      axios({
        method: "get",
        responseType: "json",
        url: `/members/json-api${teamUrl}/payment/history?${filterParams}`
      })
        .then(res => {
          const { payments, ...pagination } = res.data;
          dispatch({
            type: payment.GET_PAYMENT_HISTORY,
            payload: {
              payments,
              pagination
            }
          });
        })
        .catch(() => {
          dispatch({
            type: payment.GET_PAYMENT_HISTORY_FAIL
          });
        });
    };
  },

  setLoading(section, isSectionLoading) {
    return {
      type: payment.SET_LOADING,
      payload: { section, isSectionLoading }
    };
  },

  setStripeToken(token) {
    return dispatch => {
      dispatch({ type: payment.SET_STRIPE_TOKEN, payload: token });
    };
  },

  selectCard(card) {
    return {
      type: payment.SET_SELECTED_CARD,
      payload: card
    };
  },

  updateCard(card) {
    return dispatch => {
      dispatch(this.setLoading("updateCard", true));
      dispatch(mainActions.togglePaymentError(false, ""));
      axios({
        method: "post",
        url: "/members/json-api/payment/card-update",
        headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
        data: qs.stringify(card)
      })
        .then(() => {
          // Close modal on success and reset state immediately
          new Promise(resolve => {
            dispatch({
              type: payment.UPDATE_CARD_SUCCESS,
              payload: true
            });

            if (card.default_source) {
              dispatch({
                type: payment.SET_DEFAULT_SOURCE,
                payload: card
              });
            }

            dispatch({
              type: payment.UPDATE_CARD_LOCALLY,
              payload: card
            });

            dispatch(this.selectCard(card));
            resolve();
          }).then(() => {
            dispatch({
              type: payment.UPDATE_CARD_SUCCESS,
              payload: null
            });
          });
        })
        .catch(error => {
          dispatch(mainActions.togglePaymentError(true, error?.response?.data?.error));
        })
        .finally(() => dispatch(this.setLoading("updateCard", false)));
    };
  },

  updateDefaultSource(cardId) {
    return dispatch => {
      dispatch(this.setLoading("updateCard", true));
      dispatch(mainActions.togglePaymentError(false, ""));
      axios({
        method: "post",
        url: "members/json-api/payment/card-default",
        headers: { "Content-Type": "application/json; charset=UTF-8" },
        data: {
          card_id: cardId
        }
      })
        .then(res => {
          if (res.data.default_source === cardId) {
            dispatch({
              type: payment.SET_DEFAULT_SOURCE,
              payload: res.data
            });
          }
        })
        .catch(error => {
          dispatch(mainActions.togglePaymentError(true, error?.response?.data?.error));
        });
    };
  },

  setErrorToken(err) {
    return dispatch => {
      dispatch({ type: payment.GET_ERROR_TOKEN, payload: err.message });
    };
  },

  clearTokenError() {
    return dispatch => {
      dispatch({ type: payment.CLEAR_ERROR_TOKEN });
    };
  }
};
