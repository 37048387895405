import React, { useState, useEffect } from "react";

import Tabs from "../feed/tabs";
import Filter from "./filter";
import Profiles from "./profiles";
import Organizations from "./organizations";
import Events from "./events";
import Jobs from "./jobs";

import styles from "./saved.module.css";

const Saved = () => {
  const [filter, setFilter] = useState({});

  const all = { value: "All", label: "all label" };
  const profiles = { value: "Profiles", label: "Profiles label" };
  const organizations = { value: "Organizations", label: "Organizations label" };
  const jobs = { value: "Jobs", label: "Jobs label" };
  const events = { value: "Events", label: "Events label" };

  const filterOptions = [all, profiles, organizations, jobs, events];

  useEffect(() => {
    if (!filter.value) {
      setFilter(all);
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <Tabs />
      <Filter filter={filter} setFilter={setFilter} filterOptions={filterOptions} />

      {(filter.value === profiles.value || filter.value === all.value) && (
        <Profiles onClick={() => setFilter(profiles)} isActive={filter.value === profiles.value} handleClickAll={() => setFilter(all)} />
      )}

      {(filter.value === organizations.value || filter.value === all.value) && (
        <Organizations
          onClick={() => setFilter(organizations)}
          isActive={filter.value === organizations.value}
          handleClickAll={() => setFilter(all)}
        />
      )}

      {(filter.value === jobs.value || filter.value === all.value) && (
        <Jobs onClick={() => setFilter(jobs)} isActive={filter.value === jobs.value} handleClickAll={() => setFilter(all)} />
      )}

      {(filter.value === events.value || filter.value === all.value) && (
        <Events onClick={() => setFilter(events)} isActive={filter.value === events.value} handleClickAll={() => setFilter(all)} />
      )}
    </div>
  );
};

export default Saved;
