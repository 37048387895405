import { useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import React from "react";

import { QuestionBox } from "../questionBox";
import { MainSection } from "../mainSection";
import questionAnswersIcon from "../../../media/icons/q&a.svg";

export const Questions = React.memo(() => {
  const { t } = useTranslation();

  const { dashboardData } = useSelector(state => state.home, shallowEqual);

  if (!dashboardData?.qa?.length) {
    return null;
  }

  const qaList = dashboardData?.qa.map(
    ({ added, city, question_answer: questionAnswer, question_id: questionId, state, summary, title }) => {
      return (
        <QuestionBox
          key={questionId}
          added={added}
          answerTimes={questionAnswer}
          city={city}
          questionId={questionId}
          state={state}
          summary={summary}
          tab="questions"
          title={title}
        />
      );
    }
  );

  return (
    <MainSection
      icon={questionAnswersIcon}
      isActive={!dashboardData?.qa?.length}
      title={t("dashboard_home_questions_block_title")}
      linkText={t("dashboard_home_viewall")}
      to="/question-answer"
    >
      <div>{qaList}</div>
    </MainSection>
  );
});
