import React from "react";
import { useTranslation } from "react-i18next";

import SelectInput from "../../common/selectInput";
import Tile from "../tile";
import styles from "./copyListing.module.css";

export const CopyListing = ({ existingListings, formik, handleSelectChange, className, isHomePage, onClose }) => {
  const { t } = useTranslation();

  return (
    <Tile className={className}>
      {isHomePage ? (
        <p className={styles.label}>
          {t("addlisting_title_copy")}
          <button type="button" className={styles.copyLink} onClick={onClose}>
            {t("addliting_copy_hide")}
          </button>
        </p>
      ) : (
        <>
          <p className="h2">Copy from Existing Listing</p>
          <p className="mb30">You may copy data from existing listings</p>
        </>
      )}

      <SelectInput
        getOptionLabel={({ membership }) => membership.toString()}
        getOptionValue={({ listing_id }) => listing_id.toString()}
        name="listing"
        onChange={handleSelectChange}
        options={[{ listing_id: "", membership: "None" }, ...existingListings]}
        value={[formik.values.listing]}
      />
    </Tile>
  );
};
