import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { format } from "date-fns";

import { getFormattedDateTimeZone } from "../../../../helpers";
import styles from "./history.module.css";

export const History = ({ history }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <h3 className={styles.title}>{t("dashboard_referrals_details_change_title")}</h3>
      <div className={styles.historyContent}>
        <div className={styles.row}>
          <div className={styles.itemLeft}>
            <h3 className={styles.label}>{t("dashboard_referrals_details_change_date")}</h3>
          </div>
          <div className={styles.itemRight}>
            <h3 className={styles.label}>{t("dashboard_referrals_details_change_status")}</h3>
          </div>
        </div>

        {history.map(({ added, status }) => {
          return (
            <div className={styles.row} key={added}>
              <div className={styles.itemLeft}>
                <p className={cn(styles.textContent, styles.textContentBold)}>
                  {format(getFormattedDateTimeZone(added), "MMM dd, yyyy' 'HH:mm")}
                </p>
              </div>
              <div className={styles.itemRight}>
                <p className={cn(styles.textContent, styles.textContentBold)}>{t(status)}</p>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};
