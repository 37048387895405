import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import nextIcon from "../../../../media/icons/next.svg";
import styles from "./next.module.css";

const areEqual = (prevProps, nextProps) => {
  return nextProps.nextQuestionId === prevProps.nextQuestionId && nextProps.tab === prevProps.tab;
};

export const Next = React.memo(({ nextQuestionId, tab }) => {
  if (!nextQuestionId) return null;

  return (
    <Link className={cn("button--clear", styles.nextIcon)} to={`/question-answer/${nextQuestionId}${tab ? `?tab=${tab}` : ""}`}>
      <img src={nextIcon} alt="" />
    </Link>
  );
}, areEqual);
