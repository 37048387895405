import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import axios from "axios";
// eslint-disable-next-line import/no-extraneous-dependencies
import Keycloak from "keycloak-js";

import { actions as authActions } from "./actions/auth";
import { actions as mainActions } from "./actions/main";
import { actions as signUpActions } from "./actions/signup";
import RegistrationContainer from "./containers/registration";
import ProfessionCommunities from "./containers/professionCommunities";
import PrivateRoutes from "./containers/privateRoutes";
import ForgotPassword from "./components/passwordReset/forgotPassword";
import Forbidden from "./components/forbidden";
import VerificationCode from "./components/passwordReset/verificationCode";
import SetNewPass from "./components/passwordReset/setNewPass";
import ResetPasswordSuccess from "./components/passwordReset/resetPasswordSuccess";
import Features from "./containers/features";
import Communities from "./containers/communities";
import Pricing from "./containers/pricing";
import SearchResult from "./containers/searchResult";
import Landing from "./containers/landing";
import PublicListing from "./containers/publicListing";
import PublicOrganizations from "./containers/publicOrganizations";
import PublicProfile from "./containers/publicProfile";
import Login from "./containers/login";
import Logout from "./containers/logout";
import { useIntercom } from "./helpers/hooks";
import { LeadAlreadyPurchased } from "./containers/leadAlreadyPurchased";
import { LeadExpired } from "./containers/leadExpired";
import { getQueryParams } from "./helpers";
import { Notification } from "./components/common/notification";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.defaults.withCredentials = true;

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID
});

// eslint-disable-next-line
console.log("keycloak", keycloak);

try {
  if (!keycloak?.token) {
    const authenticated = await keycloak.init({ checkLoginIframe: false, onLoad: "check-sso", pkceMethod: "S256" });
    // eslint-disable-next-line
    console.log(`User is ${authenticated ? "authenticated" : "not authenticated Keycloack"}`);
  }

  /*
  if (keycloak.isTokenExpired()) {
    await keycloak.updateToken(1);
  }
  */

  if (keycloak.token) {
    // eslint-disable-next-line
    axios.defaults.headers.common["Authorization"] = `Bearer ${keycloak.token}`;
  }
} catch (error) {
  console.error("Failed to initialize adapter Keycloack:", error);
}

/*
axios.interceptors.request.use(
  async config => {
    try {
      if (keycloak.isTokenExpired()) {
        await keycloak.updateToken(60);
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${keycloak.token}`;
      }
    } catch (error) {
      console.error("Failed to update keycloak token", error);
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
*/

const Routes = ({ errors, login, logoutAction, isLoggedIn, loginMethods, preloaders, globalInfo, setLoggedIn, listings, profile }) => {
  const history = useHistory();
  const globalPages = ["/pricing", "/features", "/communities", "/search-result", "/landing"];
  const currentProfession = history.location.pathname
    .replace("-communities", "")
    .replace("/", "")
    .toLowerCase();

  useIntercom({ globalInfo, isLoggedIn, listings, profile });
  const dispatch = useDispatch();

  const getPublicProfessions = useCallback(profession => dispatch(signUpActions.getPublicProfessions(profession)), [dispatch]);
  const getCountries = useCallback(profession => dispatch(signUpActions.getCountries(profession)), [dispatch]);
  const { professionsList, countriesList } = useSelector(state => state.signUp);

  const logout = () => {
    keycloak.logout({ redirectUri: `${window.location.origin}/login` });
    logoutAction();
  };

  const professionalPathNames =
    countriesList?.length && professionsList?.length
      ? [...countriesList, ...professionsList].map(({ title }) => `/${title.toLowerCase()}-communities`)
      : [];
  const isCommunities = history.location.pathname.includes("-communities");
  const isCommunitiesDefault = history.location.pathname.includes("communities");
  const isPublicListingPage =
    history.location.pathname.includes("public-listing") ||
    history.location.pathname.includes("public-organizations") ||
    history.location.pathname.includes("public-profile");
  const queryParams = useMemo(() => getQueryParams(history.location.search), [history.location]);
  const isGlobalPages = globalPages.includes(history.location.pathname);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (isCommunitiesDefault) {
      getPublicProfessions(currentProfession);
      getCountries(currentProfession);
    }
  }, [history.location.pathname]); // eslint-disable-line

  useEffect(() => {
    if (keycloak.token) {
      setLoggedIn(true);
    }
  }, [keycloak.token]); //eslint-disable-line

  useEffect(() => {
    if (!isLoggedIn && !keycloak.token && !isCommunitiesDefault && !isGlobalPages && !isPublicListingPage) {
      history.push("/login");
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (queryParams?.isAuthenticationError) {
      setShowError(true);

      const urlParams = new URLSearchParams(history.location.search);
      const currentUrl = window.location.href;
      const newUrl = `${currentUrl.split("?")[0]}?${urlParams.toString()}`;

      urlParams.delete("isAuthenticationError");

      window.history.pushState({ path: newUrl }, "", newUrl);
    }
  }, [history.location.search]); // eslint-disable-line

  const isTokenExpired = keycloak.token && keycloak.isTokenExpired();

  useEffect(() => {
    // if (isTokenExpired) {
    axios.interceptors.request.use(
      async config => {
        try {
          await keycloak.updateToken(60);
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${keycloak.token}`;
        } catch (error) {
          console.error("Failed to update keycloak token", error);
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
    // }
  }, [isTokenExpired]);

  return (
    <>
      {showError && (
        <Notification error close={() => setShowError(false)}>
          User authentication error. Please contact support.
        </Notification>
      )}

      <Switch>
        <Route
          exact
          path="/login"
          render={props => (
            <Login
              errors={errors}
              isLoggedIn={isLoggedIn}
              login={login}
              loginMethods={loginMethods}
              preloaders={preloaders}
              user={profile}
              keycloak={keycloak}
              {...props}
            />
          )}
        />
        <Route exact path="/logout" render={() => <Logout handleLogout={logout} />} />

        <Route exact path="/login/forgot-password" render={props => <ForgotPassword {...props} />} />
        <Route exact path="/login/verification-code" render={props => <VerificationCode {...props} />} />
        <Route exact path="/login/set-new-password" render={() => <SetNewPass />} />
        <Route exact path="/login/reset-password-success" render={() => <ResetPasswordSuccess />} />
        <Route exact path="/create-new-account" render={() => <RegistrationContainer />} />
        <Route exact path="/forbidden" render={props => <Forbidden {...props} />} />
        {professionalPathNames?.length ? <Route exact path={professionalPathNames} render={() => <ProfessionCommunities />} /> : null}

        {(!countriesList?.length || !professionsList?.length) && isCommunities ? (
          <Route exact path={`/${currentProfession}-communities`} render={() => <ProfessionCommunities />} />
        ) : null}

        <Route exact path="/lead-purchased-already" render={() => <LeadAlreadyPurchased />} />
        <Route exact path="/lead-expired" render={() => <LeadExpired />} />
        <Route exact path="/features" render={() => <Features />} />
        <Route exact path="/communities" render={() => <Communities />} />
        <Route exact path="/pricing" render={() => <Pricing />} />
        <Route exact path="/landing" render={() => <Landing />} />
        <Route exact path="/search-result" render={() => <SearchResult />} />
        <Route exact path="/public-listing/:id?" render={props => <PublicListing props={props} />} />
        <Route exact path="/public-organizations/:id?" render={props => <PublicOrganizations props={props} />} />
        <Route exact path="/public-profile/:id?" render={props => <PublicProfile props={props} />} />

        <PrivateRoutes keycloak={keycloak} isLoggedIn={isLoggedIn} />
      </Switch>
    </>
  );
};

const mapStateToProps = state => {
  return {
    profile: state.account.profile,
    isLoggedIn: state.main.isLoggedIn,
    globalInfo: state.main.globalInfo,
    loginMethods: state.auth.loginMethods,
    preloaders: state.auth.preloaders,
    errors: state.auth.errors,
    success: state.auth.success,
    listings: state.listing.memberships
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logoutAction: () => dispatch(mainActions.logout()),
    setLoggedIn: isLoggedIn => dispatch(authActions.setLoggedIn(isLoggedIn)),
    login: credentials => dispatch(authActions.login(credentials))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
