import React from "react";

import ListingContent from "../listingContent";
import ProfileListing from "../profileListing";
import companyTest from "../../../media/images/testImg.png";

const list = [
  {
    title: "Victoria Bernardian_1",
    description:
      "Excerpt of the bio that the professional has published in their listing. The exact number of characters to be determined ...",
    practices: "Immigration Law, Family Law, Labor and Employment Law",
    position: "Chicago, IL",
    banner: companyTest,
    experience: "14 years licensed",
    phone: "(713) 999-5447"
  },
  {
    title: "Victoria Bernardian_2",
    description:
      "Excerpt of the bio that the professional has published in their listing. The exact number of characters to be determined ...",
    practices: "Immigration Law, Family Law, Labor and Employment Law",
    position: "Chicago, IL",
    banner: companyTest,
    experience: "14 years licensed",
    phone: "(713) 999-5447"
  },
  {
    title: "Victoria Bernardian_3",
    description:
      "Excerpt of the bio that the professional has published in their listing. The exact number of characters to be determined ...",
    practices: "Immigration Law, Family Law, Labor and Employment Law",
    position: "Chicago, IL",
    banner: companyTest,
    experience: "14 years licensed",
    phone: "(713) 999-5447"
  }
];

const Profiles = ({ isExpand, onClick, isActive, handleClickAll }) => {
  return (
    <>
      {isExpand ? (
        <ProfileListing isExpand list={list} />
      ) : (
        <ListingContent title="Profiles" count="135" onClick={onClick} isActive={isActive} handleClickAll={handleClickAll}>
          <ProfileListing list={list} />
        </ListingContent>
      )}
    </>
  );
};

export default Profiles;
