import React, { useEffect, useState } from "react";

import findIcon from "../../../../media/icons/search.svg";
import styles from "./search.module.css";

export const Search = ({ setSearchValue }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isSearch) {
        setSearchValue(searchTerm);
      }

      if (!isSearch) {
        setIsSearch(true);
      }
    }, 1500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchTerm]); // eslint-disable-line

  return (
    <div className={styles.wrap}>
      <input
        type="text"
        className={styles.search}
        placeholder="Search questions"
        value={searchTerm}
        onChange={event => setSearchTerm(event.target.value)}
      />
      <img className={styles.searchIcon} src={findIcon} alt="" />
    </div>
  );
};
