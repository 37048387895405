import React from "react";
import cn from "classnames";

import { ReactComponent as PinIcon } from "../../../media/icons/pin.svg";
import { Button } from "../../common/button";
import { ReactComponent as SaveIcon } from "../../../media/icons/heart.svg";
import { ReactComponent as ShareIcon } from "../../../media/icons/share.svg";
import styles from "./jobsListing.module.css";

const JobsListing = ({ list, isExpand }) => {
  return (
    <div className={cn(styles.flex, isExpand ? styles.flexExpand : "")}>
      {list.map(item => {
        return (
          <div className={cn(styles.tile, isExpand ? styles.tileExpand : "")} key={item.title}>
            <div className={styles.favorites}>
              <Button className={styles.favoritesButton}>
                <SaveIcon />
              </Button>
              <Button className={styles.favoritesButton}>
                <ShareIcon />
              </Button>
            </div>

            <div className={styles.tileImage}>
              <img src={item.banner} alt="Events banner" />
            </div>
            <div>
              <div className={styles.titleFlex}>
                <span className={styles.titleDetail}>{item.companyName}</span>
                <span className={styles.titleDetail}>
                  <PinIcon /> {item.position}
                </span>
              </div>

              <h6 className={styles.tileTitle}>{item.title}</h6>
              <p className={styles.tileDescription}>{item.description}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default JobsListing;
