import React from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

import { WEBSITE } from "../../../../constants/regex";
import { customStylesMultiMobile as selectStyle } from "../../../../constants/select";
import styles from "./categoriesMobile.module.css";

const areEqual = (prevProps, nextProps) => {
  return nextProps.activeFilters.categories.length === prevProps.activeFilters.categories.length;
};

export const CategoriesMobile = React.memo(({ activeFilters, setActiveFilters }) => {
  const { qa, categories } = useSelector(state => state.questions);
  const questionCategories = [...new Set(qa?.map(question => question.website_url.replace(WEBSITE, "").split("/")[0]))];
  const categoriesForFilter = categories?.filter(category => questionCategories.includes(category.title.toLowerCase()) && category);
  const categoriesForFilterMobile = categoriesForFilter?.map(({ category_id: value, title: label }) => ({ value, label }));

  if (!categoriesForFilterMobile?.length) {
    return null;
  }

  return (
    <div className={styles.selectArea}>
      <Select
        isMulti
        options={categoriesForFilterMobile}
        onChange={options =>
          setActiveFilters({
            ...activeFilters,
            categories: options?.map(({ label }) => label.toLowerCase()) || [],
            isFirstRender: false
          })
        }
        placeholder="Filter by Network..."
        styles={selectStyle}
      />
    </div>
  );
}, areEqual);
