import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { Modal } from "../../common/modal";
import styles from "../listingDetail.module.css";
import { OverlayPreloader } from "../../common/preloader";
import { ReactComponent as UploadIcon } from "../../../media/icons/upload_blue.svg";
import ModalControl from "../../modalControl";
import { createImageDeliveryUrl } from "../../../helpers";

export const Photo = ({
  closeModal,
  deleteImage,
  id,
  images,
  loading,
  uploadImage,
  clearAllModals,
  validation,
  uploadSocialImage,
  isSocialPhoto,
  socialPhoto,
  validationSocialPhoto
}) => {
  const { t } = useTranslation();
  const imagesList = images.filter(item => item.src);
  const defaultImage = imagesList.find(item => String(item.is_default) === "1") || {};

  const handleFileInput = e => {
    const { files } = e.target;

    if (files.length) {
      const formData = new FormData();

      formData.append("image_body", files[0]);
      formData.append("listing_id", id);
      formData.append("is_default", "1");

      if (isSocialPhoto) {
        uploadSocialImage(formData);
      } else {
        uploadImage(formData);
      }

      if (!isSocialPhoto && imagesList?.length) {
        imagesList.forEach(item => {
          deleteImage(item.listing_id, item.image_id);
        });
      }
    }
  };

  const closeHandler = () => {
    closeModal();
  };

  const renderText = isEmptyImage => {
    if (isSocialPhoto) {
      return isEmptyImage
        ? `+Add new ${validationSocialPhoto.custom_name || "image"}`
        : `Update ${validationSocialPhoto.custom_name || "image"}`;
    }

    return isEmptyImage ? `+Add new ${validation.custom_name || "picture"}` : `Update ${validation.custom_name || "picture"}`;
  };

  const emptyImage = isEmptyImage => {
    const photoWrpBig = isEmptyImage ? styles.photoWrpBig : "";

    return (
      <div className={cn(styles.photoWrp, photoWrpBig)}>
        <div>
          <div className={styles.dashedContent}>
            <UploadIcon className={styles.uploadIcon} />
          </div>
          <label htmlFor="upload" className={cn("button--clear", styles.defaultButton)}>
            {renderText(isEmptyImage)}
            <div className={styles.uploadWrp}>
              <input
                accept=".png, .jpg, .jpeg"
                onChange={e => handleFileInput(e)}
                id="upload"
                name="upload"
                className={styles.fileInput}
                type="file"
              />
            </div>
          </label>
        </div>
      </div>
    );
  };

  const renderTitle = () => {
    if (isSocialPhoto) {
      return validationSocialPhoto.custom_name || "Cover Image";
    }

    return `Listing ${validation.custom_name || "Pictures"}`;
  };

  return (
    <Modal closeModal={closeModal} clearAllModals={clearAllModals} classNameMain={styles.modal}>
      {loading && <OverlayPreloader overlayClassName="sectionOverlayModal" />}
      <p className={styles.popupSubtitle}>{t("dashboard_single_listing_popup_subtitle")}</p>
      <h2 className={styles.popupTitle}>{renderTitle()}</h2>
      <hr className={styles.hr} />
      <div className={styles.section}>
        {!isSocialPhoto && imagesList.length ? (
          <>
            <div className={styles.mainPhotoWrp}>
              <img className={styles.defaultImg} src={defaultImage?.src} alt={defaultImage.image_name?.slice(0, 1) || "Default Photo"} />

              {emptyImage()}
            </div>
          </>
        ) : null}

        {socialPhoto && isSocialPhoto ? (
          <>
            <div className={styles.mainPhotoWrp}>
              <img className={styles.defaultImg} src={createImageDeliveryUrl(socialPhoto)} alt="Banner" />

              {emptyImage()}
            </div>
          </>
        ) : null}

        {!isSocialPhoto && !imagesList.length ? <>{emptyImage(true)}</> : null}
        {isSocialPhoto && !socialPhoto && <>{emptyImage(true)}</>}
      </div>

      <ModalControl onExit={closeHandler} isHideExitConfirm isClose />
    </Modal>
  );
};
