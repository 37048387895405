import React from "react";

import styles from "./video.module.css";
import testBanner from "../../../media/images/eventsTestBanner.jpg";

const Video = () => {
  return (
    <div className={styles.content}>
      <h3 className={styles.title}>__Video about Company</h3>

      <div className={styles.video}>
        <img src={testBanner} alt="cover" />
      </div>
    </div>
  );
};

export default Video;
