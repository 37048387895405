import React from "react";
import { useTranslation } from "react-i18next";

import Subscription from "../subscription";
import styles from "./paidSubscriptions.module.css";

const PaidSubscriptions = ({ subscriptions, expiredSubscriptions, handleActivePaymentModal }) => {
  const { t } = useTranslation();

  const expiredSubscriptionsList = expiredSubscriptions.map(item => {
    return <Subscription isExpired item={item} key={item.invoice_id} handleActivePaymentModal={handleActivePaymentModal} />;
  });

  const subscriptionsList = subscriptions.map(item => {
    return <Subscription item={item} key={item.invoice_id} handleActivePaymentModal={handleActivePaymentModal} />;
  });

  return (
    <>
      {subscriptions.length > 0 && (
        <div className={styles.main}>
          <h2 className={styles.title}>{t("dashboard_payments_subscriptionstitle")}</h2>
          <div className={styles.heading}>
            <span className={styles.headingItem}>{t("dashboard_payments_service")}</span>
            <span className={styles.headingItem}>{t("dashboard_payments_amount")}</span>
            <span className={styles.headingItem}>{t("dashboard_payments_nextpayment")}</span>
            <span className={styles.headingItem}>{t("dashboard_payments_paymentmethod")}</span>
          </div>
          {subscriptionsList}
          {expiredSubscriptionsList}
        </div>
      )}
    </>
  );
};

export default PaidSubscriptions;
