import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { actions as notificationActions } from "../../actions/account/notifications";
import AccountNotifications from "./accountNotifications";
import ListingNotifications from "./listingNotifications";
/* todo: remove commented code */
// import LeadNotifications from "./leadNotifications";
// import Tabs from "./tabs";
import MainTabs from "../account/tabs";

import { tabs, tabsKeys } from "../../constants/account";
import styles from "./manageNotifications.module.css";
import { useDocTitle } from "../../helpers/hooks";
import { actions as profileActions } from "../../actions/account/profile";
import { DeleteNotification } from "../listingDetail/deleteNotification";
import { DeleteNotificationSuccess } from "../listingDetail/modals/deleteNotificationSuccess";

const NotificationsPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const getProfile = useCallback(() => dispatch(profileActions.getProfile()), [dispatch]);
  const getNotificationSettings = () => dispatch(notificationActions.getNotificationSettings());
  const saveNotificationList = useCallback(notifications => dispatch(notificationActions.saveNotificationList(notifications)), [dispatch]);
  const toggleActivityOfModal = (modal, isActive) => dispatch(profileActions.toggleActivityOfModal(modal, isActive));
  const removeNotificationPhone = data => dispatch(profileActions.removeNotificationPhone(data));
  const removeNotificationEmail = data => dispatch(profileActions.removeNotificationEmail(data));
  const clearAllModals = () => dispatch(profileActions.clearAllModals());
  const [deletedNotification, setDeletedNotification] = useState({ attorney: "", keyValue: "" });
  const { account } = useSelector(state => state);
  const { notificationSettings, preloaders, success, modals, profile } = account;
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    if (!profile?.name_f) {
      getProfile();
    }
  }, []); // eslint-disable-line

  useDocTitle(t("dashboard_manage_notifications_metatitle"));

  useEffect(() => {
    getNotificationSettings();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!activeTab) {
      setActiveTab(tabs[0].name);
    }
  }, []); // eslint-disable-line

  const openModal = modalName => {
    toggleActivityOfModal(modalName, true);
  };

  const handleRemoveNotification = (modalName, notificationData) => {
    toggleActivityOfModal(modalName, true);

    setDeletedNotification(notificationData);
  };

  // const isDisableListingNotifications = preloaders.notificationSettings;

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Manage Notifications</h1>

      <MainTabs />
      {/*

      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} isDisableListingNotifications={isDisableListingNotifications} />
      */}

      {tabsKeys.accountNotifications === activeTab && (
        <AccountNotifications
          preloaders={preloaders}
          success={success}
          notifications={notificationSettings.notifications}
          saveNotificationList={saveNotificationList}
        />
      )}

      {/*
      {tabsKeys.leadNotifications === activeTab && (
        <LeadNotifications
          success={success}
          newsletters={notificationSettings.newsletters}
          saveNewsletterList={saveNewsletterList}
          preloaders={preloaders}
        />
      )}
      */}

      {tabsKeys.listingNotifications === activeTab && (
        <ListingNotifications
          preloaders={preloaders}
          success={success}
          notifications={notificationSettings.listingNotifications.global}
          notificationsListings={notificationSettings.listingNotifications.listings}
          saveNotificationList={saveNotificationList}
          openModal={openModal}
          handleRemoveNotification={handleRemoveNotification}
          userEmail={profile?.email}
        />
      )}

      {modals.deleteEmail && (
        <DeleteNotification
          closeModal={clearAllModals}
          clearAllModals={clearAllModals}
          subtitle="DELETE NOTIFICATION"
          title="Delete Listing Notification"
          text="Please confirm deleting the email address below from this listing’s notification recipients."
          value={t("dashboard_listing_notifications_emailinput_title")}
          listing="Listing"
          keyValue={deletedNotification.keyValue}
          listingType={deletedNotification.membership}
          listingName={deletedNotification.attorney}
          handleConfirm={() => removeNotificationEmail({ listing_id: deletedNotification.listingId, prkey: deletedNotification.prKey })}
          preloaders={preloaders.removeNotificationEmail}
        />
      )}

      {modals.deletePhone && (
        <DeleteNotification
          closeModal={clearAllModals}
          clearAllModals={clearAllModals}
          subtitle="DELETE NOTIFICATION"
          title="Delete Listing Notification"
          text="Please confirm deleting the phone number below from this listing’s notification recipients."
          value={t("dashboard_listing_notifications_phoneinput_title")}
          listing="Listing"
          keyValue={deletedNotification.keyValue}
          listingType={deletedNotification.membership}
          listingName={deletedNotification.attorney}
          handleConfirm={() => removeNotificationPhone({ listing_id: deletedNotification.listingId, prkey: deletedNotification.prKey })}
          preloaders={preloaders.removeNotificationPhone}
          // keyValue={notificationModalData}
          // listingType={memberData.membership}
          // listingName={memberData.attorney}
          // handleConfirm={handleRemoveVerifiedPhone}
        />
      )}

      {modals.deletePhoneSuccess && (
        <DeleteNotificationSuccess
          closeModal={clearAllModals}
          clearAllModals={clearAllModals}
          subtitle="DELETE NOTIFICATION"
          title="Notification Deleted"
          text="Your notification has been successfully deleted."
        />
      )}

      {modals.deleteEmailSuccess && (
        <DeleteNotificationSuccess
          closeModal={clearAllModals}
          clearAllModals={clearAllModals}
          subtitle="DELETE NOTIFICATION"
          title="Notification Deleted"
          text="Your notification has been successfully deleted."
        />
      )}
    </div>
  );
};

export default NotificationsPage;
