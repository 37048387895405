import React from "react";
import cn from "classnames";

import { Button } from "../../common/button";
// import testCompany from "../../../media/images/testCompany.png";
// import { ReactComponent as ClockIcon } from "../../../media/icons/clock.svg";
import { ReactComponent as IconCheck } from "../../../media/icons/sucess-check.svg";
import styles from "./headingProfile.module.css";
import { ReactComponent as SaveIcon } from "../../../media/icons/heart.svg";

const HeadingProfile = ({ socialImage, name, logo, consultation, phone, isShowFavorites, isFavorite, removeFavorite, addToFavorite }) => {
  return (
    <div className={styles.container}>
      {socialImage && (
        <div className={styles.banner}>
          <img src={socialImage} alt="cover" />
        </div>
      )}

      <div className={cn(styles.content, socialImage ? styles.contentWithoutBanner : "")}>
        <span className={styles.avatar}>
          <img src={logo} alt="avatar" />
        </span>
        <div className={styles.heading}>
          <h3 className={styles.name}>{name}</h3>
          {/*
          <p className={styles.companyName}>
            <img src={testCompany} alt="" />
            _Latham & Watkins
          </p>
          */}
        </div>
        <div className={styles.flex}>
          {consultation ? (
            <span className={styles.freeCancel}>
              <IconCheck />
              Free consultation
            </span>
          ) : null}
          {/*
          <span className={styles.time}>
            <ClockIcon />
            _11:46 pm local time
          </span>
          */}
        </div>

        <div className={styles.actions}>
          {phone && <Button className={styles.message}>_Message</Button>}

          {phone && <Button className={styles.phone}>_(713) 999-5447</Button>}
        </div>
      </div>
      {isShowFavorites && (
        <>
          {isFavorite ? (
            <Button className={styles.favoritesButton} onClick={removeFavorite}>
              <SaveIcon className={styles.iconActive} />
            </Button>
          ) : (
            <Button className={styles.favoritesButton} onClick={addToFavorite}>
              <SaveIcon />
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default HeadingProfile;
