import React from "react";

import styles from "./locationsProfile.module.css";
import { ReactComponent as LocationIcon } from "../../../media/icons/location.svg";
import { ReactComponent as PhoneIcon } from "../../../media/icons/phone-filled.svg";

const LocationsProfile = ({ locations, website }) => {
  return (
    <div className={styles.content}>
      <h3 className={styles.title}>Location(s)</h3>

      <h5 content={styles.name}>_Latham and Watkins</h5>

      <div className={styles.flex}>
        {locations.map((item, index) => {
          return (
            <div className={styles.location}>
              <h6 className={styles.locationName}>{index === 0 ? "Primary Office Location" : `Office Location ${index + 1}`}</h6>
              <p className={styles.locationAddress}>
                <LocationIcon />
                {item.street}
                {item.city ? `, ${item.city}` : ""}
                {item.state ? `, ${item.state} ` : ""}
                {item.zip}
              </p>
              <div className={styles.locationPhone}>
                <PhoneIcon />
                <div>
                  {item.phone ? <span>{item.phone}</span> : null}
                  {item.fax ? <span>{item.fax}</span> : null}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <h6 className={styles.websiteTitle}>Website</h6>
      {website && (
        <a href={website} className={styles.website}>
          {website}
        </a>
      )}
    </div>
  );
};

export default LocationsProfile;
