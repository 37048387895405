import React, { useState, useEffect } from "react";

import Tabs from "./tabs";
import Filter from "../saved/filter";
import Profiles from "../saved/profiles";
import Organizations from "../saved/organizations";
import Events from "../saved/events";
import Jobs from "../saved/jobs";
import FollowingModal from "./followingModal";
import { ReactComponent as SearchIcon } from "../../media/icons/search.svg";
import { ReactComponent as PencilIcon } from "../../media/icons/edit-pen-white.svg";
import styles from "./feed.module.css";

const Feed = () => {
  const [filter, setFilter] = useState({});
  const [search, setSearch] = useState("");
  const [isShowModal, setIsShowModal] = useState(false);

  const all = { value: "All", label: "all label" };
  const profiles = { value: "Profiles", label: "Profiles label" };
  const organizations = { value: "Organizations", label: "Organizations label" };
  const jobs = { value: "Jobs", label: "Jobs label" };
  const events = { value: "Events", label: "Events label" };

  const filterOptions = [all, profiles, organizations, jobs, events];

  useEffect(() => {
    if (!filter.value) {
      setFilter(all);
    }
  }, []);

  const handleShowModal = () => {
    setIsShowModal(true);
  };

  const handleHideModal = () => {
    setIsShowModal(false);
  };

  return (
    <div className={styles.wrapper}>
      {isShowModal && <FollowingModal onClose={handleHideModal} />}
      <Tabs />

      <div className={styles.searchContent}>
        <SearchIcon className={styles.searchIcon} />
        <input type="text" className={styles.search} value={search} onChange={event => setSearch(event.target.value)} />
        <button type="button" className={styles.reset} onClick={() => setSearch("")}>
          Reset
        </button>
      </div>

      <Filter filter={filter} setFilter={setFilter} filterOptions={filterOptions} />

      <div className={styles.flex}>
        <div className={styles.following}>
          <div className={styles.followingHeading}>
            <h6 className={styles.followingTitle}>Following (8)</h6>
            <button type="button" className={styles.followingEdit} onClick={handleShowModal}>
              <PencilIcon />
            </button>
          </div>
          <div className={styles.followingList}>
            <button type="button" className={styles.followingItem}>
              Turkish Law Chicago
            </button>
            <button type="button" className={styles.followingItem}>
              UX/UI Designer
            </button>
            <button type="button" className={styles.followingItem}>
              Turkish Doctors Chicago
            </button>
          </div>
        </div>
        <div className={styles.content}>
          <h1 className={styles.title}>
            Shows results: <span>135</span>
          </h1>

          {(filter.value === profiles.value || filter.value === all.value) && (
            <Profiles
              isExpand
              onClick={() => setFilter(profiles)}
              isActive={filter.value === profiles.value}
              handleClickAll={() => setFilter(all)}
            />
          )}

          {(filter.value === organizations.value || filter.value === all.value) && (
            <Organizations
              isExpand
              onClick={() => setFilter(organizations)}
              isActive={filter.value === organizations.value}
              handleClickAll={() => setFilter(all)}
            />
          )}

          {(filter.value === jobs.value || filter.value === all.value) && (
            <Jobs isExpand onClick={() => setFilter(jobs)} isActive={filter.value === jobs.value} handleClickAll={() => setFilter(all)} />
          )}

          {(filter.value === events.value || filter.value === all.value) && (
            <Events
              isExpand
              onClick={() => setFilter(events)}
              isActive={filter.value === events.value}
              handleClickAll={() => setFilter(all)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Feed;
