import React, { useCallback, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import Footer from "../footer";
import PublicHeader from "../publicHeader";
import EmptyState from "../publicListing/emptyState";

import Aside from "../publicOrganizations/profileAside";
import About from "../publicOrganizations/about";
import Schedule from "../publicOrganizations/schedule";
import Heading from "../publicOrganizations/headingProfile";
import TimeZone from "../publicOrganizations/timeZone";
import Location from "../publicOrganizations/locationsProfile";
import Tags from "../publicOrganizations/tags";
import Video from "../publicOrganizations/video";
import Languages from "../publicOrganizations/languages";
import Socials from "../publicOrganizations/socials";
import Licenses from "../publicOrganizations/licenses";
import Insurances from "../publicOrganizations/insurances";
import Educations from "../publicOrganizations/educations";
import Associations from "../publicOrganizations/associations";
import { actions } from "../../actions/publicListings";
import { OverlayPreloader } from "../common/preloader";
import styles from "./publicProfile.module.css";
import { actions as profileActions } from "../../actions/account/profile";

const PublicOrganization = ({ listingId }) => {
  const dispatch = useDispatch();
  const getListing = useCallback(id => dispatch(actions.getListing(id)), [dispatch]);
  const addToFavorite = useCallback(id => dispatch(actions.addToFavorite(id)), [dispatch]);
  const removeFavorite = useCallback(id => dispatch(actions.removeFavorite(id)), [dispatch]);
  const getProfile = useCallback(() => dispatch(profileActions.getProfile()), [dispatch]);
  const { preloaders, listing } = useSelector(state => state.publicListing);
  const { account } = useSelector(state => state);
  const { profile } = account;

  const {
    description,
    bookmeetings,
    insurances,
    lawfirms: locations,
    practices,
    languages,
    licenses,
    socials,
    attorney,
    consultation,
    listings_image_name: logo,
    education,
    associations,
    website,
    phone,
    bookmarked: isFavorite,
    listing_id
  } = listing;

  useEffect(() => {
    getListing(listingId);
  }, [listingId]);

  useEffect(() => {
    if (!profile.user_id) {
      getProfile();
    }
  }, [profile.user_id]);

  return (
    <>
      {preloaders.page && <OverlayPreloader />}

      <PublicHeader />

      <div className={styles.main}>
        <div className={styles.container}>
          {!listing.listing_id ? (
            <EmptyState />
          ) : (
            <>
              <div className={styles.content}>
                <Heading
                  name={attorney}
                  logo={logo}
                  consultation={consultation}
                  phone={phone}
                  addToFavorite={() => addToFavorite(listing_id)}
                  removeFavorite={() => removeFavorite(listing_id)}
                  isShowFavorites={profile.user_id}
                  isFavorite={isFavorite}
                />
                {bookmeetings ? <Schedule bookmeetings={bookmeetings} /> : null}
                <TimeZone />
                {practices.length ? <Tags title="Industries" tags={practices.map(item => item._practice)} /> : null}

                <About description={description} />

                {licenses.length ? <Licenses licenses={licenses} /> : null}
                {insurances.length ? <Insurances insurances={insurances} /> : null}
                {languages.length ? <Languages languages={languages} /> : null}
                {education.length ? <Educations educations={education} /> : null}
                {associations.length ? <Associations associations={associations} /> : null}

                <Location locations={locations} website={website} />

                <Video />
                {socials.length ? <Socials socials={socials} /> : null}
              </div>
              <Aside />
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PublicOrganization;
