import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import queryString from "query-string";
import cn from "classnames";

import { Question as QuestionBox } from "./components/question";
import { ReplyBox } from "./components/replyBox";
import { AnswersBox } from "./components/answersBox";
import { Next } from "./components/next";
import { Prev } from "./components/prev";
import { OverlayPreloader } from "../common/preloader";
import { Notification } from "../common/notification";
import { actions as questionsActions } from "../../actions/questions";
import { useUpdateQuestionsIds, useDocTitle } from "../../helpers/hooks";
import styles from "./question.module.css";

const Question = () => {
  const location = useLocation();
  const { id } = useParams();
  const { tab } = queryString.parse(location.search);
  const [sortAnswers, setSortAnswers] = useState(false);

  const dispatch = useDispatch();
  const getQuestions = () => dispatch(questionsActions.getQuestions());
  const cleanCurrentQuestions = () => dispatch(questionsActions.cleanCurrentQuestions());
  const getQuestionAnswers = questionId => dispatch(questionsActions.getQuestionAnswer(questionId));
  const { practices, currentQA, updateQuestionAnswers, preloader, error } = useSelector(state => state?.questions);
  const { question, answers } = currentQA;

  const submitFormAnswer = answer => {
    dispatch(questionsActions.sendReply({ answer, answer_id: question._answer_id }));
  };

  const onChangeSelect = event => {
    setSortAnswers(event.target.value);
  };

  useDocTitle("Questions and Answers");

  useEffect(() => {
    getQuestions();

    return () => {
      cleanCurrentQuestions();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    getQuestionAnswers(id);
  }, [id, updateQuestionAnswers]); // eslint-disable-line

  const { prevQuestionId, nextQuestionId } = useUpdateQuestionsIds(id, tab);
  const reversedAnswers = sortAnswers ? [...answers.reverse()] : [...answers];

  return (
    <div className={cn("wrapper", styles.wrapper)}>
      <div className={styles.questionBox}>
        {preloader && <OverlayPreloader />}
        {error && <Notification error>{error}</Notification>}
        <Next nextQuestionId={nextQuestionId} tab={tab} />
        <Prev prevQuestionId={prevQuestionId} tab={tab} />
        {question && <QuestionBox practices={practices} question={question} />}
      </div>
      <ReplyBox submitFormAnswer={submitFormAnswer} />
      <AnswersBox onChangeSelect={onChangeSelect} answersLength={answers.length} answers={reversedAnswers} />
    </div>
  );
};

export default Question;
