import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { ReactComponent as AttentionIcon } from "../../../../media/icons/attention.svg";
import { ReactComponent as PenIcon } from "../../../../media/icons/edit-pen-white.svg";
import styles from "./followUpQuestions.module.css";
import { Button } from "../../../common/button";
import { statusesConstant } from "../../../../constants/referralRequestDetail";

export const FollowUpQuestions = ({ followups, onOpenModal, status }) => {
  const { t } = useTranslation();
  const isShowEditButton = [statusesConstant.pendingReview, statusesConstant.pending].includes(status);
  const isMustBeAnswer = followups.some(item => item.answer.length === 1 && item.answer[0] === "");
  const additionalClassName = isMustBeAnswer ? styles.colorAttention : "";
  const additionalClassNameButton = isMustBeAnswer ? styles.editBtnRed : "";

  if (!followups?.length) {
    return null;
  }

  return (
    <>
      <div className={styles.topContent}>
        <div className={styles.titleBox}>
          {isMustBeAnswer && <AttentionIcon className={styles.icon} />}
          <h3 className={cn(styles.label, additionalClassName)}>{t("dashboard_referrals_details_followup_title")}</h3>
        </div>
        {(isMustBeAnswer || isShowEditButton) && (
          <Button className={cn(styles.editBtn, additionalClassNameButton)} onClick={onOpenModal}>
            <PenIcon className={styles.penIcon} />
          </Button>
        )}
      </div>

      <div>
        {followups.map(item => {
          return (
            <div key={item.lead_followup_id} className={styles.itemQuestionAnswers}>
              <p className={styles.question}>{item.question}</p>

              <div className={styles.answerBox}>
                <span className={styles.myAnswer}>{t("dashboard_referrals_details_my_answer")}:</span>
                {item.answer.length === 1 && item.answer[0] === "" && <span className={styles.missing}>[Missing]</span>}

                {item.answer.map(elem => {
                  if (item.answer_type === "select") {
                    return (
                      <p key={elem} className={styles.text}>
                        {elem}
                      </p>
                    );
                  }

                  return (
                    <span key={elem} className={styles.answerTextSimple}>
                      {elem}
                    </span>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      <hr className={styles.hr} />
    </>
  );
};
