import React from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

import { customStylesMultiMobile as selectStyle } from "../../../../constants/select";
import styles from "./professionsMobile.module.css";

const areEqual = (prevProps, nextProps) => {
  return nextProps.activeFilters.professions.length === prevProps.activeFilters.professions.length;
};

export const ProfessionsMobile = React.memo(({ activeFilters, setActiveFilters }) => {
  const questions = useSelector(state => state.questions);
  const { professions } = questions;

  const professionsForFilterMobile = professions?.map(({ profession_id: value, title: label }) => ({ value, label }));

  if (!professions?.length) {
    return null;
  }

  return (
    <div className={styles.selectMember}>
      <Select
        isMulti
        options={professionsForFilterMobile}
        onChange={opts => {
          setActiveFilters({ ...activeFilters, professions: opts?.map(({ value }) => value) || [], isFirstRender: false });
        }}
        placeholder="Filter by Professions..."
        styles={selectStyle}
      />
    </div>
  );
}, areEqual);
