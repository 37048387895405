import React, { useEffect } from "react";
import cn from "classnames";

import { Button } from "../button";
import closeIcon from "../../../media/icons/close-grey.svg";
import { disableScroll, enableScroll } from "../../../helpers";
import { useKeyDownEscape } from "../../../helpers/hooks";
import styles from "./modal.module.css";

export const Modal = ({ children, className, classNameMain, classNameButton, closeModal, clearAllModals }) => {
  useEffect(() => {
    disableScroll();
    return () => {
      enableScroll();
    };
  }, []);

  const resetModals = () => {
    clearAllModals();
  };

  useKeyDownEscape(resetModals);

  return (
    <section className={cn(styles.wrapper, className)}>
      {/* eslint-disable-next-line */}
      <aside role="button" tabIndex="0" className={styles.leftAside} onClick={resetModals} />
      <main className={cn(styles.content, classNameMain)}>
        <Button className={cn("button--clear", styles.closeBtn, classNameButton)} onClick={closeModal}>
          <img src={closeIcon} alt="close" />
        </Button>
        {children}
      </main>
    </section>
  );
};
