import React, { useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import PublicHeader from "../publicHeader";
import Footer from "../footer";
import { ReactComponent as IconCheck } from "../../media/icons/check-green.svg";
// import { ReactComponent as IconClose } from "../../media/icons/close-cyrcle.svg";
import { ReactComponent as IconFlash } from "../../media/icons/flash.svg";
import styles from "./pricing.module.css";
import { Switch } from "../common/switch";
import { useDocTitle } from "../../helpers/hooks";

const Pricing = () => {
  const [active, setActive] = useState(false);
  const { t } = useTranslation();

  useDocTitle("dashboard_pricing_metatitle");

  return (
    <>
      <PublicHeader />

      <div className={styles.section}>
        <div className={styles.wrapper}>
          <div>
            <h1 className={styles.title}>{t("dashboard_pricing_h1")}</h1>
            <div className={styles.pricingToggle}>
              <div className={cn(styles.pricingToggleTitle, active ? styles.pricingToggleTitleUnactive : "")}>
                {t("dashboard_pricing_monthly")}
              </div>

              <Switch selected={active} value="" handleSwitch={() => setActive(prev => !prev)} />

              <div
                className={cn(styles.pricingToggleTitle, styles.pricingToggleTitleAnnually, active ? styles.pricingToggleTitleActive : "")}
              >
                {t("dashboard_pricing_yearly")}
                <span className={styles.pricingToggleLabel}>
                  <IconFlash />
                  {t("dashboard_pricing_discount")}
                </span>
              </div>
            </div>

            <div className={styles.pricing}>
              <div className={styles.pricingContent}>
                <PricingPlanFree />

                <PricingPlanMonth active={active} />
              </div>
            </div>

            <div className={styles.helpContent}>
              <p className={styles.helpText}>{t("dashboard_pricing_knowledgebase_text")}</p>
              <a target="_blank" rel="noreferrer" href="https://help.heritageweb.com/" className={styles.helpLink} type="button">
                {t("dashboard_pricing_knowledgebase_button")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const PricingPlanMonth = ({ active }) => {
  const { t } = useTranslation();

  const [activeList, setActiveList] = useState(false);
  const toggleList = () => {
    setActiveList(prev => !prev);
  };

  return (
    <div className={cn(styles.pricingItem, styles.pricingItemBlue)}>
      <h2 className={styles.pricingTitle}>{t("dashboard_pricing_sponsor_title")}</h2>
      <span className={styles.pricingType}>{t("dashboard_pricing_listing")}</span>
      <span className={styles.pricingPrice}>${active ? "20" : "25"}</span>
      <span className={styles.pricingPeriod}>{t("dashboard_pricing_sponsor_subtitle")}</span>

      <div className={cn(styles.pricingIncludes, activeList ? styles.pricingIncludesActive : "")}>
        <span className={styles.pricingSubtitle}>Everything in free, plus:</span>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_sponsor_benefits1_bullet1")}
        </p>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_sponsor_benefits1_bullet2")}
        </p>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_sponsor_benefits1_bullet3")}
        </p>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_sponsor_benefits1_bullet4")}
        </p>
      </div>
      <div className={styles.pricingButtonContent}>
        <button className={styles.pricingHide} type="button" onClick={toggleList}>
          {activeList ? "Hide features" : "See all features"}
        </button>
        <Link to="/login" className={styles.pricingButton}>
          Become a Member
        </Link>
      </div>
    </div>
  );
};

const PricingPlanFree = () => {
  const [activeList, setActiveList] = useState(false);
  const { t } = useTranslation();

  const toggleList = () => {
    setActiveList(prev => !prev);
  };

  return (
    <div className={styles.pricingItem}>
      <h2 className={styles.pricingTitle}>{t("dashboard_pricing_free_title")}</h2>
      <span className={styles.pricingType}>{t("dashboard_pricing_listing")}</span>
      <span className={styles.pricingPrice}>$0</span>
      <span className={styles.pricingPeriod}>{t("dashboard_pricing_free_subtitle")}</span>

      <div className={cn(styles.pricingIncludes, activeList ? styles.pricingIncludesActive : "")}>
        <span className={styles.pricingSubtitle}>{t("dashboard_pricing_free_benefits1_title")}</span>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits1_bullet1")}
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits1_bullet2")}
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits1_bullet3")}
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits1_bullet4")}
        </p>

        <span className={styles.pricingSubtitle}>{t("dashboard_pricing_free_benefits2_title")}</span>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits2_bullet1")}
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits2_bullet2")}
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits2_bullet3")}
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits2_bullet4")}
        </p>

        <span className={styles.pricingSubtitle}>{t("dashboard_pricing_free_benefits3_title")}</span>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits3_bullet1")}
        </p>

        <span className={styles.pricingSubtitle}>{t("dashboard_pricing_free_benefits4_title")}</span>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits4_bullet1")}
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits4_bullet2")}
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits4_bullet3")}
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits4_bullet4")}
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits4_bullet5")}
        </p>

        {/* Remove in June
        <span className={styles.pricingSubtitle}>Seamless platform performance</span>
        <p className={cn(styles.pricingIncludesItem, styles.pricingIncludesItemNotIncluded)}>
          <IconClose />
          {t("dashboard_pricing_sponsor_benefits1_bullet2")}
        </p>


        <span className={styles.pricingSubtitle}>Show your best reviews</span>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          Republish Avvo reviews
        </p>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          Republish Yelp reviews
        </p>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          Publish press releases
        </p>

        <span className={styles.pricingSubtitle}>Integrations</span>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          Zapier integration
        </p>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          Facebook integration
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          Instagram integration
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          Twitter integration
        </p>

        <p className={cn(styles.pricingIncludesItem, styles.pricingIncludesItemNotIncluded)}>
          <IconClose />
          SMS notifications available
        </p>

        <span className={styles.pricingSubtitle}>Members only groups</span>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          LinkedIn private group invitation
        </p>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          Facebook private group invitation
        </p>

        <span className={styles.pricingSubtitle}>Track performance and history</span>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          Performance analytics
        </p>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          90-day message history
        </p>


        <span className={styles.pricingSubtitle}>Buy additional leads</span>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          Purchase individual leads from $9
        </p>

        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits4_bullet3")}
        </p>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          {t("dashboard_pricing_free_benefits4_bullet4")}
        </p>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          Each lead manually reviewed by our team
        </p>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          Leads matched by license, practice, directory, distance
        </p>
        <p className={styles.pricingIncludesItem}>
          <IconCheck />
          Read each lead`s short case description before purchasing
        </p>
        */}
      </div>
      <div className={styles.pricingButtonContent}>
        <button className={styles.pricingHide} type="button" onClick={toggleList}>
          {activeList ? "Hide features" : "See all features"}
        </button>
        <Link to="/login" className={styles.pricingButtonBlue}>
          Become a member
        </Link>
      </div>
    </div>
  );
};

export default Pricing;
