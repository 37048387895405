import React from "react";

import { Modal } from "../../common/modal";
import { OverlayPreloader } from "../../common/preloader";
import { ReactComponent as RemoveIcon } from "../../../media/icons/remove.svg";
import styles from "./followingModal.module.css";

const FollowingModal = ({ onClose, preloader }) => {
  return (
    <Modal clearAllModals={onClose} closeModal={onClose} classNameMain={styles.modal}>
      {preloader && <OverlayPreloader />}

      <h3 className={styles.title}>Following</h3>

      <div className={styles.followingItem}>
        <span>UX/UI Designer</span>
        <button type="button" className={styles.removeButton}>
          <RemoveIcon />
        </button>
      </div>

      <div className={styles.followingItem}>
        <span>UX/UI Designer</span>
        <button type="button" className={styles.removeButton}>
          <RemoveIcon />
        </button>
      </div>

      <div className={styles.followingItem}>
        <span>UX/UI Designer</span>
        <button type="button" className={styles.removeButton}>
          <RemoveIcon />
        </button>
      </div>

      <button className={styles.close} type="button" onClick={onClose}>
        Close
      </button>
    </Modal>
  );
};

export default FollowingModal;
