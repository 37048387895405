import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useDispatch } from "react-redux";

import { actions as payPerLeadDetailActions } from "../../actions/payPerLeadDetail";
import { getQueryParams } from "../../helpers";

export const useCallAllHooks = ({ success, lead_id: leadId, error, match, isNotGetLeadDetail }) => {
  // TODO: find a way to clear timeouts
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const getLead = useCallback((type, leadIds, userId) => dispatch(payPerLeadDetailActions.getLead(type, leadIds, userId)), [dispatch]);
  const getPractices = useCallback(() => dispatch(payPerLeadDetailActions.getPractices()), [dispatch]);
  const setLeadClear = useCallback(() => dispatch(payPerLeadDetailActions.setLeadClear()), [dispatch]);
  const [successNotification, setSuccessNotification] = useState(null);
  const [errorNotification, setErrorNotification] = useState(null);
  const queryParams = useMemo(() => getQueryParams(history.location.search), [history.location.search]);

  useEffect(() => {
    let notificationTimeout;
    let historyPushTimeout;

    if (success.buyLead) {
      setSuccessNotification(success.buyLead);

      notificationTimeout = setTimeout(() => {
        setSuccessNotification(null);
      }, 6000);

      historyPushTimeout = setTimeout(() => {
        history.push(`/paid-leads/${leadId}?purchased="1"${queryParams.user_id ? `&user_id=${queryParams.user_id}` : ""}`);
      }, 6000);
    }

    // test when local will work
    return () => {
      clearTimeout(notificationTimeout);
      clearTimeout(historyPushTimeout);
    };
  }, [success.buyLead]); // eslint-disable-line

  useEffect(() => {
    if (success.hideLead) {
      setSuccessNotification(success.hideLead);
      setTimeout(() => {
        setSuccessNotification(null);
      }, 2000);
      setTimeout(() => {
        history.push(`/paid-leads${queryParams.user_id ? `?user_id=${queryParams.user_id}` : ""}`);
      }, 2000);
    }
  }, [success.hideLead]); // eslint-disable-line

  useEffect(() => {
    if (error) {
      setErrorNotification(error);
      setTimeout(() => {
        setErrorNotification(null);
      }, 8000);
    }
  }, [error]); // eslint-disable-line

  useEffect(() => {
    if (!isNotGetLeadDetail) {
      const isPurchased = queryString.parse(location.search).purchased === "0" ? "notpurchased" : "purchased";
      const userId = queryString.parse(location.search).user_id;

      getLead(isPurchased, match.params.id, userId);
    }

    return () => {
      setLeadClear();
    };
  }, [location]); // eslint-disable-line

  useEffect(() => {
    getPractices();
  }, []); // eslint-disable-line

  return { successNotification, errorNotification };
};
