import React from "react";
import cn from "classnames";
import { useSelector } from "react-redux";

import { Button } from "../../common/button";
import { Preloader } from "../../common/preloader";
import styles from "./avatar.module.css";

export const Avatar = ({ togglePopup }) => {
  const {
    globalInfo: { initials },
    preloaders: { getGlobalInfo }
  } = useSelector(state => state.main);

  return (
    <Button onClick={() => togglePopup("links")} className={cn("button--clear", styles.buttonAvatar)}>
      <div className={styles.avatar}>
        {getGlobalInfo && <Preloader className={styles.preloader} />}
        {!getGlobalInfo && <span>{initials}</span>}
      </div>
    </Button>
  );
};
