import React from "react";

import ListingContent from "../listingContent";
import JobsListing from "../jobsListing";
import companyTest from "../../../media/images/companyTestSpotify.png";

const list = [
  {
    title: "UI / UX Designer_1",
    description:
      "We are looking for a UI Designer who would work close with our team at https://kulga.co/ to continue the website design...",
    position: "Chicago, IL",
    banner: companyTest,
    companyName: "Spotify"
  },
  {
    title: "UI / UX Designer_2",
    description:
      "We are looking for a UI Designer who would work close with our team at https://kulga.co/ to continue the website design...",
    position: "Chicago, IL",
    banner: companyTest,
    companyName: "Spotify"
  },
  {
    title: "UI / UX Designer_3",
    description:
      "We are looking for a UI Designer who would work close with our team at https://kulga.co/ to continue the website design...",
    position: "Chicago, IL",
    banner: companyTest,
    companyName: "Spotify"
  }
];

const Jobs = ({ isExpand, onClick, isActive, handleClickAll }) => {
  return (
    <>
      {isExpand ? (
        <JobsListing isExpand list={list} />
      ) : (
        <ListingContent title="Jobs" count="32" onClick={onClick} isActive={isActive} handleClickAll={handleClickAll}>
          <JobsListing list={list} />
        </ListingContent>
      )}
    </>
  );
};

export default Jobs;
