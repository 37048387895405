import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import PublicHeader from "../publicHeader";
import Footer from "../footer";
import Feature from "./components/feature";
import Integrations from "./components/integrations";
import Connected from "./components/connected";
import { useDocTitle } from "../../helpers/hooks";
import styles from "./features.module.css";

const Features = () => {
  const { t } = useTranslation();
  useDocTitle(t("dashboard_features_metatitle"));

  useEffect(() => {
    const metaDescription = document.querySelector("meta[name='description']");
    if (metaDescription) {
      metaDescription.setAttribute("content", t("dashboard_features_metadesc"));
    }
  }, []);

  return (
    <>
      <PublicHeader />

      <div className={styles.container}>
        <h1 className={styles.title}>Features</h1>
        <Feature />
        <Integrations />
      </div>
      <Connected />
      <Footer />
    </>
  );
};

export default Features;
