export const modals = {
  addCard: "addCard",
  editCard: "editCard",
  upgrade: "upgrade",
  payPending: "payPending",
  delete: "delete",
  deleteSuccess: "deleteSuccess",
  pause: "pause",
  pauseSuccess: "pauseSuccess",
  unpause: "unpause",
  unpauseSuccess: "unpauseSuccess",
  renew: "renew",
  payment: "payment",
  deleteEmail: "deleteEmail",
  deleteEmailSuccess: "deleteEmailSuccess",
  deletePhone: "deletePhone",
  deletePhoneSuccess: "deletePhoneSuccess",
  addPhone: "addPhone",
  addEmail: "addEmail",
  addPhoneSuccess: "addPhoneSuccess",
  addEmailSuccess: "addEmailSuccess",
  paymentSuccess: "paymentSuccess",
  confirm: "confirm",
  name: "name",
  photo: "photo",
  socialPhoto: "socialPhoto",
  phone: "phone",
  media: "media",
  practiceArea: "practiceArea",
  languages: "languages",
  bio: "bio",
  editLawFirm: "editLawFirm",
  lawFirmLocation: "lawFirmLocation",
  license: "license",
  education: "education",
  association: "association",
  avvoReviews: "avvoReviews",
  yelpReviews: "yelpReviews",
  verification: "verification",
  verificationSuccess: "verificationSuccess",
  insurances: "insurances",
  listingBasics: "listingBasics",
  downgrade: "downgrade",
  downgradePeriod: "downgradePeriod",
  downgradePeriodSuccess: "downgradePeriodSuccess",
  downgradeSuccess: "downgradeSuccess",
  addNewListing: "addNewListing"
};

export const typeReview = {
  avvo: "avvo",
  yelp: "yelp"
};

export const indexEdit = {
  education: null,
  association: null,
  license: null,
  lawFirmLocation: null,
  practiceArea: null,
  language: null,
  insurances: null
};

export const validationNames = {
  website: "Website",
  locations: "Locations",
  licenses: "Licenses",
  name: "Name",
  bio: "Bio",
  pictures: "Pictures",
  business: "Business",
  educations: "Educations",
  associations: "Associations",
  yelp: "Yelp",
  language: "Language",
  specialties: "Specialties",
  events: "Events",
  insurance: "Insurance",
  calendar: "Calendar",
  coverImage: "Cover Image",
  parentOrganization: "Parent Organization",
  tags: "Tags",
  communities: "Communities",
  job: "Job",
  socialMedia: "Social Media",
  additionalImages: "Additional Images",
  video: "Video",
  freeConsultation: "Free Consultation",
  linkedInCompany: "LinkedIn Company",
  linkedInProfile: "LinkedIn Profile"
};

export const verificationTypes = {
  phone: "phone",
  email: "email"
};

export const listingType = {
  regular: "regular",
  ppl: "ppl"
};

export const statuses = {
  active: "Active",
  approved: "Approved",
  draft: "Draft",
  pending: "Pending Review",
  pendingPayment: "Pending Payment",
  rejected: "Rejected",
  paused: "Paused",
  expired: "Expired",
  neverPublished: "Never Published",
  deleted: "Deleted",
  transferred: "Transferred"
};

export const redStatuses = ["Paused", "Pending Payment", "Expired", "Changes Rejected"];

export const emptyFilters = {
  freesponsor: ["free", "sponsor"],
  professiongroups: [
    { profession_group_id: "2", title: "Individual Professional’s Listing" },
    { profession_group_id: "3", title: "Organization’s Listing" },
    { profession_group_id: "4", title: "Place Of Worship Listing" }
  ]
};

export const filterTypes = {
  directory: "directory",
  status: "status",
  changes: "changes",
  option: "option"
};

export const imageDelivery = "https://imagedelivery.net";
