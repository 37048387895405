import axios from "axios";
import qs from "qs";
import queryString from "query-string";
import { differenceInCalendarDays } from "date-fns";
import { listing } from "../constants/actionTypes";
// import { actions as mainActions } from "./main";
import { listingType } from "../constants/listings";
import { actions as homeActions } from "./home";
import { track } from "../helpers/track";
import { eventName } from "../constants/track";

export const actions = {
  // @param {string} active - 0 / 1
  // @param {string} typeReview - avvo / yelp
  changeReviewsVisibility(typeReview) {
    return (dispatch, getState) => {
      const {
        listing: { memberData }
      } = getState();
      const active = +memberData[`${typeReview}_disabled`] === 1 ? "0" : "1";
      const data = { listing_id: memberData.listing_id, [`${typeReview}_disabled`]: active };
      dispatch(actions.setLoading(typeReview, true));

      axios({
        method: "post",
        responseType: "json",
        url: `/members/json-api/listing/update-${typeReview}`,
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(res => {
          if (res.data.ok) {
            dispatch({
              type: listing.SUBMIT_SUCCESS_REVIEWS,
              payload: {
                successMessage: "Changes saved",
                review: typeReview,
                active,
                memberData
              }
            });
          }
        })
        .catch(error => {
          console.error("changeReviewsVisibility", error);
          dispatch({
            type: listing.SUBMIT_CHANGES_FAIL,
            payload: "Error saving changes"
          });
        });
    };
  },

  resetDraft(listingId, history) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      // const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};

      const pattern = /jobs|events|organizations|profile/;
      const isProfile = history.location.pathname.includes("/profile");
      const listingName = isProfile ? "profiles" : history.location.pathname.match(pattern);
      const url = listingName ? `/members/api-v2/${listingName}` : `/members/json-api${teamUrl}/listing/delete-draft`;

      dispatch(actions.setLoading("memberData", true));

      axios({
        method: listingName ? "delete" : "get",
        responseType: "json",
        url,
        // url: `/members/json-api${teamUrl}/listing/delete-draft`,
        data: {
          listing_id: listingId,
          command: "reset"
        },
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(res => {
          dispatch({
            type: listing.GET_MEMBER_DATA,
            payload: { data: res.data.data, help: res.data._help }
          });
        })
        .catch(() => {
          dispatch(actions.setLoading("memberData", false));
        });
    };
  },

  getMemberData(listingId, history) {
    return (dispatch, getState) => {
      dispatch(actions.setLoading("memberData", true));

      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};

      axios({
        method: "get",
        responseType: "json",
        url: `/members/json-api${teamUrl}/listing`,
        params: {
          listing_id: listingId,
          ...userIdParams
        }
      })
        .then(res => {
          dispatch({
            type: listing.GET_MEMBER_DATA,
            payload: { data: res.data, help: res.data._help }
          });
        })
        .catch(err => {
          if (err?.response?.data?.error === "Not found" && history) {
            history.push("/404");
          } else {
            dispatch({
              type: listing.GET_MEMBER_DATA_FAIL,
              payload: err.response
            });
          }
        });
    };
  },

  getMemberDataNew(listingId, history) {
    return (dispatch, getState) => {
      dispatch(actions.setLoading("memberData", true));

      const {
        teams: { currentTeam }
      } = getState();

      const teamUrl = currentTeam?.value ? "/team" : "";
      // const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};
      const isEvensOrJobsPage = ["/my-jobs", "/my-events", "/my-organizations"].some(item => history.location.pathname.includes(item));
      const isEvents = history.location.pathname.includes("my-events");
      const isJobs = history.location.pathname.includes("my-jobs");
      const isOrganizatios = history.location.pathname.includes("my-organizations");

      const pattern = /jobs|events|organizations/;
      const listingName = history.location.pathname.match(pattern);
      const eventsListingExpand = isEvents ? ",event_schedules,communities,event_tickets,socials,lawfirms" : "";
      const organizationsListingExpand = isOrganizatios
        ? ",lawfirms,bookmeetings,insurances,education,associations,socials,licenses,communities"
        : "";
      // ,languages
      const jobsListingExpand = isJobs ? ",jobs,lawfirms,socials,parent_company" : "";
      const expand = "filling_options,validations,practices,tags,verifiedphones,verifiedemails,images,languages";
      const url = isEvensOrJobsPage
        ? `/members/api-v2/${listingName}/get?id=${listingId}&expand=${expand}${eventsListingExpand}${jobsListingExpand}${organizationsListingExpand}`
        : `/members/json-api${teamUrl}/listing`;

      axios({
        method: "get",
        responseType: "json",
        url
      })
        .then(res => {
          dispatch({
            type: listing.GET_MEMBER_DATA,
            payload: {
              data: { ...res.data.data, languages: res.data.data.languages || [] },
              help: { ...res.data.data.filling_options, validations: res.data.data.validations }
            }
          });
        })
        .catch(err => {
          if (err?.response?.data?.error === "Not found" && history) {
            history.push("/404");
          } else {
            dispatch({
              type: listing.GET_MEMBER_DATA_FAIL,
              payload: err.response
            });
          }
        });
    };
  },

  getDetailProfilePage(history) {
    return dispatch => {
      dispatch(actions.setLoading("memberData", true));

      // ,images
      axios({
        method: "get",
        responseType: "json",
        url:
          "/members/api-v2/profiles/get?expand=filling_options,validations,practices,tags,verifiedphones,verifiedemails,lawfirms,insurances,languages,education,associations,bookmeetings,socials,licenses,communities"
      })
        .then(res => {
          dispatch({
            type: listing.GET_MEMBER_DATA,
            payload: {
              data: res.data.data,
              help: { ...res.data.data.filling_options, validations: res.data.data.validations }
            }
          });
        })
        .catch(err => {
          if (err?.response?.data?.error === "Not found" && history) {
            history.push("/404");
          } else {
            dispatch({
              type: listing.GET_MEMBER_DATA_FAIL,
              payload: err.response
            });
          }
        });
    };
  },

  setLoading(section, isSectionLoading) {
    return {
      type: listing.SET_LOADING,
      payload: { section, isSectionLoading }
    };
  },

  updateActiveUpgradeOptions(activeOption) {
    return {
      type: listing.UPDATE_ACTIVE_UPGRADE_OPTIONS,
      payload: activeOption
    };
  },

  closeAllModals() {
    return {
      type: listing.SUBMIT_CHANGES_CLOSE_MODALS
    };
  },

  submitPhoneEmail(params, isShowModal, typeModal) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};

      dispatch(actions.setLoading("notifications", true));
      axios({
        method: "post",
        responseType: "json",
        url: `/members/json-api${teamUrl}/listing/update-emails-phones`,
        data: qs.stringify({ ...params, ...userIdParams }),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(res => {
          dispatch({
            type: listing.GET_MEMBER_DATA,
            payload: { data: res.data }
          });
          if (isShowModal) {
            dispatch({
              type: listing.SUBMIT_PHONE_EMAIL_SUCCESS,
              payload: typeModal
            });
          }
        })
        .catch(() => {
          dispatch({
            type: listing.SUBMIT_CHANGES_FAIL,
            payload: "Error saving changes"
          });
        });
    };
  },

  submitImages(memberData, section, history) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      // const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};

      const isEvensOrJobsPage = ["/my-events", "/my-jobs", "/my-organizations", "/profile"].some(item =>
        history.location.pathname.includes(item)
      );
      const pattern = /jobs|events|organizations|profile/;
      const isProfile = history.location.pathname.includes("/profile");
      const listingName = isProfile ? "profiles" : history.location.pathname.match(pattern);
      const url = isEvensOrJobsPage ? `/members/api-v2/${listingName}?return_object=1` : `/members/json-api${teamUrl}/listing/update`;

      dispatch(actions.setLoading(section, true));

      return axios({
        method: "post",
        responseType: "json",
        url,
        // url: /`/members/json-api${teamUrl}/listing/update``,
        // data: qs.stringify({ ...memberData, ...userIdParams }),
        data: memberData,
        // data: memberData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(res => {
          if (res.headers["content-type"] === "text/html; charset=utf-8") {
            history.push("/404");
          } else {
            dispatch({
              type: listing.GET_MEMBER_DATA,
              payload: { data: res.data.data, successMessage: true }
            });

            dispatch(actions.setLoading(section, false));
          }
          return res;
        })
        .catch(err => {
          // duplicate tab edge case
          // trigger 404 when listing was previously deleted
          if (err?.response?.data?.error === "Not found") {
            history.push("/404");
          } else {
            dispatch({
              type: listing.SUBMIT_CHANGES_FAIL,
              payload: "Error saving changes"
            });
          }
        });
    };
  },

  submitChanges(memberData, section, history, isCloseModals, isReload) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      // const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};

      const isEvensOrJobsPage = ["/my-events", "/my-jobs", "/my-organizations", "/profile"].some(item =>
        history.location.pathname.includes(item)
      );
      const pattern = /jobs|events|organizations|profile/;
      const isProfile = history.location.pathname.includes("/profile");
      const listingName = isProfile ? "profiles" : history.location.pathname.match(pattern);
      const url = isEvensOrJobsPage ? `/members/api-v2/${listingName}?return_object=1` : `/members/json-api${teamUrl}/listing/update`;

      const newLocations = memberData.lawfirms.map(item => {
        if (Object.values(item.subs.hours) && !Object.values(item.subs.hours)[0]?.length) {
          return { ...item, "subs][hours][0": null };
        }

        return item;
      });
      const newData = { ...memberData, lawfirms: newLocations };
      dispatch(actions.setLoading(section, true));

      return axios({
        method: isEvensOrJobsPage ? "put" : "post",
        responseType: "json",
        url,
        // url: /`/members/json-api${teamUrl}/listing/update``,
        data: qs.stringify({ ...newData }),
        // data: newData,
        // data: memberData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(res => {
          if (res.headers["content-type"] === "text/html; charset=utf-8") {
            history.push("/404");
          } else {
            if (isReload) {
              dispatch(this.getMemberDataNew(res.data.data.listing_id, history));
            } else {
              dispatch({
                type: listing.GET_MEMBER_DATA,
                payload: { data: { ...memberData, ...res.data.data }, successMessage: true }
              });
            }
            if (isCloseModals) {
              dispatch(this.closeAllModals());
            }
            dispatch(actions.setLoading(section, false));
          }
          return res;
        })
        .catch(err => {
          // duplicate tab edge case
          // trigger 404 when listing was previously deleted
          if (err?.response?.data?.error === "Not found") {
            history.push("/404");
          } else {
            dispatch({
              type: listing.SUBMIT_CHANGES_FAIL,
              payload: "Error saving changes"
            });
          }
        });
    };
  },

  autoSaveListing(field, section, history, command) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam },
        listing: { memberData }
      } = getState();

      const teamUrl = currentTeam?.value ? "/team" : "";
      // const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};

      const isEvensOrJobsPage = ["/my-events", "/my-jobs", "/my-organizations", "/profile"].some(item =>
        history.location.pathname.includes(item)
      );
      const pattern = /jobs|events|organizations|profile/;
      const isProfile = history.location.pathname.includes("/profile");
      const listingName = isProfile ? "profiles" : history.location.pathname.match(pattern);
      const url = isEvensOrJobsPage ? `/members/api-v2/${listingName}` : `/members/json-api${teamUrl}/listing/update`;

      /*
      const newLocations = memberData.lawfirms.map(item => {
        if (Object.values(item.subs.hours) && !Object.values(item.subs.hours)[0]?.length) {
          return { ...item, "subs][hours][0": null };
        }

        return item;
      }); lawfirms: newLocations
      */

      const newData = { ...field };
      dispatch(actions.setLoading(section, true));

      return axios({
        method: "post",
        responseType: "json",
        url,
        data: qs.stringify({ ...newData, command }),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(res => {
          dispatch({
            type: listing.GET_MEMBER_DATA,
            payload: { data: { ...memberData, [section]: res.data.data[section], ...res.data.data.listing }, successMessage: true }
          });
          dispatch(actions.setLoading(section, false));
        })
        .catch(err => {
          if (err?.response?.data?.error === "Not found") {
            history.push("/404");
          } else {
            dispatch({
              type: listing.SUBMIT_CHANGES_FAIL,
              payload: "Error saving changes"
            });
          }
        });
    };
  },

  clearSuccess() {
    return {
      type: listing.CLEAR_CHANGES_FIELD
    };
  },

  setMembershipClear() {
    return {
      type: listing.SET_MEMBERSHIP_DATA_CLEAR
    };
  },

  toggleActivityOfModal(modal, isActive) {
    return {
      type: listing.TOGGLE_ACTIVITY_OF_MODAL,
      payload: {
        modal,
        isActive
      }
    };
  },

  activeNotificationModal(modal, data) {
    return {
      type: listing.ACTIVE_NOTIFICATION_MODAL,
      payload: {
        modal,
        data
      }
    };
  },

  clearAllModals() {
    return {
      type: listing.CLEAR_ALL_MODALS
    };
  },

  uploadImage(imageData) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      // const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};

      if (currentTeam?.value) {
        imageData.append("user_id", currentTeam.value);
      }

      dispatch(actions.setLoading("photo", true));
      axios({
        method: "post",
        responseType: "json",
        mimeType: "multipart/form-data",
        processData: false,
        contentType: false,
        url: `/members/json-api${teamUrl}/listing-image/upload`,
        data: imageData,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(res => {
          dispatch({
            type: listing.UPLOAD_IMAGE,
            payload: res.data
          });
        })
        .catch(() => {
          dispatch({
            type: listing.UPLOAD_IMAGE_FAIL,
            payload: "Error during uploading an image"
          });
        });
    };
  },

  uploadSocialImage(imageData) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      // const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};

      if (currentTeam?.value) {
        imageData.append("user_id", currentTeam.value);
      }

      dispatch(actions.setLoading("socialImage", true));
      axios({
        method: "post",
        responseType: "json",
        mimeType: "multipart/form-data",
        processData: false,
        contentType: false,
        url: `/members/json-api${teamUrl}/listing-image/upload-smp`,
        data: imageData,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(res => {
          dispatch({
            type: listing.UPLOAD_SOCIAL_IMAGE,
            payload: res.data.cf_image_id
          });
        })
        .catch(() => {
          dispatch({
            type: listing.UPLOAD_SOCIAL_IMAGE_FAIL,
            payload: "Error during uploading an image"
          });
        });
    };
  },

  deleteImage(listingId, imageId) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};

      axios({
        method: "get",
        responseType: "json",
        url: `/members/json-api${teamUrl}/listing-image/delete?listing_id=${listingId}&image_id=${imageId}`,
        headers: { "Content-Type": "application/json" },
        data: {
          ...userIdParams
        }
      })
        .then(() => {
          dispatch({
            type: listing.DELETE_IMAGE,
            payload: imageId
          });
        })
        .catch(err => {
          dispatch({
            type: listing.DELETE_IMAGE_FAIL,
            payload: err
          });
        });
    };
  },

  cleanUpgradeOptions() {
    return {
      type: listing.UPGRADE_CLEAN
    };
  },

  setSavedPaymentData() {
    return {
      type: listing.SET_SAVED_PAYMENT_DATA
    };
  },

  clearSavedPaymentData() {
    return {
      type: listing.CLEAR_SAVED_PAYMENT_DATA
    };
  },

  deleteMembership(memberData, isHomePage, history, isListingDetail) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      // const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};
      const isEvensOrJobsPage = ["/my-events", "/my-jobs", "/my-organizations"].some(item => history.location.pathname.includes(item));
      const pattern = /jobs|events|organizations/;
      const listingName = history.location.pathname.match(pattern);

      dispatch(actions.setLoading("memberData", true));

      const url = isEvensOrJobsPage ? `/members/api-v2/${listingName}` : `/members/json-api${teamUrl}/listing/delete`;

      dispatch(actions.setLoading("deleteMembership", true));

      axios({
        method: isEvensOrJobsPage ? "delete" : "post",
        responseType: "json",
        // url: `/members/json-api${teamUrl}/listing/delete`,
        url,
        // data: qs.stringify({ ...memberData, ...userIdParams }),
        data: qs.stringify({ listing_id: memberData.listing_id }),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(() => {
          if (isHomePage) {
            dispatch(homeActions.getDashboardInfo());
          }

          dispatch({
            type: listing.DELETE_SUCCESS,
            payload: memberData.listing_id
          });

          if (isListingDetail) {
            history.push(`/my-${listingName}`);
          }
        })
        .catch(error => {
          console.error("deleteMembership", error);
          new Promise(resolve => {
            dispatch({
              type: listing.DELETE_FAIL,
              payload: "Failed to delete listing"
            });
            resolve();
          }).then(() => {
            dispatch({
              type: listing.CLEAR_ERRORS
            });
          });
        });
    };
  },

  downgradeMembership(id, isSingleListing, isHomePage, history) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      // const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};

      const pattern = /jobs|events|organizations|profile/;
      const isProfile = history.location.pathname.includes("/profile");
      const listingName = isProfile ? "profiles" : history.location.pathname.match(pattern);
      const url = listingName ? `/members/api-v2/${listingName}` : `/members/json-api${teamUrl}/listing/cancel`;

      dispatch(actions.setLoading("downgrade", true));

      axios({
        method: listingName ? "put" : "post",
        responseType: "json",
        url,
        // url: `/members/json-api${teamUrl}/listing/cancel`,
        data: qs.stringify({ listing_id: id, command: "cancel" }),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(() => {
          dispatch({
            type: listing.DOWNGRADE_SUCCESS
          });

          if (isHomePage) {
            dispatch(homeActions.getDashboardInfo());
          }

          if (isSingleListing) {
            dispatch(this.getMemberDataNew(id, history));
          } else {
            dispatch(this.getExistingMembershipsNew({}, true, 1, [], [], [], "", "", 10, [], [], "", history.location.pathname));
          }
        })
        .catch(error => {
          dispatch({
            type: listing.DOWNGRADE_FAIL,
            payload: error.response.data.error
          });
        });
    };
  },

  downgradeMembershipHow(id, isSingleListing, isHomePage, history) {
    return (dispatch, getState) => {
      dispatch(actions.setLoading("downgrade", true));

      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      // const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};

      const pattern = /jobs|events|organizations|profile/;
      const isProfile = history.location.pathname.includes("/profile");
      const listingName = isProfile ? "profiles" : history.location.pathname.match(pattern);
      const url = listingName ? `/members/api-v2/${listingName}` : `/members/json-api${teamUrl}/listing/cancel-now`;

      axios({
        method: listingName ? "put" : "post",
        responseType: "json",
        url,
        // url: `/members/json-api${teamUrl}/listing/cancel-now`,
        data: qs.stringify({ listing_id: id, command: "cancel_now" }),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(() => {
          dispatch({
            type: listing.DOWNGRADE_SUCCESS
          });

          if (isHomePage) {
            dispatch(homeActions.getDashboardInfo());
          } else if (isSingleListing) {
            dispatch(this.getMemberDataNew(id, history));
          } else {
            dispatch(this.getExistingMembershipsNew({}, true, 1, [], [], [], "", "", 10, [], [], "", history.location.pathname));
          }
        })
        .catch(error => {
          dispatch({
            type: listing.DOWNGRADE_FAIL,
            payload: error.response.data.error
          });
        });
    };
  },

  getTimeZones() {
    return dispatch => {
      axios({
        method: "get",
        responseType: "json",
        url: "/members/json-api/index/timezones"
      })
        .then(result => {
          dispatch({
            type: listing.GET_TIME_ZONES,
            payload: result.data
          });
        })
        .catch(error => {
          dispatch({
            type: listing.GET_TIME_ZONES_FAIL,
            payload: error
          });
        });
    };
  },

  getDirectoriesProfessions() {
    return dispatch => {
      axios({
        method: "get",
        responseType: "json",
        url: "/members/json-api/index/directories-professions-heritages"
      })
        .then(result => {
          dispatch({
            type: listing.GET_DIRECTORIES_PROFESSIONS,
            payload: result.data
          });
        })
        .catch(error => {
          dispatch({
            type: listing.GET_DIRECTORIES_PROFESSIONS_FAIL,
            payload: error
          });
        });
    };
  },

  unDowngradeMembership(id, isSingleListing, isHomePage, history) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      // const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};
      const pattern = /jobs|events|organizations|profile/;
      const isProfile = history.location.pathname.includes("/profile");
      const listingName = isProfile ? "profiles" : history.location.pathname.match(pattern);
      const url = listingName ? `/members/api-v2/${listingName}` : `/members/json-api${teamUrl}/listing/uncancel`;

      dispatch(actions.setLoading("downgrade", true));

      axios({
        method: listingName ? "put" : "post",
        responseType: "json",
        url,
        // url: `/members/json-api${teamUrl}/listing/uncancel`,
        data: qs.stringify({ listing_id: id, command: "uncancel" }),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(() => {
          dispatch({
            type: listing.UN_DOWNGRADE_SUCCESS
          });

          if (isHomePage) {
            dispatch(homeActions.getDashboardInfo());
          } else if (isSingleListing) {
            dispatch(this.getMemberDataNew(id, history));
          } else {
            dispatch(this.getExistingMembershipsNew({}, true, 1, [], [], [], "", "", 10, [], [], "", history.location.pathname));
          }
        })
        .catch(err => {
          dispatch({
            type: listing.UN_DOWNGRADE_FAIL,
            payload: err
          });
        });
    };
  },

  getExistingMemberships(
    filter,
    isLoading,
    page,
    dirId = [],
    professionGroupId = [],
    freeSponsor = [],
    professionName = "",
    sort,
    perPage,
    listActiveChange,
    listActiveStatus,
    isDraft,
    pathname,
    config
  ) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};
      const isEvensOrJobsPage = ["/my-events", "/my-jobs", "/my-organizations"].includes(pathname);
      const isOrganizations = ["/my-organizations"].includes(pathname);
      const listingName = isOrganizations ? "business" : pathname?.replace("/my-", "").toLowerCase();
      const professionId = isEvensOrJobsPage ? { profession_id: config[`${listingName}Profession`] } : {};

      if (!isLoading) {
        dispatch(actions.setLoading("memberships", true));
      }

      const searchName = professionName ? { prof_name: professionName } : {};
      const sortSearch = sort ? { sort } : {};
      const perPageSearch = perPage ? { per_page: perPage } : {};
      const listActiveChangeSearch = listActiveChange?.length ? { change_status: listActiveChange } : {};
      const listActiveStatusSearch = listActiveStatus?.length ? { publish_status: listActiveStatus } : {};
      const isDraftParams = isDraft ? { is_draft: "1" } : {};

      const filterParams = queryString.stringify(
        {
          dir_id: dirId,
          profession_group_id: professionGroupId,
          freesponsor: freeSponsor,
          page,
          ...searchName,
          ...sortSearch,
          ...perPageSearch,
          ...listActiveChangeSearch,
          ...listActiveStatusSearch,
          ...isDraftParams,
          ...userIdParams,
          ...professionId
        },
        { arrayFormat: "index" }
      );

      return axios({
        method: "get",
        responseType: "json",
        url: `/members/json-api${teamUrl}/listing?${filterParams}`
      })
        .then(res => {
          let listings;
          if (filter === listingType.ppl) {
            listings = res.data?.listings?.filter(({ membership }) => membership?.includes("Pay Per Lead"));
          } else if (filter === listingType.ppl) {
            listings = res.data?.listings?.filter(({ membership }) => !membership?.includes("Pay Per Lead"));
          } else {
            listings = res.data?.listings;
          }

          dispatch({
            type: listing.GET_MEMBERSHIPS,
            payload: {
              listings,
              filters: res.data.filters,
              lastPage: res.data.last_page,
              currentPage: res.data.current_page,
              perPage: res.data.per_page
            }
          });
        })
        .catch(error => {
          dispatch({
            type: listing.GET_MEMBERSHIPS_FAIL,
            payload: error.response
          });
        });
    };
  },

  getAllListings(
    filter,
    isLoading,
    page,
    dirId = [],
    professionGroupId = [],
    freeSponsor = [],
    professionName = "",
    sort,
    perPage,
    listActiveChange,
    listActiveStatus,
    isDraft,
    pathname,
    config
  ) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      // const teamUrl = currentTeam?.value ? "/team" : "";
      const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};
      const isEvensOrJobsPage = ["/my-events", "/my-jobs", "/my-organizations"].includes(pathname);
      const isOrganizations = ["/my-organizations"].includes(pathname);
      const listingName = isOrganizations ? "business" : pathname?.replace("/my-", "").toLowerCase();
      const professionId = isEvensOrJobsPage ? { profession_id: config[`${listingName}Profession`] } : {};

      if (!isLoading) {
        dispatch(actions.setLoading("memberships", true));
      }

      const searchName = professionName ? { prof_name: professionName } : {};
      const sortSearch = sort ? { sort } : {};
      const perPageSearch = perPage ? { limit: perPage } : {};
      const listActiveChangeSearch = listActiveChange?.length ? { change_status: listActiveChange } : {};
      const listActiveStatusSearch = listActiveStatus?.length ? { publish_status: listActiveStatus } : {};
      const isDraftParams = isDraft ? { is_draft: "1" } : {};

      const filterParams = queryString.stringify(
        {
          dir_id: dirId,
          profession_group_id: professionGroupId,
          freesponsor: freeSponsor,
          page,
          ...searchName,
          ...sortSearch,
          ...perPageSearch,
          ...listActiveChangeSearch,
          ...listActiveStatusSearch,
          ...isDraftParams,
          ...userIdParams,
          ...professionId
        },
        { arrayFormat: "index" }
      );

      return axios({
        method: "get",
        responseType: "json",
        url: `/members/api-v2/listings?${filterParams}`
      })
        .then(res => {
          /*
          let listings;
          if (filter === listingType.ppl) {
            listings = res.data.data?.filter(({ membership }) => membership?.includes("Pay Per Lead"));
          } else if (filter === listingType.ppl) {
            listings = res.data.data?.filter(({ membership }) => !membership?.includes("Pay Per Lead"));
          } else {
            listings = res.data.data;
          }
          */
          dispatch({
            type: listing.GET_MEMBERSHIPS,
            payload: {
              listings: res.data.data,
              filters: {
                // remove 2 fieds
                professiongroups: [
                  {
                    profession_group_id: 3,
                    title: "Individual professionals listing"
                  },
                  {
                    profession_group_id: 1,
                    title: "Organization\u2019s listing"
                  },
                  {
                    profession_group_id: 2,
                    title: "Place of worship listing"
                  }
                ],
                directories: [
                  {
                    dir_id: 1000,
                    title: "Afghantherapists.com"
                  },
                  {
                    dir_id: 301,
                    title: "Agentesdehablaespanol.com"
                  },
                  {
                    dir_id: 701,
                    title: "Albanianhousekeepers.com"
                  },
                  {
                    dir_id: 203,
                    title: "Arabicaccountants.com"
                  },
                  {
                    dir_id: 1163,
                    title: "Heritageweb.com"
                  },
                  {
                    dir_id: 38,
                    title: "Christianattorneys.net"
                  },
                  {
                    dir_id: 1161,
                    title: "Iranianlawyers.com"
                  },
                  {
                    dir_id: 11,
                    title: "Iranianlawyers.com"
                  },
                  {
                    dir_id: 1167,
                    title: "Iranianlawyers.com"
                  },
                  {
                    dir_id: 1168,
                    title: "Heritageweb.com"
                  }
                ],
                freesponsor: ["free", "sponsor"],
                change_status: ["Approved", "Pending Review", "Rejected"],
                publish_status: ["Never Published", "Active", "Paused", "Expired"]
              },
              lastPage: res.data.pagination.total_pages || 0,
              currentPage: res.data.pagination.current_page || 0,
              perPage: res.data.pagination.records_per_page || 0,
              isEvensOrJobsPage: true
            }
          });
        })
        .catch(error => {
          dispatch({
            type: listing.GET_MEMBERSHIPS_FAIL,
            payload: error.response
          });
        });
    };
  },

  getExistingMembershipsNew(
    filter,
    isLoading,
    page,
    dirId = [],
    professionGroupId = [],
    freeSponsor = [],
    professionName = "",
    sort,
    perPage,
    listActiveChange,
    listActiveStatus,
    isDraft,
    pathname
  ) {
    // getState
    return dispatch => {
      if (!isLoading) {
        dispatch(actions.setLoading("memberships", true));
      }
      /*
      const {
        teams: { currentTeam }
      } = getState();
      */
      // const teamUrl = currentTeam?.value ? "/team" : "";
      // const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};
      const isEvensOrJobsPage = ["/my-events", "/my-jobs", "/my-organizations"].includes(pathname);
      const listingName = pathname?.replace("/my-", "").toLowerCase();

      const searchName = professionName ? { name: professionName } : {};
      const sortSearch = sort ? { sort } : {};
      const perPageSearch = perPage ? { limit: perPage } : {};
      const listActiveChangeSearch = listActiveChange?.length ? { change_status: listActiveChange } : {};
      const listActiveStatusSearch = listActiveStatus?.length ? { publish_status: listActiveStatus } : {};
      const isDraftParams = isDraft ? { is_draft: "1" } : {};
      /*
      const filterParams = queryString.stringify(
        {
          dir_id: dirId,
          profession_group_id: professionGroupId,
          freesponsor: freeSponsor,
          page,
          ...searchName,
          ...sortSearch,
          ...perPageSearch,
          ...listActiveChangeSearch,
          ...listActiveStatusSearch,
          ...isDraftParams,
          ...userIdParams
          // ...professionId
        },
        { arrayFormat: "index" }
      );
      */

      const object = {
        dir_id: dirId,
        profession_group_id: professionGroupId,
        sponsorship: freeSponsor,
        // page,
        ...searchName,
        // ...sortSearch,
        // ...perPageSearch,
        ...listActiveChangeSearch,
        ...listActiveStatusSearch,
        ...isDraftParams
        // ...userIdParams
        // ...professionId
      };

      const keys = object ? Object.keys(object).filter(item => object[item]?.length) : {};

      const newQueryObject = keys.reduce((acc, value, index) => {
        acc[`filters[${index}][field]`] = value;
        acc[`filters[${index}][value]`] = object[value];

        return acc;
      }, {});

      const sorting = sortSearch.sort && sortSearch.sort !== "desc" ? { "sorters[0][field]=updated&sorters[0][dir]": sortSearch.sort } : {};
      const pageParams = page ? { page } : {};
      const newQueryString = queryString.stringify(
        { ...newQueryObject, ...sorting, ...pageParams, ...perPageSearch },
        { arrayFormat: "bracket" }
      );

      return axios({
        method: "get",
        responseType: "json",
        url: `/members/api-v2/${listingName}${newQueryString ? `?${newQueryString}` : ""}`
      })
        .then(res => {
          dispatch({
            type: listing.GET_MEMBERSHIPS,
            payload: {
              listings: res.data.data,
              filters: isEvensOrJobsPage
                ? {
                    // remove 2 fieds
                    professiongroups: [
                      {
                        profession_group_id: 3,
                        title: "Individual professionals listing"
                      },
                      {
                        profession_group_id: 1,
                        title: "Organization\u2019s listing"
                      },
                      {
                        profession_group_id: 2,
                        title: "Place of worship listing"
                      }
                    ],
                    directories: [
                      {
                        dir_id: 1000,
                        title: "Afghantherapists.com"
                      },
                      {
                        dir_id: 301,
                        title: "Agentesdehablaespanol.com"
                      },
                      {
                        dir_id: 701,
                        title: "Albanianhousekeepers.com"
                      },
                      {
                        dir_id: 203,
                        title: "Arabicaccountants.com"
                      },
                      {
                        dir_id: 1163,
                        title: "Heritageweb.com"
                      },
                      {
                        dir_id: 38,
                        title: "Christianattorneys.net"
                      },
                      {
                        dir_id: 1161,
                        title: "Iranianlawyers.com"
                      },
                      {
                        dir_id: 11,
                        title: "Iranianlawyers.com"
                      },
                      {
                        dir_id: 1167,
                        title: "Iranianlawyers.com"
                      },
                      {
                        dir_id: 1168,
                        title: "Heritageweb.com"
                      }
                    ],
                    freesponsor: ["free", "sponsor"],
                    change_status: ["Approved", "Pending Review", "Rejected"],
                    publish_status: ["Never Published", "Active", "Paused", "Expired"]
                  }
                : res.data.filters,
              lastPage: res.data.pagination.total_pages || 0,
              currentPage: res.data.pagination.current_page || 0,
              perPage: res.data.pagination.records_per_page || 0,
              isEvensOrJobsPage
            }
          });
        })
        .catch(error => {
          dispatch({
            type: listing.GET_MEMBERSHIPS_FAIL,
            payload: error.response
          });
        });
    };
  },

  getUpgradeOptions(id, coupon) {
    return dispatch => {
      dispatch(actions.setLoading("upgradeOptions", true));

      dispatch({
        type: listing.GET_UPGRADE_OPTIONS_HIDE_ERROR
      });

      const params = {
        id,
        coupon
      };

      axios({
        method: "get",
        responseType: "json",
        url: "/members/json-api/index/upgrade-options",
        params
      })
        .then(res => {
          if (res.data?.length) {
            dispatch({
              type: listing.GET_UPGRADE_OPTIONS,
              payload: res.data
            });
          } else {
            dispatch({
              type: listing.GET_UPGRADE_OPTIONS_SHOW_ERROR
            });
          }
        })
        .catch(error => {
          dispatch({
            type: listing.GET_UPGRADE_OPTIONS_FAIL,
            payload: error.response.data.error
          });
          dispatch({
            type: listing.GET_UPGRADE_OPTIONS_SHOW_ERROR
          });
        });
    };
  },

  getRenewOptions(id, coupon) {
    return dispatch => {
      dispatch(actions.setLoading("upgradeOptions", true));

      const params = {
        id,
        coupon
      };

      axios({
        method: "get",
        responseType: "json",
        url: "/members/json-api/index/renew-options",
        params
      })
        .then(res => {
          dispatch({
            type: listing.GET_UPGRADE_OPTIONS,
            payload: res.data
          });
        })
        .catch(error => {
          dispatch({
            type: listing.GET_UPGRADE_OPTIONS_FAIL,
            payload: error.response.data.error
          });
        });
    };
  },

  getPendingPayment(listing_id) {
    return dispatch => {
      dispatch(actions.setLoading("upgradeOptions", true));
      axios({
        method: "get",
        responseType: "json",
        url: "/members/json-api/listing/amount",
        params: { listing_id }
      })
        .then(res => {
          dispatch({
            type: listing.GET_UPGRADE_OPTIONS,
            payload: [res.data]
          });
        })
        .catch(error => {
          dispatch({
            type: listing.GET_UPGRADE_OPTIONS_FAIL,
            payload: error.response.data.error
          });
        });
    };
  },

  pauseListing(listingId, isHomePage, history, isListingDetail) {
    return (dispatch, getState) => {
      dispatch(actions.setLoading("pause", true));

      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      // const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};

      const pattern = /jobs|events|organizations|profile/;
      const isProfile = history.location.pathname.includes("/profile");
      const listingName = isProfile ? "profiles" : history.location.pathname.match(pattern);

      const url = listingName ? `/members/api-v2/${listingName}` : `/members/json-api${teamUrl}/listing/pause`;

      axios({
        method: listingName ? "put" : "post",
        responseType: "json",
        url,
        // url: `/members/json-api${teamUrl}/listing/pause`,
        data: qs.stringify({ listing_id: listingId, command: "pause" }),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(() => {
          dispatch({
            type: listing.PAUSE_SUCCESS
          });

          if (isHomePage) {
            dispatch(homeActions.getDashboardInfo());
          }

          if (isListingDetail) {
            dispatch(this.getMemberDataNew(listingId, history));
          } else {
            dispatch(this.getExistingMembershipsNew({}, true, 1, [], [], [], "", "", 10, [], [], "", history.location.pathname));
          }
        })
        .catch(error => {
          dispatch({
            type: listing.PAUSE_FAIL,
            payload: error.response.data.error || "Failed to pause membership"
          });
        });
    };
  },

  unPauseListing(listingId, isListingDetail, isHomePage, history) {
    return (dispatch, getState) => {
      dispatch(actions.setLoading("unpause", true));

      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      // const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};

      const pattern = /jobs|events|organizations|profile/;
      const isProfile = history.location.pathname.includes("/profile");
      const listingName = isProfile ? "profiles" : history.location.pathname.match(pattern);

      const url = listingName ? `/members/api-v2/${listingName}` : `/members/json-api${teamUrl}/listing/unpause`;

      axios({
        method: listingName ? "put" : "post",
        responseType: "json",
        url,
        // url: `/members/json-api${teamUrl}/listing/unpause`,
        data: qs.stringify({ listing_id: listingId, command: "unpause" }),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(() => {
          if (isHomePage) {
            dispatch(homeActions.getDashboardInfo());
          }

          dispatch({
            type: listing.UNPAUSE_SUCCESS
          });

          if (!isHomePage) {
            if (isListingDetail) {
              dispatch(this.getMemberDataNew(listingId, history));
            } else {
              dispatch(this.getExistingMembershipsNew({}, true, 1, [], [], [], "", "", 10, [], [], "", history.location.pathname));
            }
          }
        })
        .catch(error => {
          dispatch({
            type: listing.UNPAUSE_FAIL,
            payload: error.response.data.error || "Failed to unpause membership"
          });
        });
    };
  },

  publishListing(listingId, isReload, history) {
    return (dispatch, getState) => {
      const {
        listing: { memberData },
        account: { profile },
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      // const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};
      const pattern = /jobs|events|organizations|profile/;
      const isProfile = history.location.pathname.includes("/profile");
      const listingName = isProfile ? "profiles" : history.location.pathname.match(pattern);

      const url = listingName ? `/members/api-v2/${listingName}` : `/members/json-api${teamUrl}/listing/publish`;

      dispatch(actions.setLoading("memberData", true));

      axios({
        method: listingName ? "put" : "post",
        responseType: "json",
        url,
        // url: `/members/json-api${teamUrl}/listing/publish`,
        // data: { listing_id: listingId, ...userIdParams },
        data: { listing_id: listingId, command: "publish" },
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(() => {
          const maxDateTracking = 60;
          if (
            memberData?.added &&
            differenceInCalendarDays(new Date(), new Date(memberData.added)) <= maxDateTracking &&
            !memberData?.is_premium
          ) {
            track(eventName.startTrial, { value: "12.00", currency: "USD", em: profile.email, fn: profile.name_f, ln: profile.name_f });
          }
          /*
          dispatch({
            type: listing.PUBLISH_LISTING,
            payload: res.data
          });
          */
          if (!isReload) {
            if (isProfile) {
              dispatch(this.getDetailProfilePage(history));
            } else {
              dispatch(this.getMemberDataNew(listingId, history));
            }
          }
        })
        .catch(() => {
          // duplicate tab edge case
          // trigger 404 when listing was previously deleted
          dispatch({
            type: listing.SUBMIT_CHANGES_FAIL,
            payload: "Error submitting for review"
          });
        });
    };
  },

  handleNotificationEmail(email) {
    return dispatch => {
      dispatch(this.setLoadingNotifications(true));

      axios({
        method: "post",
        responseType: "json",
        url: "members/json-api/verify/send-email-code",
        headers: { "Content-Type": "application/json" },
        data: {
          email
        }
      })
        .then(() => {
          dispatch({
            type: listing.NOTIFICATION_EMAIL,
            payload: { email }
          });
        })
        .catch(error => {
          dispatch({
            type: listing.NOTIFICATION_EMAIL_FAIL,
            payload: error?.response?.data?.error || "Failed to update email"
          });
        });
    };
  },

  handleVerifyEmail(code) {
    return (dispatch, getState) => {
      const {
        listing: { memberData }
      } = getState();

      dispatch(this.setLoadingNotifications(true));

      axios({
        method: "post",
        responseType: "json",
        url: "members/json-api/verify/check-listing-email-code",
        headers: { "Content-Type": "application/json" },
        data: {
          code,
          listing_id: memberData.listing_id
        }
      })
        .then(({ data }) => {
          dispatch(
            this.submitPhoneEmail({
              listing_id: memberData.listing_id,
              leademail_primary_only: memberData.leademail_primary_only,
              verifiedemails: [...memberData.verified_emails, data.verifiedemail],
              verifiedphones: memberData.verified_phones
            })
          );
          dispatch({
            type: listing.NOTIFICATION_VERIFICATION_EMAIL_SUCCESS
          });
        })
        .catch(() => {
          dispatch({
            type: listing.NOTIFICATION_VERIFICATION_EMAIL_FAIL
          });
        });
    };
  },

  handleVerifyPhone(code) {
    return (dispatch, getState) => {
      const {
        listing: { memberData }
      } = getState();

      dispatch(this.setLoadingNotifications(true));

      axios({
        method: "post",
        responseType: "json",
        url: "members/json-api/verify/check-listing-phone-code",
        headers: { "Content-Type": "application/json" },
        data: {
          code,
          listing_id: memberData.listing_id
        }
      })
        .then(({ data }) => {
          dispatch(
            this.submitPhoneEmail({
              listing_id: memberData.listing_id,
              verifiedemails: memberData.verified_emails,
              leademail_primary_only: memberData.leademail_primary_only,
              verifiedphones: [...memberData.verified_phones, data.verifiedphone]
            })
          );
          dispatch({
            type: listing.NOTIFICATION_VERIFICATION_PHONE_SUCCESS
          });
        })
        .catch(error => {
          dispatch({
            type: listing.NOTIFICATION_VERIFICATION_PHONE_FAIL,
            payload: error.response.data.error
          });
        });
    };
  },

  handleNotificationPhone(phone) {
    return dispatch => {
      dispatch(this.setLoadingNotifications(true));

      axios({
        method: "post",
        responseType: "json",
        url: "members/json-api/verify/send-phone-code",
        headers: { "Content-Type": "application/json" },
        data: {
          phone
        }
      })
        .then(() => {
          dispatch({
            type: listing.NOTIFICATION_PHONE,
            payload: { phone }
          });
        })
        .catch(error => {
          dispatch({
            type: listing.NOTIFICATION_PHONE_FAIL,
            payload: error?.response?.data?.error || "Failed to update phone"
          });
        });
    };
  },

  getListingAssistant() {
    return dispatch => {
      axios({
        method: "get",
        responseType: "json",
        url: "members/json-api/listing-assistant/get"
      }).then(res => {
        dispatch({
          type: listing.GET_LISTING_ASSISTANT,
          payload: res.data?.records
        });
      });
    };
  },

  hideListingAssistant(listingAssistantId) {
    return dispatch => {
      dispatch(actions.setLoading("listingAssistant", true));

      axios({
        method: "post",
        responseType: "json",
        url: "members/json-api/listing-assistant/hide",
        data: {
          listing_assistant_id: listingAssistantId
        }
      })
        .then(() => {
          dispatch({
            type: listing.HIDE_LISTING_ASSISTANT,
            payload: listingAssistantId
          });
        })
        .catch(error => {
          dispatch({
            type: listing.HIDE_LISTING_ASSISTANT_FAIL,
            payload: error.response.data.error
          });
        });
    };
  },

  getConfig() {
    return dispatch => {
      dispatch(actions.setLoading("listingConfig", true));

      axios({
        method: "get",
        responseType: "json",
        url: "members/json-api/index/config"
      })
        .then(res => {
          const {
            events_profession: eventsProfession,
            jobs_profession: jobsProfession,
            profiles_form: profilesForm,
            profiles_profession: profilesProfession,
            business_profession: businessProfession
          } = res.data.listings;
          dispatch({
            type: listing.GET_LISTINGS_CONFIG,
            payload: {
              eventsProfession,
              jobsProfession,
              profilesForm,
              profilesProfession,
              businessProfession
            }
          });
        })
        .catch(error => {
          dispatch({
            type: listing.GET_LISTINGS_CONFIG_FAIL,
            payload: error.response.data.error
          });
        });
    };
  },

  getProfessions() {
    return dispatch => {
      axios({
        method: "get",
        responseType: "json",
        url: "members/api-v2/professions?command=tree"
      })
        .then(res => {
          dispatch({
            type: listing.GET_PRACTICES,
            payload: res.data.data
          });
        })
        .catch(error => {
          dispatch({
            type: listing.GET_PRACTICES_FAIL,
            payload: error.response.data.error
          });
        });
    };
  },

  setSuccess(action, isActionSuccessful) {
    return {
      type: listing.SET_SUCCESS,
      payload: { action, isActionSuccessful }
    };
  },

  setLoadingNotifications(isLoading) {
    return {
      type: listing.NOTIFICATIONS_LOADING,
      payload: isLoading
    };
  },

  clearErrors() {
    return {
      type: listing.CLEAR_ERRORS
    };
  }
};
