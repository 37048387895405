import React from "react";

import ListingContent from "../listingContent";
import EventsListing from "../eventsListing";
import testCompany from "../../../media/images/eventsTestBanner.jpg";

const list = [
  {
    title: "Mandarin restaurant San Josef _1",
    description:
      "We are looking for a UI Designer who would work close with our team at https://kulga.co/ to continue the website design...",
    position: "Chicago, IL",
    banner: testCompany
  },
  {
    title: "Mandarin restaurant San Josef _2",
    description:
      "We are looking for a UI Designer who would work close with our team at https://kulga.co/ to continue the website design...",
    position: "Chicago, IL",
    banner: testCompany
  },
  {
    title: "Mandarin restaurant San Josef _3",
    description:
      "We are looking for a UI Designer who would work close with our team at https://kulga.co/ to continue the website design...",
    position: "Chicago, IL",
    banner: testCompany
  }
];

const Organizations = ({ isExpand, onClick, isActive, handleClickAll }) => {
  return (
    <>
      {isExpand ? (
        <EventsListing isExpand list={list} />
      ) : (
        <ListingContent title="Organizations" count="12" onClick={onClick} isActive={isActive} handleClickAll={handleClickAll}>
          <EventsListing list={list} />
        </ListingContent>
      )}
    </>
  );
};

export default Organizations;
