import React from "react";

import styles from "./dropdownActions.module.css";
import { ReactComponent as PlusIcon } from "../../../media/icons/plus.svg";

const DropdownActions = ({ buttonText, secondButtonText, buttonHandle, secondButtonHandle }) => {
  return (
    <div className={styles.actions}>
      {buttonText && (
        <button type="button" className={styles.add} onClick={buttonHandle}>
          {buttonText} <PlusIcon className={styles.plusIcon} />
        </button>
      )}

      {secondButtonText && (
        <button type="button" className={styles.add} onClick={secondButtonHandle}>
          {secondButtonText} <PlusIcon className={styles.plusIcon} />
        </button>
      )}
    </div>
  );
};

export default DropdownActions;
