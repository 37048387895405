import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import cn from "classnames";

import Input from "../input";
import Dropdown from "../dropdown";
import RemoveButton from "../removeButton";
import Select from "../select";
import DropdownActions from "../dropdownActions";
import { customStyles as selectStyles } from "../../../constants/select";
import styles from "./eventsTickets.module.css";

const currencyOptions = [
  {
    title: "AUD"
  },
  {
    title: "CAD"
  },
  {
    title: "CHF"
  },
  {
    title: "EUR"
  },
  {
    title: "GBP"
  },
  {
    title: "USD"
  },
  {
    title: "ZAR"
  }
];

const EventsTickets = ({ validation, selectedEvents, submitChanges }) => {
  if (Object.keys(validation).length && !validation.enabled) {
    return null;
  }

  const refButton = useRef();
  const delayDebounceFn = useRef();
  const [mainErrors, setMainErrors] = useState({});
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);

  const [isEdited, setIsEdited] = useState(false);
  const [selectedList, setSelectedList] = useState([]);

  useEffect(() => {
    if (!selectedList.length) {
      setSelectedList(selectedEvents);
    } else {
      const newList = selectedList.map((item, index) => {
        return selectedEvents[index] ? selectedEvents[index] : item;
      });
      setSelectedList(newList);
    }
  }, [selectedEvents.length]);

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }

    if (delayDebounceFn.current) {
      clearTimeout(delayDebounceFn.current);
    }
  };

  const onSubmit = list => {
    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = list
        .map(item => {
          const { isNew, ...rest } = item;
          return { ...rest };
        })
        .filter(item => Object.values(item).some(listItem => listItem));

      submitChanges({ event_tickets: updatedList }, "event_tickets", "save_event_ticket");
    }
  };

  const handleSubmit = (data, isNew, index) => {
    if (isNew) {
      const newList = selectedList.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return { ...data, isNew };
        }

        return { ...item, isNew };
      });

      setSelectedList(newList);
      onSubmit(newList);
    } else {
      const newList = selectedList.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return { ...data };
        }

        return { ...item };
      });

      setSelectedList(newList);
      onSubmit(newList);
    }
  };

  const handleShowEmpty = () => {
    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = selectedList.filter(item => Object.values(item).some(listItem => listItem));

      if (selectedList.length === updatedList.length) {
        setSelectedList([
          ...selectedList,
          {
            currency: "USD",
            name: "",
            price: "",
            prkey: "",
            quantity: "",
            isNew: true
          }
        ]);
      }
    }
  };

  const handleRemoveEmpty = index => {
    const newSelectedList = selectedList.filter((item, currentIndex) => {
      return String(index) !== String(currentIndex);
    });

    setSelectedList(newSelectedList);
    onSubmit(newSelectedList);
  };

  const handleRemove = index => {
    const newSelectedList = selectedList.filter((item, currentIndex) => {
      return String(index) !== String(currentIndex);
    });

    onSubmit(newSelectedList);
    setSelectedList(newSelectedList);
  };

  const title = `Events Tickets (${selectedEvents.length})`;

  return (
    <Dropdown title={title} handleMouseMove={handleMouseMove}>
      <div className={styles.content}>
        <div>
          {selectedList.map((item, index) => {
            return (
              <EventsTicketsItem
                // eslint-disable-next-line react/no-array-index-key
                key={`${item.percentage}-events-tickets-${item.radius}-index-${index}`}
                handleSubmit={handleSubmit}
                handleRemove={handleRemove}
                selected={item}
                index={index}
                handleRemoveEmpty={handleRemoveEmpty}
                refButton={refButton}
                setIsEdited={setIsEdited}
                isEdited={isEdited}
                setMainErrors={setMainErrors}
                mainErrors={mainErrors}
              />
            );
          })}
        </div>
      </div>
      <DropdownActions buttonHandle={handleShowEmpty} buttonText="Add Event Ticket" />
    </Dropdown>
  );
};

const EventsTicketsItem = ({
  selected,
  handleSubmit: onSubmit,
  index,
  handleRemoveEmpty,
  handleRemove,
  refButton,
  setIsEdited,
  isEdited,
  setMainErrors,
  mainErrors
}) => {
  const { t } = useTranslation();
  const { isNew } = selected;
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const containerRef = useRef();

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const validate = ({ currency, name, price }) => {
    const errors = {};
    const requiredText = t("dashboard_common_required");

    if (!name) {
      errors.name = requiredText;
    }

    if (!currency) {
      errors.currency = requiredText;
    }

    if (!price) {
      errors.price = requiredText;
    }

    const mainErrorsValue = mainErrors;
    mainErrorsValue[index] = Object.keys(errors).length ? errors : null;
    setMainErrors(mainErrorsValue);
    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...selected,
      name: selected.name,
      currency: selected.currency,
      quantity: selected.quantity,
      price: selected.price,
      isEdited: false
    },
    onSubmit: values => {
      const { currency, name, quantity, price, prkey } = values;
      return onSubmit({ currency, name, quantity, price, prkey }, isNew, index);
    },
    validate
  });
  const { values, errors, touched, handleSubmit, setFieldValue, setFieldTouched } = formik;

  const handleSelectChange = (newValue, actionMeta) => {
    setFieldValue(actionMeta.name, newValue.title);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleChange = e => {
    setFieldValue(e.target.name, e.target.value);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const formatPracticeOption = ({ title, bold }) => <div>{bold ? <strong>{title}</strong> : title}</div>;

  const onRemove = () => {
    if (selected.isNew) {
      handleRemoveEmpty(index);
      const mainErrorsValue = mainErrors;

      if (mainErrorsValue[index]) {
        mainErrorsValue[index] = null;
        setMainErrors(mainErrorsValue);
      }
    } else {
      handleRemove(index, index, true);
    }
  };

  const handleBlur = fieldName => {
    setFieldTouched(fieldName, true);
  };

  const onHandleSubmit = () => {
    if ((values.currency || values.name || values.quantity || values.price) && values.isEdited) {
      handleSubmit();
    }
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);

      if (
        (values.currency || values.name || values.quantity || values.price) &&
        values.isEdited &&
        !Object.values(errors).some(item => item)
      ) {
        handleSubmit();
      }
      setIsEdited(false);
    }
  };

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const isHideInfo = index > 0;

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (isTouched) {
          setIsTouched(false);

          if (
            (values.currency || values.name || values.quantity || values.price) &&
            values.isEdited &&
            !Object.values(errors).some(item => item)
          ) {
            handleSubmit();
            setIsEdited(false);
          }
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [isEdited]);

  return (
    <div
      ref={containerRef}
      className={styles.item}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      onTouchStart={handleTouchStart}
    >
      <div className={styles.inputNameWrapper}>
        <Input
          classNameWrap={styles.inputName}
          className={styles.ticketName}
          error={errors.name}
          id="name"
          isInvalid={errors.name && touched.name}
          name="name"
          onBlur={() => handleBlur("name")}
          onChange={handleChange}
          value={values.name}
          errorClassName={styles.errorText}
          classNameInvalid={styles.inputInvalid}
          placeholder="Enter ticket name"
        />

        <RemoveButton className={styles.removeButton} onClick={onRemove} />
      </div>

      <div className={styles.flex}>
        <div className={styles.flexItem}>
          {!index && <span className={cn(styles.label, isHideInfo ? styles.labelHideDesktop : "")}>Currency</span>}

          <Select
            error={errors.currency}
            isInvalid={errors.currency && touched.currency}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.title}
            formatOptionLabel={formatPracticeOption}
            inputId="practice_id"
            name="currency"
            options={currencyOptions}
            onBlur={() => handleBlur("currency")}
            handleChange={handleSelectChange}
            styles={selectStyles}
            value={values.currency ? currencyOptions.find(item => item.title === values.currency) : []}
            placeholder="USD"
            classNameWrap={styles.selectCurrency}
          />
        </div>

        <div className={styles.flexItem}>
          {!index && <span className={cn(styles.label, isHideInfo ? styles.labelHideDesktop : "")}>Price</span>}

          <Input
            className={styles.flexInput}
            error={errors.price}
            id="price"
            isInvalid={errors.price && touched.price}
            name="price"
            onBlur={() => handleBlur("price")}
            onChange={handleChange}
            value={values.price}
            errorClassName={styles.errorText}
            classNameInvalid={styles.inputInvalid}
            placeholder="0"
          />
        </div>
        {/* hide due this page https://heritageweb.atlassian.net/browse/GROWTH-3888
        <div className={styles.flexItem}>
          {!index && <span className={cn(styles.label, isHideInfo ? styles.labelHideDesktop : "")}>Quantity</span>}

          <Input
            className={styles.flexInput}
            error={errors.quantity}
            id="quantity"
            isInvalid={errors.quantity && touched.quantity}
            name="quantity"
            onBlur={() => handleBlur("quantity")}
            onChange={handleChange}
            value={values.quantity}
            errorClassName={styles.errorText}
            classNameInvalid={styles.inputInvalid}
            placeholder="0"
          />
        </div>
        */}

        <button ref={refButton} type="button" className={styles.hidden} onClick={onHandleSubmit} />
      </div>
    </div>
  );
};

export default EventsTickets;
