import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import { ReactComponent as PencilIcon } from "../../../media/icons/edit-pencil-small.svg";
import { RedStar } from "../redStar";
import Label from "../label";
import RenderPhoto from "../renderPhoto";
import styles from "./listingInfo.module.css";
import { URL as URLRegex } from "../../../constants/regex";
import Input from "../../common/input";
// import { ReactComponent as CameraIcon } from "../../../media/icons/photo-listing.svg";
// import { createImageDeliveryUrl } from "../../../helpers";
import CoverImage from "../coverImage";
import DetailImages from "../detailImages";
import { OverlayPreloaderAbsolute } from "../../common/preloader";

const ListingInfoEvents = ({
  defaultImage,
  // onClick,
  showRequiredText,
  validationPhoto,
  validationName,
  validationBusiness,
  validationWebsite,
  attorney,
  title,
  website,
  submitChanges,
  isActiveModalPhoto,
  socialPhoto,
  validationCoverImage,
  validationAdditionalImages,
  id,
  // uploadSocialImage,
  socialImagePreloaders,
  handleSubmitImage,
  images,
  deleteImage
  // uploadImage,
}) => {
  const input = useRef();

  const additionalImages = images.filter(item => !item.is_default);

  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const isRequiredPhoto = validationPhoto.required && !defaultImage?.src;
  const requiredError = isRequiredPhoto && showRequiredText ? styles.requiredError : "";
  const photoContainerRequired = isRequiredPhoto && showRequiredText ? styles.photoContainerRequired : "";
  const { t } = useTranslation();
  const contentActive = isMouseInBlock && !isActiveModalPhoto ? styles.contentActive : "";
  const containerRef = useRef();

  const isRequiredCoverImage = Object.keys(validationCoverImage).length ? validationCoverImage.required : true;
  const isRequiredCoverImageText = validationCoverImage.required && !socialPhoto?.length && showRequiredText;
  const isRequiredBusiness = Object.keys(validationBusiness).length ? validationBusiness.required : true;
  const isRequiredWebsite = Object.keys(validationWebsite).length ? validationWebsite.required : true;
  const isRequiredName = Object.keys(validationName).length ? validationName.required : true;

  const handleFileInput = e => {
    e.persist();
    const { files } = e.target;

    handleUploadImage(files[0]);
  };

  const handleUploadImage = files => {
    if (files) {
      const formData = new FormData();
      formData.append("image_body", files);
      formData.append("listing_id", id);
      formData.append("is_default", "1");
      formData.append("command", "upload");

      handleSubmitImage(formData);
    }
  };

  const validate = values => {
    const errors = {};
    const { title: titleField, website: websiteField, attorney: attorneyField } = values;
    const requiredText = "This field is mandatory";
    const invalidUrlText = "Please use the full URL";

    if (!attorneyField && isRequiredName) {
      errors.attorney = requiredText;
    }

    if (!websiteField && isRequiredWebsite) {
      errors.website = requiredText;
    }

    if (!titleField && isRequiredBusiness) {
      errors.title = requiredText;
    }

    if (websiteField && !websiteField.match(URLRegex)) {
      errors.website = invalidUrlText;
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: { attorney, title, website, isEdited: false },
    validate,
    onSubmit: values => {
      submitChanges({ attorney: values.attorney, title: values.title, website: values.website }, "test");
    }
  });

  const handleSubmitAttorney = () => {
    submitChanges({ attorney: values.attorney }, "attorney", "save_fullname");
  };

  const handleMouseLeaveAttorney = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);

      if (values.attorney && values.isEdited && !errors.attorney) {
        formik.setFieldValue("isEdited", false);
        handleSubmitAttorney();
      }
    }
  };

  const handleSubmitTitle = () => {
    submitChanges({ title: values.title }, "title", "save_headline");
  };

  const handleMouseLeaveTitle = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);

      if (values.title && values.isEdited && !errors.title) {
        formik.setFieldValue("isEdited", false);
        handleSubmitTitle();
      }
    }
  };

  const handleSubmitWebsite = () => {
    submitChanges({ website: values.website }, "website", "save_website");
  };

  const handleMouseLeaveWebsite = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);

      if (values.website && values.isEdited && !errors.website) {
        formik.setFieldValue("isEdited", false);
        handleSubmitWebsite();
      }
    }
  };

  // touched,
  const { errors, values, handleSubmit } = formik;

  const handleChange = e => {
    formik.setFieldValue(e.target.name, e.target.value);

    if (!values.isEdited) {
      formik.setFieldValue("isEdited", true);
    }
  };

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  /*
  const handleTouchEnd = () => {
    if (isTouched) {
      setIsTouched(false);

      if (values.isEdited) {
        handleSubmit();
        formik.setFieldValue("isEdited", false);
      }
    }
  };
  */

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);

      if (values.isEdited) {
        formik.setFieldValue("isEdited", false);
      }
    }
  };

  useEffect(() => {
    if (
      (!values.attorney && isRequiredName && showRequiredText) ||
      (!values.website && isRequiredWebsite && showRequiredText) ||
      (!values.title && isRequiredBusiness && showRequiredText)
    ) {
      handleSubmit();
    }
  }, [showRequiredText]);

  const renderFullNameLabel = () => {
    if (validationName.custom_name) {
      return validationName.custom_name;
    }

    return "Full Name";
  };

  const renderFullNamePlaceholder = () => {
    return "Enter your full name";
  };

  const renderWebsiteUrlLabel = () => {
    if (validationWebsite.custom_name) {
      return validationWebsite.custom_name;
    }

    return t("website");
  };

  const handleOpenFile = () => {
    input.current.click();
  };

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target) && !errors.website) {
        if (isTouched) {
          setIsTouched(false);

          if (values.isEdited) {
            handleSubmitWebsite();
            handleSubmitTitle();
            handleSubmitAttorney();

            formik.setFieldValue("isEdited", false);
          }
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [values.isEdited, values.website]);

  return (
    <>
      <div className={cn(styles.content, styles.contentMain)}>
        {socialImagePreloaders && <OverlayPreloaderAbsolute />}
        <CoverImage onUpload={handleSubmitImage} id={id} image={socialPhoto} />
        <div className={styles.leftSection}>
          <RenderPhoto
            className={cn(styles.photoRequired, photoContainerRequired)}
            defaultImage={defaultImage}
            classNameIcon={styles.iconPhoto}
          >
            <button type="button" className={styles.edit} onClick={handleOpenFile}>
              <PencilIcon />
            </button>
          </RenderPhoto>
          <input
            ref={input}
            accept=".png, .jpg, .jpeg"
            onChange={e => handleFileInput(e)}
            id="upload"
            name="upload"
            className={styles.fileInput}
            type="file"
          />
          {isRequiredPhoto && (
            <p className={cn(styles.required, requiredError)}>
              {validationPhoto.custom_name || "Profile photo required"} <RedStar isActive />
            </p>
          )}
          {isRequiredCoverImage && (
            <p className={cn(styles.required, isRequiredCoverImageText ? styles.requiredError : "")}>
              {validationCoverImage.custom_name || "Cover Image required"} <RedStar isActive />
            </p>
          )}
        </div>
        <DetailImages
          onUpload={handleSubmitImage}
          id={id}
          deleteImage={deleteImage}
          images={additionalImages.reverse()}
          validation={validationAdditionalImages}
        />
      </div>
      <div
        ref={containerRef}
        className={cn(styles.content, contentActive)}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        // onTouchEnd={handleTouchEnd}
        onTouchStart={handleTouchStart}
      >
        <div className={styles.rightSection}>
          <div className={styles.inputContent} onMouseLeave={handleMouseLeaveAttorney}>
            <Label htmlFor="fullName">{renderFullNameLabel()}</Label>
            <Input
              type="text"
              placeholder={renderFullNamePlaceholder()}
              className={styles.input}
              error={errors.attorney}
              id="attorney"
              isInvalid={errors.attorney}
              name="attorney"
              onChange={handleChange}
              required={isRequiredName}
              value={values.attorney}
              classNameError={styles.errorText}
              classNameInvalid={styles.inputInvalid}
            />
          </div>
          {Object.keys(validationBusiness).length && validationBusiness.enabled ? (
            <div className={styles.inputContent} onMouseLeave={handleMouseLeaveTitle}>
              <Label htmlFor="businessName">{validationBusiness.custom_name || "Business Name"}</Label>
              <Input
                type="text"
                placeholder="Enter your business name"
                className={styles.input}
                error={errors.title}
                id="title"
                isInvalid={errors.title}
                name="title"
                onChange={handleChange}
                required={isRequiredBusiness}
                value={values.title}
                classNameError={styles.errorText}
                classNameInvalid={styles.inputInvalid}
              />
            </div>
          ) : null}

          <div onMouseLeave={handleMouseLeaveWebsite}>
            <Label htmlFor="website">{renderWebsiteUrlLabel()}</Label>
            <Input
              type="text"
              placeholder="ex. https://www.andybernanrd.com"
              className={styles.input}
              error={errors.website}
              id="website"
              isInvalid={errors.website}
              name="website"
              onChange={handleChange}
              value={values.website}
              required={isRequiredWebsite}
              classNameError={styles.errorText}
              classNameInvalid={styles.inputInvalid}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ListingInfoEvents;
