import React, { useEffect, useState, useCallback, useRef } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../common/button";
import { Modal } from "../../../../common/modal";
import modalStyles from "../../../../common/modal/modal.module.css";
import { OverlayPreloader } from "../../../../common/preloader";
import { modals as modalsConstant, statuses } from "../../../../../constants/listings";
import { useKeyDownEnter } from "../../../../../helpers/hooks";
import styles from "./upgrade.module.css";
import ModalControl from "../../../../modalControl";
import { Radio } from "../../../../common/radio";

const plans = {
  month: "month",
  year: "year"
};

export const Upgrade = ({
  cleanUpgradeOptions,
  getUpgradeOptions,
  itemToEdit,
  preloaders,
  activeUpgradeOptions,
  updateActiveUpgradeOptions,
  setItemToEdit,
  upgradeOptions,
  clearAllModals,
  openModal,
  setSavedPaymentData,
  clearSavedPaymentData,
  savedPaymentData,
  showUpgradeOptionsError
}) => {
  const { t } = useTranslation();
  const [planYear, setPlanYear] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(plans.month);
  const [errorCoupon, setErrorCoupon] = useState(false);
  const [successCoupon, setSuccessCoupon] = useState(false);
  const [coupon, setCoupon] = useState("");
  const sorted = upgradeOptions.sort(item => (item.terms.includes("month") ? -1 : 1));
  const [firstOption, secondOption] = sorted;
  const isSelectedYear = activeUpgradeOptions?.terms?.includes("year");
  const { first_total: firstTotal, second_total: secondTotal } = activeUpgradeOptions;
  // const isActiveCoupon = Boolean(activeUpgradeOptions?.coupon?.code);
  // const planTermsActive = isActiveCoupon ? styles.planTermsActive : "";
  const isFree = firstTotal === 0 && secondTotal === 0;
  const couponElement = useRef();
  const isPendingPayment = statuses.pendingPayment === itemToEdit.status;
  const isExpired = statuses.expired === itemToEdit.status;

  const handleClose = () => {
    clearAllModals();
    setItemToEdit(null);
    cleanUpgradeOptions();
    clearSavedPaymentData();
  };

  const onKeyDownEnter = () => {
    if (document.activeElement === couponElement.current) {
      handleCoupon();
    } else {
      clearAllModals();
      setSavedPaymentData();
      if (isFree) {
        openModal(modalsConstant.confirm);
      } else {
        openModal(modalsConstant.payment);
      }
    }
  };

  const handleNext = () => {
    clearAllModals();
    setSavedPaymentData();
    if (isFree) {
      openModal(modalsConstant.confirm);
    } else {
      openModal(modalsConstant.payment);
    }
  };

  const handleCoupon = useCallback(() => {
    clearSavedPaymentData();
    getUpgradeOptions(itemToEdit.listing_id, coupon, isExpired);
  }, [clearSavedPaymentData, getUpgradeOptions, itemToEdit.listing_id, coupon]);

  const handleSelectMonth = useCallback(() => {
    setPlanYear(false);
    setCurrentPlan(plans.month);
    updateActiveUpgradeOptions(firstOption);
  }, [firstOption, updateActiveUpgradeOptions]);

  const handleSelectYear = useCallback(() => {
    setPlanYear(true);
    setCurrentPlan(plans.year);
    updateActiveUpgradeOptions(secondOption);
  }, [secondOption, updateActiveUpgradeOptions]);

  useEffect(() => {
    if (!savedPaymentData.isActive) {
      getUpgradeOptions(itemToEdit?.listing_id);
    }
  }, [getUpgradeOptions, itemToEdit, savedPaymentData.isActive]);

  useEffect(() => {
    if (!savedPaymentData.isActive) {
      setErrorCoupon(upgradeOptions?.[0]?.coupon?.error);
    }
    setSuccessCoupon(upgradeOptions?.[0]?.coupon?.code);
  }, [savedPaymentData.isActive, upgradeOptions]);

  useKeyDownEnter(onKeyDownEnter);

  useEffect(() => {
    if (savedPaymentData.isActive && !planYear && isSelectedYear) {
      handleSelectYear();
    }
    if (savedPaymentData.isActive && activeUpgradeOptions.coupon.code) {
      setCoupon(activeUpgradeOptions.coupon.code);
    }
  }, [activeUpgradeOptions?.coupon?.code, handleSelectYear, isSelectedYear, planYear, savedPaymentData.isActive]); // eslint-disable-line

  useEffect(() => {
    if (document && document.activeElement) {
      document.activeElement.blur();
    }
  }, []);

  const renderTitle = () => {
    if (isExpired) {
      return "RENEW SUBSCRIPTION";
    }

    if (isPendingPayment) {
      return t("pendingpayment");
    }

    return t("dashboard_listings_modal_upgrade_top_title");
  };

  const renderSubTitle = () => {
    if (isExpired) {
      return "Renew Subscription";
    }

    if (isPendingPayment) {
      return t("pendingpayment");
    }

    return t("dashboard_listings_modal_upgrade_plan_h1");
  };

  const confirmClassName = showUpgradeOptionsError ? styles.confirmClassName : "";

  return (
    <Modal closeModal={handleClose} clearAllModals={clearAllModals}>
      {preloaders.upgradeOptions && <OverlayPreloader overlayClassName="sectionOverlayModal" />}
      <form
        className={modalStyles.form}
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <section>
          <div className={modalStyles.pointer}>{renderTitle()}</div>
          <div className={styles.popupHeading}>{renderSubTitle()}</div>
          <div className={styles.paymentContent}>
            {showUpgradeOptionsError && (
              <span className={styles.error}>
                Upgrading this listing is not an option on the dashboard at this time. Please contact support to upgrade.
              </span>
            )}
            {(firstOption || secondOption) && (
              <>
                {/*
                todo: remove
                <div className={styles.label}>
                  <p className="labelText">
                    {t("dashboard_listings_modal_upgrade_plan_h2")} {secondOption && <span className="text-red">*</span>}
                  </p>
                </div>
                */}

                <div className={styles.currentPlan}>
                  <div
                    tabIndex={-1}
                    role="button"
                    className={cn(styles.currentPlanItem, currentPlan === plans.year ? styles.currentPlanItemActive : "")}
                    onClick={handleSelectYear}
                  >
                    <div>
                      <h6 className={styles.currentPlanName}>BILLED ANNUALLY</h6>
                      <p className={styles.currentPlanPrice}>
                        {activeUpgradeOptions?.coupon?.code && secondOption?.terms_without_coupon && (
                          <span className={styles.currentPlanOldPrice}>{secondOption?.terms_without_coupon}</span>
                        )}
                        <span className={styles.currentPlanNewPrice}>{secondOption?.terms}</span>
                      </p>
                      <span className={styles.currentPlanPeriod}>/year</span>
                      {/*
                      <span className={styles.currentPlanTotal}>$180 total</span>
                      */}
                    </div>
                    <div>
                      <div className={styles.currentPlanDiscount}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                          <path
                            d="M11.5 14.3H10.1299C8.72143 14.3 8.01721 14.3 7.72228 13.8385C7.42735 13.3769 7.72321 12.7379 8.31493 11.4597L11.0463 5.56006C11.4205 4.75182 11.6075 4.3477 11.8038 4.39091C12 4.43413 12 4.87946 12 5.77013V10.2C12 10.4357 12 10.5536 12.0732 10.6268C12.1464 10.7 12.2643 10.7 12.5 10.7H13.8701C15.2786 10.7 15.9828 10.7 16.2777 11.1615C16.5726 11.6231 16.2768 12.2621 15.6851 13.5402L12.9537 19.4399C12.5795 20.2482 12.3925 20.6523 12.1962 20.6091C12 20.5659 12 20.1205 12 19.2299V14.8C12 14.5643 12 14.4464 11.9268 14.3732C11.8536 14.3 11.7357 14.3 11.5 14.3Z"
                            fill="#5BAFD7"
                          />
                        </svg>
                        <span className={styles.currentPlanSave}>Save 20%</span>
                      </div>
                      <div className={styles.currentPlanRadioContent}>
                        <Radio
                          isNewStyles
                          big
                          mainClass={styles.currentPlanRadio}
                          iconClass={styles.currentPlanRadioIcon}
                          checked={currentPlan === plans.year}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    tabIndex={-1}
                    role="button"
                    className={cn(styles.currentPlanItem, currentPlan === plans.month ? styles.currentPlanItemActive : "")}
                    onClick={handleSelectMonth}
                  >
                    <div>
                      <h6 className={styles.currentPlanName}>Billed Monthly</h6>
                      <p className={styles.currentPlanPrice}>
                        {activeUpgradeOptions?.coupon?.code && firstOption?.terms_without_coupon && (
                          <span className={styles.currentPlanOldPrice}>{firstOption?.terms_without_coupon}</span>
                        )}
                        <span className={styles.currentPlanNewPrice}>{firstOption?.terms}</span>
                      </p>
                      <span className={styles.currentPlanPeriod}>/month</span>
                    </div>
                    <div>
                      <div className={styles.currentPlanRadioContent}>
                        <Radio
                          isNewStyles
                          big
                          mainClass={styles.currentPlanRadio}
                          iconClass={styles.currentPlanRadioIcon}
                          checked={currentPlan === plans.month}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* todo: remove */}
                {/* hid during this ticket https://heritageweb.atlassian.net/browse/GROWTH-3364
                <div className={styles.plan}>
                  <div>
                    {activeUpgradeOptions?.coupon?.code && firstOption?.terms_without_coupon && (
                      <span className={styles.planTermOld} onClick={handleSelectMonth} role="button" tabIndex="0">
                        {firstOption?.terms_without_coupon}
                      </span>
                    )}
                    <span className={cn(styles.planTerm, planTermsActive)} onClick={handleSelectMonth} role="button" tabIndex="0">
                      {firstOption?.terms}
                    </span>
                  </div>
                  {secondOption && (
                    <Switch
                      activeAll
                      selected={planYear}
                      className={styles.switch}
                      value=""
                      disabled={!secondOption}
                      handleSwitch={() => {
                        if (planYear) {
                          handleSelectMonth();
                        } else {
                          handleSelectYear();
                        }
                      }}
                    />
                  )}
                  <div>
                    {activeUpgradeOptions?.coupon?.code && secondOption?.terms_without_coupon && (
                      <span className={styles.planTermOld} onClick={handleSelectMonth} role="button" tabIndex="0">
                        {secondOption?.terms_without_coupon}
                      </span>
                    )}
                    <span className={cn(styles.planTerm, planTermsActive)} onClick={handleSelectYear} role="button" tabIndex="0">
                      {secondOption?.terms}
                    </span>
                  </div>
                </div>
                */}
              </>
            )}
          </div>

          {!showUpgradeOptionsError && (
            <>
              <p className={styles.couponText}>Coupon</p>

              <div className={styles.coupon}>
                <input
                  type="text"
                  ref={couponElement}
                  className={styles.couponInput}
                  value={coupon}
                  onChange={e => setCoupon(e.target.value)}
                />

                <Button
                  className={cn(styles.couponBtn, preloaders.upgradeOptions ? "button--outline" : null)}
                  disabled={!coupon}
                  onClick={handleCoupon}
                >
                  {t("dashboard_common_button_apply")}
                </Button>
              </div>
              {errorCoupon && <p className={styles.couponError}>{t("dashboard_common_payments_couponerror")}</p>}
              {successCoupon && (
                <p className={styles.successCoupon}>
                  Coupon <span>’{upgradeOptions?.[0]?.coupon?.code}’</span> has been applied
                </p>
              )}
            </>
          )}
        </section>
        <ModalControl onExit={handleClose} onConfirm={handleNext} className={styles.modalControl} confirmClassName={confirmClassName}>
          {t("dashboard_common_button_next")}
        </ModalControl>
      </form>
    </Modal>
  );
};
