import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { OverlayPreloader } from "../../common/preloader";
import { Notification } from "../../common/notification";
import Checkbox from "../../common/checkbox";
import { Tooltip } from "../../common/tooltip";
import styles from "./accountNotifications.module.css";

const AccountNotifications = ({ preloaders, success, notifications, saveNotificationList }) => {
  const { t } = useTranslation();

  const [localNotifications, setLocalNotifications] = useState([]);

  const handleNotificationChange = event => {
    const updatedNotifications = localNotifications.map(item => {
      if (item.notification === event.target.name) {
        return {
          ...item,
          email: String(item.email) === "1" ? "0" : "1"
        };
      }

      return item;
    });

    setLocalNotifications(updatedNotifications);
    saveNotificationList(updatedNotifications);
  };

  const getCheckedStatus = name => {
    const index = localNotifications.findIndex(item => item.notification === name);
    return localNotifications.length ? Number(localNotifications[index].email) : false;
  };

  useEffect(() => {
    if (!localNotifications.length) {
      setLocalNotifications(notifications);
    }
  }, [notifications]);

  return (
    <form action="" className={styles.notificationForm}>
      {preloaders.notifications && <OverlayPreloader />}
      {success.notifications && <Notification success>{t("dashboard_common_saved")}</Notification>}

      <h2 className={styles.mainTitle}>Account Notifications</h2>

      <h6 className={styles.acctSubtitle}>{t("dashboard_notifications_profilechangestitle")}</h6>
      <p className={styles.description}>{t("dashboard_notifications_profilechangesmsg")}</p>

      <div className={styles.checkboxWrap}>
        <div className="d-flex align-items-baseline">
          <Checkbox isNewStyles big name="account_changes" className={styles.checkboxLabel} unactive checked onChange={() => {}}>
            E-Mail
          </Checkbox>
          <Tooltip className={styles.tooltip}>{t("dashboard_account_notification_profilechange_emailtooltip")}</Tooltip>
        </div>

        <Checkbox isNewStyles big name="account_changes_text" className={styles.checkboxLabel} disabled>
          Text (SMS)
        </Checkbox>
      </div>

      <h6 className={styles.acctSubtitle}>Inbox Notifications</h6>
      <div className={styles.checkboxWrap}>
        <div className="d-flex align-items-baseline">
          <Checkbox isNewStylesclassName={styles.checkboxLabel} id="inbox" big name="inbox" unactive checked onChange={() => {}}>
            New message email notification
          </Checkbox>
          <Tooltip className={styles.tooltip}>{t("dashboard_account_notification_inbox_email")}</Tooltip>
        </div>
        <Checkbox isNewStyles big className={styles.checkboxLabel} disabled id="inbox_sms" name="inbox_sms">
          Text (SMS)
        </Checkbox>
      </div>

      <h6 className={styles.acctSubtitle}>Referral Request Notifications</h6>
      <p className={styles.description}>Updated regarding the status of your referral requests</p>

      <div className={styles.checkboxWrap}>
        <div className="d-flex align-items-baseline">
          <Checkbox
            isNewStyles
            big
            id="referral_requests"
            name="referral_requests"
            className={styles.checkboxLabel}
            checked={getCheckedStatus("referral_requests")}
            onChange={handleNotificationChange}
          >
            E-Mail
          </Checkbox>
        </div>

        <Checkbox isNewStyles big name="account_changes_text" className={styles.checkboxLabel} disabled>
          Text (SMS)
        </Checkbox>
      </div>

      <h6 className={styles.acctSubtitle}>Q&A Notifications</h6>
      <p className={styles.description}>Notifications about answers and replies postede on your questions</p>

      <div className={styles.checkboxWrap}>
        <div className="d-flex align-items-baseline">
          <Checkbox
            isNewStyles
            big
            name="legal_q_and_a"
            id="legal_q_and_a"
            className={styles.checkboxLabel}
            checked={getCheckedStatus("legal_q_and_a")}
            onChange={handleNotificationChange}
          >
            E-Mail
          </Checkbox>
        </div>

        <Checkbox isNewStyles big name="account_changes_text" className={styles.checkboxLabel} disabled>
          Text (SMS)
        </Checkbox>
      </div>

      <h2 className={styles.mainTitle}>Listing Notifications</h2>

      <h6 className={styles.acctSubtitle}>{t("dashboard_notifications_paymenttitle")}</h6>

      <div className={styles.checkboxWrap}>
        <Checkbox
          name="payment_reminders"
          id="payment_reminders"
          className={styles.checkboxLabel}
          checked={getCheckedStatus("payment_reminders")}
          onChange={handleNotificationChange}
          big
          isNewStyles
        >
          {t("dashboard_notifications_paymentadvancenotice")}
        </Checkbox>
        <Checkbox
          name="payments"
          id="payments"
          className={styles.checkboxLabel}
          checked
          // onChange={handleNotificationChange}
          big
          isNewStyles
          unactive
        >
          {t("dashboard_notifications_afterpayment")}
        </Checkbox>
      </div>

      <h6 className={styles.acctSubtitle}>Listing Change Notifications</h6>
      <p className={styles.accText}>Notifications related to changes made to your any listing in your account</p>
      <div className={styles.checkboxWrap}>
        <Checkbox
          className={styles.checkboxLabel}
          name="listing"
          id="listing"
          checked
          // onChange={handleNotificationChange}
          big
          isNewStyles
        >
          Email
        </Checkbox>
        <Checkbox isNewStyles className={styles.checkboxLabel} disabled name="listing_sms">
          Text (SMS)
        </Checkbox>
      </div>

      {/*
      <div className={styles.checkboxWrap}>
        <Checkbox
          isNewStyles
          big
          className={styles.checkboxLabel}
          name="inbox_reminders"
          id="inbox_reminders"
          checked={getCheckedStatus("inbox_reminders")}
          onChange={handleNotificationChange}
        >
          Follow-up email reminder 24 hours after initial message
        </Checkbox>
        <Checkbox isNewStyles big className={styles.checkboxLabel} disabled name="inbox_reminders_sms" id="inbox_reminders_sms">
          Text (SMS)
        </Checkbox>
          </div>
        */}
    </form>
  );
};

export default AccountNotifications;
