import { useSelector } from "react-redux";
import React from "react";

import Checkbox from "../../../common/checkbox";
import { FILTERS } from "../../../../constants/questionsAnswers";
import styles from "./professions.module.css";

const areEqual = (prevProps, nextProps) => {
  return nextProps.activeFilters.professions.length === prevProps.activeFilters.professions.length;
};

export const Professions = React.memo(({ activeFilters, handleFilter }) => {
  const questions = useSelector(state => state.questions);
  const { professions } = questions;

  if (!professions?.length) {
    return null;
  }

  return (
    <ul className={styles.ulList}>
      <li className={styles.ulHead}>Professions</li>
      <li>
        <ul className={styles.ulList}>
          {professions.map(({ profession_id: id, title }) => (
            <li key={id}>
              <Checkbox
                checked={activeFilters.professions.includes(id)}
                className={styles.checkbox}
                id={title}
                name={title}
                onChange={() => handleFilter(FILTERS.PROFESSIONS, id)}
              >
                {title}
              </Checkbox>
            </li>
          ))}
        </ul>
      </li>
    </ul>
  );
}, areEqual);
