import { messages } from "../constants/actionTypes";

const defaultState = {
  error: "",
  preloaders: {
    messages: false,
    readMessage: false,
    messageDetail: false,
    sendMessage: false
  },
  messages: [],
  messageDetail: {},
  messageDetailMessages: [],
  activeListings: [],
  filter: {
    activeListings: [],
    isUnread: false
  },
  isActiveMenu: false,
  isActiveFilterModal: false,
  success: {
    unread: false,
    archive: false,
    unArchive: false
  },
  activityBlocks: {
    conversation: false,
    detail: false,
    detailDesktop: true,
    messages: true
  }
};

export default function(state = defaultState, action) {
  const { type, payload } = action;
  let updatedMessagesList;
  switch (type) {
    case messages.SET_ACTIVE_BLOCKS:
      return {
        ...state,
        activityBlocks: { ...state.activityBlocks, ...payload }
      };

    case messages.DISABLE_SUCCESS:
      return {
        ...state,
        success: defaultState.success
      };

    case messages.TOGGLE_LOADING:
      return {
        ...state,
        preloaders: { ...state.preloaders, [payload.section]: payload.isLoading },
        error: ""
      };

    case messages.GET_MESSAGE_DETAIL_LOADING:
      return {
        ...state,
        preloaders: { ...state.preloaders, messageDetail: true },
        messageDetail: {},
        messageDetailMessages: []
      };

    case messages.GET_MESSAGES_FAIL:
      return {
        ...state,
        preloaders: { ...state.preloaders, messages: false },
        error: payload,
        isActiveMenu: false
      };

    case messages.GET_MESSAGES:
      return {
        ...state,
        messages: payload.messages,
        activeListings: payload.activeListings,
        preloaders: { ...state.preloaders, messages: false },
        isActiveMenu: false,
        messageDetail: {},
        messageDetailMessages: []
      };

    case messages.GET_MESSAGE_DETAIL:
      return {
        ...state,
        preloaders: { ...state.preloaders, messageDetail: false },
        messageDetail: payload.messageDetail,
        messageDetailMessages: payload.messages
      };

    case messages.READ_MESSAGE:
      updatedMessagesList = state.messages.map(item => {
        if (String(item.email_conversation_id) === String(payload)) {
          return { ...item, is_read: "1" };
        }
        return item;
      });

      return {
        ...state,
        messages: updatedMessagesList
      };

    case messages.UNREAD_MESSAGE:
      updatedMessagesList = state.messages.map(item => {
        if (String(item.email_conversation_id) === String(payload)) {
          return { ...item, is_read: "0" };
        }
        return item;
      });

      return {
        ...state,
        messages: updatedMessagesList,
        success: {
          ...state.success,
          unread: true
        },
        preloaders: {
          ...state.preloaders,
          unreadMessage: false
        }
      };

    case messages.ARCHIVE_MESSAGE:
      updatedMessagesList = state.messages.filter(item => String(item.email_conversation_id) !== String(payload));

      return {
        ...state,
        messages: updatedMessagesList,
        success: {
          ...state.success,
          archive: true
        }
      };

    case messages.UN_ARCHIVE_MESSAGE:
      updatedMessagesList = state.messages.filter(item => String(item.email_conversation_id) !== String(payload));

      return {
        ...state,
        messages: updatedMessagesList,
        success: {
          ...state.success,
          unArchive: true
        }
      };

    case messages.SEND_MESSAGE:
      return {
        ...state,
        preloaders: { ...state.preloaders, sendMessage: false },
        messageDetailMessages: [...state.messageDetailMessages, payload.message]
      };

    case messages.CLEAR_MESSAGE_DETAIL:
      return {
        ...state,
        messageDetailMessages: [],
        messageDetail: {}
      };

    case messages.SEND_MESSAGE_FAIL:
      return {
        ...state,
        preloaders: { ...state.preloaders, sendMessage: false }
      };

    case messages.SET_ACTIVITY_MENU:
      return {
        ...state,
        isActiveMenu: payload
      };

    case messages.SET_ACTIVITY_FILTER:
      return {
        ...state,
        isActiveFilterModal: payload
      };

    case messages.SET_FILTER:
      return {
        ...state,
        filter: payload
      };

    default:
      return {
        ...state
      };
  }
}
