import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import { Modal } from "../../common/modal";
import styles from "../listingDetail.module.css";
import modalStyles from "../../common/modal/modal.module.css";
import { OverlayPreloader } from "../../common/preloader";
import Input from "../../common/input";
import { phone as phoneRegex } from "../../../constants/regex";
import ModalControl from "../../modalControl";

const validate = ({ phone }) => {
  const invalidPhoneText = "Please enter valid number";

  const errors = {};
  if (!phone.match(phoneRegex)) {
    errors.phone = invalidPhoneText;
  }
  if (!phone) {
    errors.phone = "Required";
  }
  return errors;
};

export const Phone = ({ closeModal, isSuccessful, handleBlur, loading, memberInputData, submitChanges, clearAllModals }) => {
  useEffect(() => {
    if (isSuccessful) {
      closeModal();
    }
  }, [isSuccessful]); // eslint-disable-line
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: { phone: memberInputData.phone },
    validate,
    onSubmit: () => {
      submitChanges({ ...memberInputData, phone: formik.values.phone }, "modal", true);
    }
  });
  const { errors, touched, values } = formik;

  return (
    <Modal closeModal={closeModal} clearAllModals={clearAllModals}>
      <form className={modalStyles.form} onSubmit={formik.handleSubmit}>
        {loading && <OverlayPreloader overlayClassName="sectionOverlayModal" />}
        <div>
          <p className={styles.popupSubtitle}>{t("dashboard_single_listing_popup_top_title")}</p>
          <p className={styles.popupTitle}>{t("dashboard_single_listing_primary_phone")}</p>
          <hr className={styles.hr} />
          <Input
            error={errors.phone}
            id="phone"
            isInvalid={errors.phone && touched.phone}
            name="phone"
            onBlur={e => handleBlur(formik, e.target.name)}
            label={t("dashboard_single_listing_primary_phone")}
            onChange={formik.handleChange}
            required
            value={values.phone}
          />
        </div>

        <ModalControl
          onExit={closeModal}
          confirmationType="submit"
          isClose
          confirmDisabled={loading}
          confirmClassName={loading ? "button--outline" : null}
        >
          {t("dashboard_single_listing_popup_save")}
        </ModalControl>
      </form>
    </Modal>
  );
};
