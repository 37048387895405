import React from "react";
import { useTranslation } from "react-i18next";

import img from "../../../../media/images/testImg.png";
import { ReactComponent as LogoutIcon } from "../../../../media/icons/logout-main.svg";
import { Button } from "../../../common/button";
import { getNameWebsiteFromUrl } from "../../../../helpers";
import styles from "./matches.module.css";

export const Matches = ({ matches, onOpenModal }) => {
  const { t } = useTranslation();

  if (!matches.length) {
    return null;
  }

  return (
    <>
      {matches.map(({ attorney, city, state, listing_url }) => (
        <div className={styles.matches} key={attorney}>
          <img className={styles.photo} src={img} alt="profile imf" />
          <div className={styles.info}>
            <span className={styles.name}>{attorney}</span>
            <span className={styles.address}>{`${city}, ${state}`}</span>
          </div>
          <div className={styles.content}>
            <span className={styles.website}>{getNameWebsiteFromUrl(listing_url)}</span>
            <a className={styles.profileLink} href={listing_url} target="_blank" rel="noopener noreferrer">
              <LogoutIcon className={styles.icon} />
              <span className={styles.uppercase}>{t("dashboard_common_button_public_profile")}</span>
            </a>
          </div>
          <Button className={styles.button} onClick={() => onOpenModal(true, attorney)}>
            {t("dashboard_common_button_message")}
          </Button>
        </div>
      ))}
    </>
  );
};
