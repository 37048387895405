import axios from "axios";
import queryString from "query-string";

import { referralRequests } from "../constants/actionTypes";
import { typeReferrals } from "../constants/referralRequests";

export const actions = {
  getCases({ sort, page, dirId, practiceId, search, perPage, type = "referrals", isSelectedFilter, team = {} }) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value || team?.value ? "/team" : "";
      const userIdParams = currentTeam?.value || team?.value ? { user_id: currentTeam?.value || team?.value } : {};

      dispatch(this.setLoading(true));

      const typeReferralsString = typeReferrals[type];
      const searchValue = search ? { search } : {};
      const perPageValue = perPage ? { per_page: perPage } : {};
      const sortValue = sort ? { sort } : {};
      const filterParams = queryString.stringify(
        { dir_id: dirId, practice_id: practiceId, page, ...sortValue, ...searchValue, ...perPageValue, ...userIdParams },
        { arrayFormat: "index" }
      );

      axios({
        method: "get",
        responseType: "json",
        url: `members/json-api${teamUrl}/case/${typeReferralsString}${filterParams ? `?${filterParams}` : ""}`,
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(({ data }) => {
          const { cases, hidden, purchased, notpurchased, filters, ...pagination } = data;

          dispatch({
            type: referralRequests.GET_CASES_SUCCESS,
            payload: {
              cases,
              pagination,
              tabsCounter: {
                hidden,
                purchased,
                notpurchased
              },
              filters,
              isSelectedFilter
            }
          });
        })
        .catch(err => dispatch({ type: referralRequests.GET_CASES_FAIL, payload: err }))
        .finally(() => {
          dispatch(this.setLoading(false));
        });
    };
  },

  getImportReady() {
    return dispatch => {
      axios
        .get("/members/json-api/case/import-ready")
        .then(res => {
          dispatch({
            type: referralRequests.GET_IMPORT_READY,
            payload: res.data
          });
        })
        .catch(err =>
          dispatch({
            type: referralRequests.GET_CASES_FAIL,
            payload: err
          })
        );
    };
  },

  getImport() {
    return dispatch => {
      dispatch(this.setLoadingImport(true));

      axios
        .get("/members/json-api/case/import")
        .then(res => {
          dispatch({
            type: referralRequests.GET_IMPORT,
            payload: res.data
          });
          dispatch(this.getCases());
        })
        .catch(err =>
          dispatch({
            type: referralRequests.GET_CASES_FAIL,
            payload: err
          })
        );
    };
  },

  createNewReferralRequest(referralRequest) {
    return dispatch => {
      dispatch(this.setLoading(true));
      axios({
        method: "post",
        url: "/members/json-api/case/add",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(referralRequest)
      })
        .then(res => {
          dispatch({
            type: referralRequests.GET_NEW_REFERRAL_REQUEST,
            payload: {
              newReferralRequest: res.data.lead,
              followups: res.data.followups
            }
          });
        })
        .catch(error => {
          console.error("createNewReferralRequest", error);
        })
        .finally(() => {
          dispatch(this.setLoading(false));
        });
    };
  },

  sendAnswersForQuestion(answers) {
    return dispatch => {
      dispatch(this.updateReferralRequest(false));
      dispatch(this.setLoading(true));
      axios({
        method: "patch",
        url: "/members/json-api/case/add",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(answers)
      })
        .then(res => {
          dispatch(this.updateReferralRequest(res.data.ok));
          dispatch(this.clearFollowups());
        })
        .catch(error => {
          console.error("sendAnswersForQuestion", error);
        })
        .finally(() => {
          dispatch(this.setLoading(false));
        });
    };
  },

  clearFollowups() {
    return {
      type: referralRequests.CLEAR_NEW_REFERRAL_REQUEST
    };
  },

  setLoading(status) {
    return {
      type: referralRequests.SET_CASES_PRELOADER,
      payload: status
    };
  },

  setLoadingImport(status) {
    return {
      type: referralRequests.SET_CASES_PRELOADER_IMPORT,
      payload: status
    };
  },

  toggleActivityOfModal({ modal, isActive }) {
    return {
      type: referralRequests.TOGGLE_ACTIVITY_OF_MODAL,
      payload: {
        modal,
        isActive
      }
    };
  },

  clearAllModals() {
    return {
      type: referralRequests.CLEAR_ALL_MODALS
    };
  },

  setClearReferrals() {
    return {
      type: referralRequests.SET_CLEAR_REFERRALS
    };
  },

  updateReferralRequest(bol) {
    return {
      type: referralRequests.UPDATE_REFERRAL_REQUEST,
      payload: bol
    };
  }
};
