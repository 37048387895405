import React from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import cn from "classnames";

import MainUserInfo from "../mainUserInfo";
import Location from "../location";
import Verifications from "../verifications";
import { Button } from "../../common/button";
import { modalsName } from "../../../constants/account";
import styles from "./settings.module.css";

const Settings = ({ success, preloaders, profile, updateContacts, openModal, keycloak }) => {
  const { name_f, name_l, email_verified, phone_verified, email, phone, state, country, city, zip } = profile;
  const { t } = useTranslation();

  const validate = values => {
    const { name_f: fullName, name_l: lastName } = values;

    const errors = {};

    if (!fullName) {
      errors.name_f = t("dashboard_common_required");
    }

    if (!lastName) {
      errors.name_l = t("dashboard_common_required");
    }

    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name_f: name_f || "",
      name_l: name_l || "",
      state: state || { value: "" },
      country: country ? { value: country } : { value: "" },
      city: city || "",
      zip: zip || ""
    },
    onSubmit: values => {
      const newValues = {
        ...profile,
        state: values.state.value,
        phone: values.phone,
        email: values.email,
        name_f: values.name_f,
        name_l: values.name_l,
        city: values.city,
        country: values.country.value,
        zip: values.zip
      };
      updateContacts(newValues);
    },
    validate
  });

  const { errors, handleSubmit, touched, values, setFieldTouched, setFieldValue, resetForm } = formik;

  const handleBlur = inputName => {
    setFieldTouched(inputName, true);
  };

  const handleChangeSelect = (newValue, actionMeta) => {
    setFieldValue(actionMeta.name, newValue);

    if (actionMeta.name === "country") {
      setFieldValue("state", "");
    }
  };

  const handleChange = e => {
    setFieldValue(e.target.name, e.target.value);
  };

  return (
    <section>
      <h2 className={styles.title}>{t("dashboard_account_personalinfo")}</h2>

      <MainUserInfo
        preloaders={preloaders}
        success={success}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        touched={touched}
      />

      <h3 className={styles.title}>{t("dashboard_account_location")}</h3>

      <Location handleChangeSelect={handleChangeSelect} values={values} handleChange={handleChange} />

      <div className={styles.actions}>
        <Button className={styles.cancel} onClick={resetForm}>
          {t("dashboard_common_button_cancel")}
        </Button>
        <Button
          className={cn(styles.submit, { "button--outline": preloaders.contacts })}
          disabled={preloaders.contacts}
          onClick={handleSubmit}
        >
          {t("dashboard_common_button_save")}
        </Button>
      </div>

      <Verifications
        openModal={openModal}
        emailVerified={email_verified}
        phoneVerified={phone_verified}
        phone={phone}
        email={email}
        keycloak={keycloak}
      />

      <div className={styles.password}>
        <p className={styles.passwordName}>Password</p>
        <button
          type="button"
          className={styles.passwordButton}
          onClick={() =>
            keycloak.login({
              redirectUri: `${window.location.origin}/account?type=${modalsName.changePasswordSuccess}`,
              action: "UPDATE_PASSWORD"
            })
          }
        >
          {t("dashboard_account_changepassword")}
        </button>
      </div>
    </section>
  );
};

export default Settings;
