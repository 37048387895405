import React from "react";
import cn from "classnames";

import { ACTIVE_BLOCK_NAMES } from "../../../../constants/messages";
import { ReactComponent as IconLeft } from "../../../../media/icons/arrowLeft.svg";
import { ReactComponent as IconArchive } from "../../../../media/icons/archive.svg";
import { ReactComponent as IconLetter } from "../../../../media/icons/letter.svg";
/* todo: remove */
// import { Button } from "../../../common/button";
import styles from "./messagePanel.module.css";

const MessagePanel = ({
  name,
  // listingUrl,
  // attorney,
  setActiveBlocks,
  activityBlocks,
  handleUnread,
  handleArchive,
  isArchived,
  handleUnnArchive
}) => {
  const buttonExitDisable = !activityBlocks.detail ? styles.buttonExitDisabled : "";
  const messagePanelDisabled = activityBlocks.detail || activityBlocks.detailDesktop ? styles.messagePanelDisabled : "";
  // const detailDisabled = activityBlocks.detail ? styles.detailDisabled : "";

  const activityArchive = () => {
    if (isArchived) {
      handleUnnArchive();
    } else {
      handleArchive();
    }
  };

  return (
    <div className={cn(styles.messagePanel, messagePanelDisabled)}>
      <div className={styles.headContent}>
        <button
          type="button"
          className={cn("button--clear", styles.buttonExit, buttonExitDisable)}
          onClick={() => setActiveBlocks({ [ACTIVE_BLOCK_NAMES.MESSAGES]: true, [ACTIVE_BLOCK_NAMES.DETAIL]: false })}
        >
          <IconLeft className={styles.iconLeft} />
        </button>

        <span className={styles.name}>{name}</span>
        <div className={styles.actions}>
          <button type="button" className={cn("button--clear", styles.buttonLetter)} title="Mark as Unread" onClick={handleUnread}>
            <IconLetter />
          </button>
          <button
            type="button"
            className={cn("button--clear", styles.buttonArchive)}
            title={isArchived ? "Un archive" : "Archive"}
            onClick={activityArchive}
          >
            <IconArchive />
          </button>
        </div>
      </div>
      {/*
        <div className={cn(styles.detail, detailDisabled)}>
          <div>
            <span className={styles.source}>Source: listing contact form on</span>
            <span className={styles.listing}>{listingUrl}</span>
            <span className={styles.attorney}>({attorney})</span>
          </div>
          <Button
            className={styles.detailButton}
            onClick={() =>
              setActiveBlocks({
                [ACTIVE_BLOCK_NAMES.DETAIL]: true,
                [ACTIVE_BLOCK_NAMES.MESSAGES]: false,
                [ACTIVE_BLOCK_NAMES.CONVERSATION]: false
              })
            }
          >
            Details
          </Button>
        </div>
      */}
    </div>
  );
};

export default MessagePanel;
