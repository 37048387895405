import React from "react";

import styles from "./languages.module.css";

const Languages = ({ languages }) => {
  return (
    <div className={styles.content}>
      <h3 className={styles.title}>Languages</h3>
      <div>
        {languages.map(item => {
          return (
            <div className={styles.tile} key={`${item.prkey}-${item.skill}`}>
              <div className={styles.tileHeading}>
                <h6 className={styles.tileTitle}>{item.language_title}</h6>
              </div>
              <div className={styles.footer}>
                <p className={styles.tileDescription}>{item.skill}</p>
                <span className={styles.tileAuthor}>{item.type}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Languages;
