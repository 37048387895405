import React from "react";
import cn from "classnames";

import { useTranslation } from "react-i18next";
import { Radio } from "../../common/radio";
import { renderBrandIcon } from "../../../helpers";
// import { Button } from "../../common/button";
import styles from "../paymentMethods/paymentMethods.module.css";

const CardList = ({
  defaultSource,
  paymentMethods,
  checkedRadio,
  handleSelect,
  inputCheck,
  // setModal,
  isUpdatePaymentMethod,
  updatedPaymentMethodText,
  activeSubscriptionCard,
  handleDelete,
  isNotShowCantDeleteText
}) => {
  const { t } = useTranslation();

  const renderCardList = paymentMethods.map(({ id, brand, cannot_delete_text: cantDeleteText, last4 }) => {
    const isDefault = id === defaultSource?.id;
    const isActiveSubscriptionCard = id === activeSubscriptionCard;

    return (
      <div>
        <div className={cn(styles.cardsLayoutItem, isDefault ? styles.cardsLayoutItemDefault : "")} key={id}>
          <Radio
            className={styles.cardItemLabel}
            id={id}
            name="select_card"
            value={id}
            checked={checkedRadio === id}
            onChange={handleSelect}
            refRadio={isDefault && inputCheck}
            isNewStyles
          >
            <span className={styles.cardBrandImg}>
              <img src={renderBrandIcon(brand)} alt="" />
            </span>
            <span className={cn(styles.numberWrap, checkedRadio === id ? styles.defaultNumber : null)}>
              <p className={styles.layoutNumber}>**** - **** - **** - {last4}</p>
              {!isUpdatePaymentMethod && isDefault && <p className={styles.defaultCard}>{t("dashboard_payments_defaultcard")}</p>}
              {isUpdatePaymentMethod && isActiveSubscriptionCard && updatedPaymentMethodText && (
                <p className={styles.layoutMain}>{updatedPaymentMethodText}</p>
              )}
            </span>

            {!isUpdatePaymentMethod && !isDefault && !cantDeleteText && (
              <button type="button" className={styles.remove} onClick={() => handleDelete(id)}>
                {t("remove")}
              </button>
            )}
          </Radio>
        </div>

        {cantDeleteText && !isNotShowCantDeleteText && <span className={styles.cantDeleteText}>{cantDeleteText}</span>}
      </div>
    );
  });

  return (
    <div>
      <form action="">
        <div className={styles.cardsLayout}>{renderCardList}</div>
        {/*
        <Button
          type="submit"
          onClick={e => {
            e.preventDefault();
            setModal({ add: true });
          }}
          className={cn(styles.buttonNew, isUpdatePaymentMethod ? styles.buttonPaymentMethod : styles.buttonHideTablet)}
        >
          + {t("dashboard_payments_addcardbutton")}
        </Button>
       */}
      </form>
    </div>
  );
};

export default CardList;
