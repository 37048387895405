import { home } from "../constants/actionTypes";

const defaultState = {
  dashboardData: {
    listing_messages: [],
    leads: [],
    calls: [],
    notifications: [],
    qa: [],
    cases: [],
    listings: []
  },
  error: {
    listingMessages: null,
    dashboardData: null
  },
  preloaders: {
    dashboard: false,
    listingAssistant: false
  },
  successListingAssistant: false,
  addNewListingData: {}
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case home.SET_LOADING:
      return {
        ...state,
        preloaders: { ...state.preloaders, [action.payload.section]: action.payload.isSectionLoading }
      };

    case home.GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboardData: action.payload,
        preloaders: {
          dashboard: false
        }
      };

    case home.GET_DASHBOARD_DATA_FAIL:
      return {
        ...state,
        preloaders: {
          dashboard: false
        }
      };

    case home.SET_DASHBOARD_DATA_CLEAR:
      return {
        ...state,
        dashboardData: {
          ...defaultState.dashboardData
        }
      };

    case home.MARK_UNREAD: {
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          notifications: action.payload
        }
      };
    }

    case home.MARK_READ: {
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          notifications: action.payload
        }
      };
    }

    case home.ADD_LISTING_ASSISTANT: {
      return {
        ...state,
        preloaders: { ...state.preloaders, listingAssistant: false },
        successListingAssistant: true
      };
    }

    case home.ADD_LISTING_ASSISTANT_REMOVE_SUCCESS: {
      return {
        ...state,
        successListingAssistant: false
      };
    }

    case home.ADD_LISTING_ASSISTANT_FAIL: {
      return {
        ...state,
        preloaders: { ...state.preloaders, listingAssistant: false }
      };
    }

    case home.GET_ADD_NEW_LISTING_DATA: {
      return {
        ...state,
        addNewListingData: action.payload
      };
    }

    case home.ADD_NEW_LISTING: {
      return {
        ...state,
        preloaders: { ...state.preloaders, listingAssistant: false }
      };
    }

    case home.ADD_NEW_LISTING_FAIL: {
      return {
        ...state,
        preloaders: { ...state.preloaders, listingAssistant: false }
      };
    }

    default:
      return {
        ...state
      };
  }
}
