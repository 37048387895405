import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import Checkbox from "../../../common/checkbox";
import styles from "./bookmarked.module.css";

const areEqual = (prevProps, nextProps) => {
  return nextProps.bookmarked === prevProps.bookmarked;
};

export const Bookmarked = React.memo(({ bookmarked, setBookmarked, isHideDesktop }) => {
  const { t } = useTranslation();

  return (
    <div className={cn(isHideDesktop && styles.hideDesktop)}>
      <Checkbox
        checked={bookmarked}
        className={styles.checkbox}
        id="bookmarked"
        name="bookmarked"
        onChange={() => setBookmarked(state => !state)}
      >
        {t("dashboard_qa_all_bookmarked")}
      </Checkbox>
    </div>
  );
}, areEqual);
