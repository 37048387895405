import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Modal } from "../common/modal";
import { actions as referralRequestsActions } from "../../actions/referralRequests";
import { modals } from "../../constants/referralRequests";
import { ReactComponent as Check } from "../../media/icons/check-green.svg";
import styles from "./importingSuccess.module.css";
import ModalControl from "../modalControl";

export const ImportingSuccess = () => {
  const { t } = useTranslation();
  const { importReady = {} } = useSelector(state => state.referralRequests);

  const { requests, messages } = importReady;
  const dispatch = useDispatch();
  const clearAllModals = useCallback(() => dispatch(referralRequestsActions.clearAllModals()), [dispatch]);
  const toggleActivityOfModal = useCallback(
    (modal, isActive) =>
      dispatch(
        referralRequestsActions.toggleActivityOfModal({
          modal,
          isActive
        })
      ),
    [dispatch]
  );

  const handleClose = () => {
    toggleActivityOfModal(modals.importingSuccess, false);
  };

  return (
    <Modal clearAllModals={clearAllModals} closeModal={handleClose} classNameMain={styles.modal}>
      <div className={styles.wrapper}>
        <h3 className={styles.modalTitle}>{t("dashboard_referrals_import_top_title")}</h3>
        <h1 className={styles.title}>
          <Check className={styles.checkIcon} />
          {t("dashboard_referrals_import_success_import")}
        </h1>
        <hr className={styles.hr} />
        <div className={styles.group}>
          <div className={styles.itemGroup}>
            <p className={styles.total}>{messages}</p>
            <p className={styles.subTitle}>{t("dashboard_referrals_import_confirmation_messages")}</p>
          </div>
          <div>
            <p className={styles.total}>{requests}</p>
            <p className={styles.subTitle}>{t("dashboard_referrals_import_confirmation_referrals")}</p>
          </div>
        </div>
      </div>

      <ModalControl onConfirm={handleClose} isHideExit>
        {t("dashboard_common_close")}
      </ModalControl>
    </Modal>
  );
};
