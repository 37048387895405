import React from "react";
import { formatDistanceToNow } from "date-fns";
import { Link } from "react-router-dom";

import { ReactComponent as PinIcon } from "../../../media/icons/pin.svg";
// import { ReactComponent as HeartIcon } from "../../../media/icons/heart.svg";
// import testCompany from "../../../media/images/testCompany.png";
import styles from "./jobs.module.css";
import { parseDate } from "../../../helpers/parseDate";

const workType = {
  1: "In-Person",
  2: "Hybrid",
  3: "Remote"
};

const jobType = {
  1: "Full Time",
  2: "Part Time",
  3: "Contract",
  4: "Temporary",
  5: "Volunteer",
  6: "Internship"
};

const Jobs = ({ jobs }) => {
  const jobsList = jobs.map(item => {
    const job = item.jobs?.length ? item.jobs[0] : {};
    const jobTypes = job.type_id ? JSON.parse(job.type_id) : [];
    const workplaces = job.workplace_type_id ? JSON.parse(job.workplace_type_id) : [];

    return (
      <div className={styles.item} key={`${item.attorney}-${job.location_state}`}>
        <div className={styles.itemHead}>
          <Link to={`/public-listing/${item.listing_id}`} className={styles.itemPosition}>
            {item.attorney}
          </Link>
          {/*
          <button type="button" className={styles.itemFavorite}>
            <HeartIcon />
          </button>
          */}
        </div>
        <span className={styles.itemSalary}>{job.compensation ? `$${job.compensation}` : ""}</span>
        <div className={styles.detail}>
          {job.location_state && (
            <>
              <span className={styles.detailItem}>
                <PinIcon />
                {job.location_state}
              </span>
              <span className={styles.detailLine} />
            </>
          )}

          {jobTypes.length ? (
            <>
              {jobTypes.map(itemJob => (
                <React.Fragment key={jobType[itemJob]}>
                  <span className={styles.detailItem}>{jobType[itemJob]}</span>
                  <span className={styles.detailLine} />
                </React.Fragment>
              ))}
            </>
          ) : null}

          {workplaces.length ? (
            <>
              {workplaces.map(itemWork => (
                <React.Fragment key={workType[itemWork]}>
                  <span className={styles.detailItem}>{workType[itemWork]}</span>
                  <span className={styles.detailLine} />
                </React.Fragment>
              ))}
            </>
          ) : null}
          <span className={styles.detailItem}>{formatDistanceToNow(new Date(parseDate(item.added)))}</span>
        </div>
        <div className={styles.itemFooter}>
          <div className={styles.itemCompany}>
            {/*
            <span className={styles.companyLogo}>
              <img src={testCompany} alt="Company" />
            </span>
            */}
            <span className={styles.companyName}>{job.company_desc}</span>
          </div>
          <span className={styles.itemDate}>{formatDistanceToNow(new Date(parseDate(item.approved_date)))}</span>
        </div>
      </div>
    );
  });
  return (
    <div className={styles.content}>
      <h4 className={styles.title}>Latest Jobs posted</h4>
      <div className={styles.jobs}>{jobsList}</div>
    </div>
  );
};

export default Jobs;
