import { useSelector } from "react-redux";
import React from "react";

import Checkbox from "../../../common/checkbox";
import { FILTERS } from "../../../../constants/questionsAnswers";
import styles from "./practices.module.css";

const areEqual = (prevProps, nextProps) => {
  return nextProps.activeFilters.practices.length === prevProps.activeFilters.practices.length;
};

export const Practices = React.memo(({ activeFilters, handleFilter }) => {
  const { myAnswersFilter } = useSelector(state => state.questions);

  if (!myAnswersFilter?.practices?.length) {
    return null;
  }

  return (
    <ul className={styles.ulList}>
      <li className={styles.ulHead}>Practice Area</li>
      <li>
        <ul className={styles.ulList}>
          {myAnswersFilter.practices.map(({ practice_id: id, title }) => (
            <li key={id}>
              <Checkbox
                checked={activeFilters.practices.includes(id)}
                className={styles.checkbox}
                id={title}
                name={title}
                onChange={() => handleFilter(FILTERS.PRACTICES, id)}
              >
                {title}
              </Checkbox>
            </li>
          ))}
        </ul>
      </li>
    </ul>
  );
}, areEqual);
