import React from "react";
import cn from "classnames";

import styles from "./profileListing.module.css";
import { Button } from "../../common/button";
import { ReactComponent as SaveIcon } from "../../../media/icons/heart.svg";
import { ReactComponent as PhoneIcon } from "../../../media/icons/phone.svg";

const ProfileListing = ({ isExpand, list }) => {
  return (
    <div className={cn(styles.flex, isExpand ? styles.flexExpand : "")}>
      {list.map(item => {
        return (
          <div className={cn(styles.tile, isExpand ? styles.tileExpand : "")} key={item.title}>
            <div className={styles.favorites}>
              <Button className={styles.favoritesButton}>
                <SaveIcon />
              </Button>
            </div>

            <div className={styles.heading}>
              <div className={styles.tileImage}>
                <img src={item.banner} alt="Events banner" />
              </div>
              <div className={styles.headingContent}>
                <div className={styles.headingFlex}>
                  <h6 className={styles.tileTitle}>{item.title}</h6>
                  <span className={cn(styles.sponsor, styles.sponsorTablet)}>Sponsor</span>
                </div>
                <p className={styles.tilePosition}>{item.position}</p>
                <span className={cn(styles.sponsor, styles.sponsorMobile)}>Sponsor</span>

                <div className={styles.tileInfoTablet}>
                  <p className={styles.practices}>{item.practices}</p>

                  <div className={styles.tileFlex}>
                    <p className={styles.experience}>{item.experience}</p>
                    <a href="tel:1212121" className={styles.phone}>
                      <PhoneIcon /> {item.phone}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.tileInfoMobile}>
              <p className={styles.practices}>{item.practices}</p>

              <div className={styles.tileFlex}>
                <p className={styles.experience}>{item.experience}</p>
                <a href="tel:1212121" className={styles.phone}>
                  <PhoneIcon /> {item.phone}
                </a>
              </div>
            </div>

            <p className={styles.tileDescription}>{item.description}</p>
          </div>
        );
      })}
    </div>
  );
};

export default ProfileListing;
