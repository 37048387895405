import queryString from "query-string";
import { payment } from "../constants/actionTypes";

const defaultPreloaders = {
  addCard: null,
  chargeForMembership: null,
  deleteCard: null,
  paymentMethods: null,
  paymentHistory: null,
  updatePaymentMethod: null,
  updateCard: null
};

const defaultState = {
  defaultSource: null,
  error: {
    addCard: null,
    chargeForMembership: null,
    deleteCard: null,
    paymentMethods: null,
    paymentHistory: null,
    updateCard: null,
    stripeTokenError: null
  },
  paymentMethods: null,
  subscriptions: [],
  paymentHistory: [],
  preloaders: defaultPreloaders,
  paymentError: null,
  selectedCard: null,
  success: {
    addCard: null,
    chargeForMembership: null,
    deleteCard: null,
    paymentMethods: null,
    paymentHistory: null,
    updatePaymentMethod: null,
    updateCard: null
  },
  paymentHistoryPagination: {},
  stripeToken: "",
  subscriptionPagination: {},
  subscriptionLoadingActive: false
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case payment.SET_LOADING:
      return {
        ...state,
        preloaders: { ...state.preloaders, [action.payload.section]: action.payload.isSectionLoading }
      };

    case payment.GET_PAYMENT_METHODS:
      // eslint-disable-next-line
      const { subscriptionPagination } = action.payload;
      // eslint-disable-next-line
      const lastPageNumSub = parseInt(queryString.parse(queryString.extract(subscriptionPagination.last_page)).page, 10);
      // eslint-disable-next-line no-case-declarations
      const currentPageNumSub = parseInt(queryString.parse(queryString.extract(subscriptionPagination.current_page)).page, 10);

      return {
        ...state,
        paymentMethods: action.payload.cards,
        subscriptions: action.payload.subscriptions,
        expiredSubscriptions: action.payload.expiredSubscriptions,
        subscriptionPagination: {
          currentPage: subscriptionPagination.current_page,
          currentPageNum: currentPageNumSub,
          lastPage: subscriptionPagination.last_page,
          lastPageNum: lastPageNumSub,
          perPage: subscriptionPagination.per_page
        },

        preloaders: {
          ...state.preloaders,
          paymentMethods: false
        }
      };

    case payment.SET_SELECTED_CARD:
      return {
        ...state,
        selectedCard: action.payload
      };

    case payment.GET_PAYMENT_METHODS_FAIL:
      return {
        ...state,
        paymentError: action.payload,
        preloaders: {
          ...state.preloaders,
          paymentMethods: false
        }
      };

    case payment.GET_PAYMENT_HISTORY:
      // eslint-disable-next-line no-case-declarations
      const lastPageNum = parseInt(queryString.parse(queryString.extract(action.payload.pagination.last_page)).page, 10);
      // eslint-disable-next-line no-case-declarations
      const currentPageNum = parseInt(queryString.parse(queryString.extract(action.payload.pagination.current_page)).page, 10);

      return {
        ...state,
        paymentHistory: action.payload.payments,
        paymentHistoryPagination: {
          currentPage: action.payload.pagination.current_page,
          currentPageNum,
          lastPage: action.payload.pagination.last_page,
          lastPageNum,
          perPage: action.payload.pagination.per_page
        },
        preloaders: {
          ...state.preloaders,
          paymentHistory: false
        }
      };

    case payment.GET_PAYMENT_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        preloaders: {
          ...state.preloaders,
          paymentHistory: false
        }
      };

    case payment.UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        error: action.payload,
        preloaders: {
          ...state.preloaders,
          updatePaymentMethod: false
        },
        modals: {
          ...state.modals,
          updatePaymentMethod: false
        },
        success: { ...state.success, updatePaymentMethod: true }
      };

    case payment.UPDATE_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        error: action.payload,
        preloaders: {
          ...state.preloaders,
          updatePaymentMethod: false
        }
      };

    case payment.ADD_CARD_SUCCESS:
      return {
        ...state,
        success: { ...state.success, addCard: action.payload },
        error: { ...state.error, addCard: null }
      };

    case payment.ADD_CARD_FAIL:
      return {
        ...state,
        error: { ...state.error, addCard: action.payload }
      };

    case payment.SET_DEFAULT_SOURCE: {
      if (action.payload.object === "customer") {
        const defaultCard = state.paymentMethods.find(({ id }) => id === action.payload.default_source);
        return {
          ...state,
          defaultSource: defaultCard,
          preloaders: defaultPreloaders
        };
      }

      if (action.payload === "deleted") {
        return {
          ...state,
          defaultSource: state.paymentMethods[0],
          preloaders: defaultPreloaders
        };
      }

      return {
        ...state,
        defaultSource: action.payload,
        preloaders: defaultPreloaders
      };
    }

    case payment.ADD_CARD_LOCALLY: {
      const allPaymentMethods = state.paymentMethods ? state.paymentMethods : [];
      return {
        ...state,
        paymentMethods: [action.payload, ...allPaymentMethods]
      };
    }

    case payment.UPDATE_CARD_SUCCESS:
      return {
        ...state,
        success: { ...state.success, updateCard: action.payload }
      };

    case payment.UPDATE_CARD_LOCALLY: {
      const changedCard = state.paymentMethods.map(card => {
        if (card.id === action.payload.id) {
          return { ...action.payload };
        }
        return { ...card, default_source: action.payload.default_source ? false : card.default_source };
      });

      return {
        ...state,
        paymentMethods: changedCard
      };
    }

    case payment.UPDATE_CARD_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case payment.DELETE_CARD_SUCCESS:
      return {
        ...state,
        success: { ...state.success, deleteCard: action.payload }
      };

    case payment.DELETE_CARD_LOCALLY: {
      const cardToBeDeletedIndex = state.paymentMethods.findIndex(card => card.id === action.payload.id);

      state.paymentMethods.splice(cardToBeDeletedIndex, 1);
      return {
        ...state,
        paymentMethods: [...state.paymentMethods]
      };
    }

    case payment.CHARGE_FOR_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        success: { ...state.success, chargeForMembership: true },
        preloaders: {
          ...state.preloaders,
          chargeForMembership: false
        }
      };

    case payment.CHARGE_FOR_MEMBERSHIP_FAIL:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          chargeForMembership: false
        },
        error: {
          ...state.error,
          chargeForMembership: action.payload
        }
      };

    case payment.SET_STRIPE_TOKEN:
      return {
        ...state,
        stripeToken: action.payload
      };

    case payment.GET_ERROR_TOKEN:
      return {
        ...state,
        error: {
          ...state.error,
          stripeTokenError: action.payload
        }
      };

    case payment.CLEAR_ERROR_TOKEN:
      return {
        ...state,
        error: {
          ...state.error,
          stripeTokenError: null
        }
      };

    case payment.CLEAR_STATE:
      return {
        ...defaultState
      };

    default:
      return {
        ...state
      };
  }
}
