import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { OverlayPreloader } from "../../common/preloader";
import { Notification } from "../../common/notification";
import Checkbox from "../../common/checkbox";
import Notifications from "../notifications";
import styles from "./listingNotifications.module.css";

const ListingNotifications = ({
  preloaders,
  success,
  saveNotificationList,
  notifications,
  notificationsListings,
  handleRemoveNotification,
  userEmail
}) => {
  const [localNotifications, setLocalNotifications] = useState([]);
  const { t } = useTranslation();

  const handleNotificationChange = event => {
    const updatedNotifications = localNotifications.map(item => {
      if (item.notification === event.target.name) {
        return {
          ...item,
          email: String(item.email) === "1" ? "0" : "1"
        };
      }

      return item;
    });

    setLocalNotifications(updatedNotifications);
    saveNotificationList(updatedNotifications);
  };

  const renderNotifications = notificationsListings.map((item, index) => {
    return (
      // eslint-disable-next-line react/no-array-index-key
      <div className={styles.listing} key={`${item.title}-${index}`}>
        <div className={styles.listingHead}>
          <h5 className={styles.listingName}>{item.title}</h5>
          <Link to={`/listings/${item.listing_id}`} className={styles.listingEdit}>
            Edit listing
          </Link>
        </div>
        <Notifications
          isPremium
          emails={item.verifiedemails}
          phones={item.verifiedphones}
          handleRemoveNotification={handleRemoveNotification}
          name={item.title}
          listingId={item.listing_id}
          userEmail={userEmail}
        />
      </div>
    );
  });
  const getCheckedStatus = name => {
    const index = localNotifications.findIndex(item => item.notification === name);
    return localNotifications.length ? Number(localNotifications[index]?.email) : false;
  };

  useEffect(() => {
    if (!localNotifications.length) {
      setLocalNotifications(notifications);
    }
  }, [notifications]);

  return (
    <div className={styles.notificationForm}>
      {preloaders.notifications && <OverlayPreloader />}
      {success.notifications && <Notification success>{t("dashboard_common_saved")}</Notification>}
      {/*
      <h6 className={styles.acctSubtitle}>Profile Changes</h6>
      <p className={styles.description}>Notifications regarding changes to your name, email address, or password</p>
      */}

      <h6 className={styles.acctSubtitle}>{t("dashboard_notifications_paymenttitle")}</h6>

      <div className={styles.checkboxWrap}>
        <Checkbox
          name="payment_reminders"
          id="payment_reminders"
          className={styles.checkboxLabel}
          checked={getCheckedStatus("payment_reminders")}
          onChange={handleNotificationChange}
          big
          isNewStyles
        >
          {t("dashboard_notifications_paymentadvancenotice")}
        </Checkbox>
        <Checkbox
          name="payments"
          id="payments"
          className={styles.checkboxLabel}
          checked
          // onChange={handleNotificationChange}
          big
          isNewStyles
          unactive
        >
          {t("dashboard_notifications_afterpayment")}
        </Checkbox>
      </div>

      <h6 className={styles.acctSubtitle}>Listing Change Notifications</h6>
      <p className={styles.accText}>Notifications related to changes made to your any listing in your account</p>
      <div className={styles.checkboxWrap}>
        <Checkbox
          className={styles.checkboxLabel}
          name="listing"
          id="listing"
          checked
          // onChange={handleNotificationChange}
          big
          isNewStyles
        >
          Email
        </Checkbox>
        <Checkbox isNewStyles className={styles.checkboxLabel} disabled name="listing_sms">
          Text (SMS)
        </Checkbox>
      </div>

      {/*
      todo: remove commented code
      <h6 className={styles.acctSubtitle}>Inbox Notifications</h6>
      <div className={styles.checkboxWrap}>
        <div className="d-flex align-items-baseline">
          <Checkbox isNewStylesclassName={styles.checkboxLabel} id="inbox" big name="inbox" unactive checked onChange={() => {}}>
            New message email notification
          </Checkbox>
          <Tooltip className={styles.tooltip}>{t("dashboard_account_notification_inbox_email")}</Tooltip>
        </div>
        <Checkbox isNewStyles big className={styles.checkboxLabel} disabled id="legal_q_and_a" name="inbox_sms">
          Text (SMS)
        </Checkbox>
      </div>
      */}

      <h2 className={styles.title}>Listing-Specific Notification</h2>
      <span className={styles.notificationDescription}>
        The notifications below are specific to each individual listing. They are independent of the above notification settings.
      </span>

      {renderNotifications}
    </div>
  );
};

export default ListingNotifications;
