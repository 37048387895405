import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import Input from "../input";

import { customStyles as selectStyles } from "../../../constants/select";
import SelectInput from "../../common/selectInput";

import styles from "./selectInsurance.module.css";

const SelectInsuranceComponent = ({
  options,
  name,
  error,
  label,
  isInvalid,
  inputId,
  handleBlur,
  handleSelectChange,
  isRequired,
  value,
  getOptionValue,
  getOptionLabel,
  classNameWrap,
  placeholder,
  otherName,
  otherNameValue,
  handleChangeInput,
  otherError,
  otherIsInvalid,
  otherOnBlur,
  otherRequired
}) => {
  const [isShowInput, setIsShowInput] = useState(false);
  const [isAutoFocusSelect, setIsAutoFocusSelect] = useState(false);
  const selectRef = useRef();

  const onInputChange = (valueInput, isEdited = true) => {
    const isShowCorrectOptions = !options.find(item => item.name.toLowerCase().includes(valueInput));

    if (valueInput && isShowCorrectOptions && !isShowInput) {
      setIsShowInput(true);
      setIsAutoFocusSelect(false);
      handleChangeInput(otherName, valueInput, isEdited);

      handleSelectChange({ value: "" }, { name }, isEdited);
    }

    if (!valueInput) {
      setIsShowInput(false);
    }
  };

  const onHandleChangeInput = event => {
    if (!event.target.value && isShowInput) {
      setIsShowInput(false);
      setIsAutoFocusSelect(true);
    }

    handleChangeInput(otherName, event.target.value);
  };

  const handleChangeSelect = (event, action) => {
    if (event.name === "Custom") {
      setIsShowInput(true);
    } else {
      handleSelectChange(event, action, true);
    }
  };

  useEffect(() => {
    if (otherNameValue) {
      onInputChange(otherNameValue, false);
    }
  }, []);

  return (
    <>
      {isShowInput ? (
        <Input
          placeholder={placeholder}
          classNameWrap={cn(styles.selectWrap, classNameWrap)}
          className={styles.input}
          name={otherName}
          value={otherNameValue}
          onChange={onHandleChangeInput}
          error={otherError}
          isInvalid={otherIsInvalid}
          onBlur={otherOnBlur}
          required={otherRequired}
          errorClassName={styles.errorInput}
          isAutoFocus
        />
      ) : (
        <SelectInput
          classNameWrap={cn(styles.selectWrap, classNameWrap)}
          className={styles.select}
          classNameInvalid={styles.selectInvalid}
          error={error}
          isInvalid={isInvalid}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          inputId={inputId}
          label={label}
          name={name}
          options={options}
          onBlur={handleBlur}
          onChange={handleChangeSelect}
          required={isRequired}
          customStyles={selectStyles}
          value={value}
          placeholder={placeholder}
          isNewSelectedColor
          classNameError={styles.errorText}
          onInputChange={onInputChange}
          selectRef={selectRef}
          isAutoFocus={isAutoFocusSelect}
        />
      )}
    </>
  );
};

export default SelectInsuranceComponent;
