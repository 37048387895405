import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import format from "date-fns/format";
// getNameWebsiteFromUrl,
import { getStateName } from "../../../../helpers";
import { Button } from "../../../common/button";
import styles from "./info.module.css";
import { ReactComponent as PenIcon } from "../../../../media/icons/edit-pen-white.svg";
import { statusesConstant } from "../../../../constants/referralRequestDetail";

export const ContentComponent = ({
  caseData,
  categoriesProfessions,
  onOpenPopupDescription,
  states,
  isFailedEmailVerification,
  handleOpenContactInfo,
  status
}) => {
  const { t } = useTranslation();

  const practices = useMemo(
    () =>
      categoriesProfessions.practices
        ? categoriesProfessions.practices.filter(
            item =>
              item.practice_id === caseData.practice1 || item.practice_id === caseData.practice2 || item.practice_id === caseData.practice3
          )
        : [],
    [caseData, categoriesProfessions]
  );

  /*
  const professions = useMemo(
    () =>
      categoriesProfessions.professions
        ? categoriesProfessions.professions.find(item => practices[0]?.profession_id === item.profession_id)
        : [],
    [categoriesProfessions.professions, practices]
  );
  */

  const isShowEditButton = [statusesConstant.pendingReview, statusesConstant.pending].includes(status);

  return (
    <>
      <hr className={styles.hr} />

      <div className={cn(styles.flexbox, styles.flexboxIndentChild, styles.relative)}>
        <div className={styles.flexItem}>
          <h3 className={styles.label}>NAME</h3>
          <p className={styles.textContent}>{`${caseData.name_f} ${caseData.name_l || ""}`}</p>
        </div>
        <div className={styles.flexItem}>
          <h3 className={styles.label}>{t("dashboard_ppl_table_received")}</h3>
          <p className={styles.textContent}>{format(new Date(caseData.added), "MM/dd/yyyy - h:mm a")}</p>
        </div>
        <div className={styles.flexItem}>
          <h3 className={styles.label}>{t("dashboard_common_field_email")}</h3>
          <p className={styles.textContent}>{caseData.email}</p>
          {isFailedEmailVerification && <p className={styles.errorEmail}>This email address is not verified</p>}
        </div>
        <div className={styles.flexItem}>
          <h3 className={styles.label}>REQUEST ID</h3>
          <p className={styles.textContent}>{caseData.lead_id}</p>
        </div>
        <div className={styles.flexItem}>
          <h3 className={styles.label}>{t("dashboard_common_field_phonenumber")}</h3>
          <p className={styles.textContent}>{caseData.phone}</p>
        </div>
        {isShowEditButton && (
          <Button className={cn(styles.editBtn, styles.editContactInfo)} onClick={handleOpenContactInfo}>
            <PenIcon className={styles.penIcon} />
          </Button>
        )}
      </div>

      <hr className={styles.hrFullWidth} />

      <div>
        <div className={styles.headerBox}>
          <h3 className={styles.label}>{t("dashboard_referrals_details_description")}</h3>

          {isShowEditButton && (
            <Button className={styles.editBtn} onClick={onOpenPopupDescription}>
              <PenIcon className={styles.penIcon} />
            </Button>
          )}
        </div>
        <p className={styles.textContent}>{caseData.summary}</p>
      </div>

      <hr className={styles.hrFullWidth} />

      <div className={styles.flexbox}>
        <div className={styles.flexItem}>
          <h3 className={styles.label}>{t("dashboard_referrals_details_location")}</h3>
          <p className={styles.textContent}>
            {caseData.city}, {getStateName(states, caseData.state)}
          </p>
        </div>
        <div className={styles.flexItem}>
          <h3 className={styles.label}>{t("dashboard_referrals_details_specialties")}</h3>
          {practices.map(item => {
            return (
              <p className={styles.textContent} key={item.practice_id}>
                {item.title}
              </p>
            );
          })}
        </div>
      </div>

      <hr className={cn(styles.hrFullWidth, styles.mt30)} />
      {/*
      <div className={styles.flexbox}>
        <div className={styles.flexItem}>
          <h3 className={styles.label}>{t("dashboard_referrals_details_directory")}_1111</h3>
          <a className={styles.profileLink} href={caseData.website_url} target="_blank" rel="noopener noreferrer">
            {getNameWebsiteFromUrl(caseData.website_url)}
          </a>
        </div>
        <div className={styles.flexItem}>
          <h3 className={styles.label}>PROFESSION_222</h3>
          <p className={styles.textContent}>{professions?.title}</p>
        </div>
      </div>

      <hr className={cn(styles.hrFullWidth, styles.mt30)} />
      */}
    </>
  );
};
