import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import cn from "classnames";

import { actions as authActions } from "../../actions/auth";
import "./styles.css";
import styles from "./login.module.css";
import { ReactComponent as Logo } from "../../media/icons/logo.svg";
import { Button } from "../common/button";
import Checkbox from "../common/checkbox";
import Input from "../common/input";
import { OverlayPreloader } from "../common/preloader";
import { Notification } from "../common/notification";
import { email as emailRegex } from "../../constants/regex";

export const Login = ({ errors, isLoggedIn, history, login, loginMethods, preloaders, user, keycloak }) => {
  const [notes, setNotes] = useState({ login: null });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const setError = useCallback(() => dispatch(authActions.setError("failLogin", "")), [dispatch]);
  const { isKeycloack } = useSelector(state => state.auth);

  const validate = ({ amember_login, amember_pass }) => {
    const validationErrors = {};
    if (!amember_login) {
      validationErrors.amember_login = t("dashboard_common_required");
    } else if (!amember_login.match(emailRegex)) {
      validationErrors.amember_login = t("dashboard_form_validations_invalid_email");
    }
    if (!amember_pass) {
      validationErrors.amember_pass = "Required";
    }
    return validationErrors;
  };

  useEffect(() => {
    document.title = t("dashboard_login_metatitle");
    if (isLoggedIn) {
      history.push("/");
    }
  }, [isLoggedIn]); // eslint-disable-line

  useEffect(() => {
    if (errors.login) {
      setNotes({ login: errors.login });
      setTimeout(() => setNotes({ login: false }), 6000);
    }
  }, [errors]);

  useEffect(() => {
    if (user?.email) {
      history.replace("/");
    }
  }, [user?.email]); //eslint-disable-line

  const formik = useFormik({
    initialValues: { amember_login: "", amember_pass: "", remember_login: "0" },
    onSubmit: () => {
      login(values);
    },
    validate,
    validateOnBlur: true
  });
  const { touched, values, handleSubmit } = formik;

  const handleChange = e => {
    formik.setFieldValue(e.target.name, e.target.value);
  };
  const handleCheckboxChange = () => {
    if (values.remember_login === "1") {
      formik.setFieldValue("remember_login", "0");
    } else {
      formik.setFieldValue("remember_login", "1");
    }
  };
  const handleBlur = fieldName => {
    formik.setFieldTouched(fieldName, true);
  };

  return (
    <div className={cn("login", styles.wrapper, isKeycloack ? styles.wrapperNewLogin : "")}>
      {notes.login && (
        <Notification error>
          {notes.login.map((error, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={index.toString()}>{error}</span>
          ))}
        </Notification>
      )}

      {errors.failLogin && (
        <Notification error close={setError}>
          {errors.failLogin}
        </Notification>
      )}

      <div className="loginWrp">
        <div className="login-banner">
          <Logo className="login-banner__logo" alt="logo" />
          <h1 className="login-banner__title">{isKeycloack ? <>Heritage Web Dashboard</> : <>{t("dashboard_login_metatitle")}</>}</h1>
          {!isKeycloack && <p className="login-banner__content">{t("dashboard_login_subtitle")}</p>}
        </div>
        <form onSubmit={handleSubmit} className={cn("auth-form", isKeycloack ? styles.newForm : "")}>
          {preloaders.login && <OverlayPreloader overlayClassName={styles.overlay} />}

          {isKeycloack ? (
            <Button className="button--blue" type="button" onClick={() => keycloak.login({ redirectUri: `${window.location.origin}/` })}>
              {t("dashboard_login_button")}
            </Button>
          ) : (
            <>
              <Input
                classNameWrap="mb30"
                error={formik.errors.amember_login}
                id="amember_login"
                isInvalid={formik.errors.amember_login && touched.amember_login}
                name="amember_login"
                onBlur={e => handleBlur(e.target.name)}
                onChange={handleChange}
                label={t("dashboard_login_email")}
                required
                value={values.amember_login}
              />

              <Input
                classNameWrap="mb30"
                error={formik.errors.amember_pass}
                id="amember_pass"
                isInvalid={formik.errors.amember_pass && touched.amember_pass}
                name="amember_pass"
                onBlur={e => handleBlur(e.target.name)}
                onChange={handleChange}
                label={t("dashboard_login_password")}
                required
                type="password"
                value={values.amember_pass}
              />

              <Button className="button--blue" type="submit">
                {t("dashboard_login_button")}
              </Button>

              <div className="password-prompt">
                <Checkbox checked={values.remember_login === "1"} onChange={handleCheckboxChange}>
                  {t("dashboard_login_remember")}
                </Checkbox>
                <Link to="/login/forgot-password" className="password-prompt__reset-link">
                  {t("dashboard_login_forgotpassword")}
                </Link>
              </div>
            </>
          )}
          {/*
            <Socials />
          */}
          {/*
          <div className={styles.register}>
            <span className={styles.registerText}>Not registered yet?</span>
            <a
              href="https://login.heritageweb.com/realms/Dashboard/protocol/openid-connect/registrations?client_id=dashboard&response_type=code"
              className="button button--blue-outline text-center"
            >
              {t("dashboard_login_createaccount")}
            </a>
          </div>
          */}
          {/*

          <a
            href="https://login.heritageweb.com/realms/Dashboard/protocol/openid-connect/registrations?client_id=dashboard&response_type=code"
            className="button button--blue-outline text-center"
          >
            {t("dashboard_login_createaccount")}
          </a>
          */}

          <button
            type="button"
            onClick={() => keycloak.login({ redirectUri: `${window.location.origin}/`, action: "register" })}
            className="button button--blue-outline text-center"
          >
            {t("dashboard_login_createaccount")}
          </button>

          {!preloaders.loginMethods && !loginMethods ? undefined : <span className={styles.text}>{t("dashboard_login_or")}</span>}
          <section className={styles.loginMethods}>
            {preloaders.loginMethods && (
              <>
                <OverlayPreloader />
              </>
            )}
          </section>
        </form>
      </div>
    </div>
  );
};
