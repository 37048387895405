import axios from "axios";
import { messages } from "../constants/actionTypes";
import { sortMessagesByDate, getColorsItem } from "../helpers";

export const actions = {
  clearMessageDetail() {
    return {
      type: messages.CLEAR_MESSAGE_DETAIL
    };
  },
  setFilter(filterData) {
    return {
      type: messages.SET_FILTER,
      payload: filterData
    };
  },
  setActivityFilter(isActive) {
    return {
      type: messages.SET_ACTIVITY_FILTER,
      payload: isActive
    };
  },
  setActivityMenu(isActive) {
    return {
      type: messages.SET_ACTIVITY_MENU,
      payload: isActive
    };
  },
  disableSuccess() {
    return {
      type: messages.DISABLE_SUCCESS
    };
  },
  setActiveBlocks(blocks) {
    return {
      type: messages.SET_ACTIVE_BLOCKS,
      payload: blocks
    };
  },

  toggleLoading(section, isLoading) {
    return {
      type: messages.TOGGLE_LOADING,
      payload: { section, isLoading }
    };
  },

  getMessages(messageId, isArchive, team = {}) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value || team?.value ? "/team" : "";
      const userIdParams = currentTeam.value || team?.value ? `?user_id=${currentTeam.value || team.value}` : "";

      dispatch(this.toggleLoading("messages", true));

      axios({
        method: "get",
        responseType: "json",
        url: `/members/json-api${teamUrl}/direct-messages/inbox${userIdParams}`
      })
        .then(result => {
          const { memberships, listing_messages } = result.data;
          const notArchivedMessages = listing_messages?.filter(({ is_archive }) => String(is_archive) === "0");
          const archivedMessages = listing_messages?.filter(({ is_archive }) => String(is_archive) === "1");
          const allMessages = isArchive ? archivedMessages : notArchivedMessages;
          const unreadMessages = allMessages?.filter(message => String(message.is_read) === "0");
          const readMessages = allMessages?.filter(message => String(message.is_read) === "1");

          const normalizeListings = memberships
            ? Object.keys(memberships).map(key => ({
                listing_id: key,
                title: memberships[key]
              }))
            : [];

          const messagesList = [...sortMessagesByDate(unreadMessages), ...sortMessagesByDate(readMessages)];

          const sortedMessageList = messageId
            ? messagesList.sort((a, b) =>
                String(a.listing_message_id) === String(messageId) ? -1 : String(b.listing_message_id) === String(messageId) ? 1 : 0
              )
            : messagesList;

          const colorMessages = getColorsItem(sortedMessageList);

          dispatch({
            type: messages.GET_MESSAGES,
            payload: {
              messages: colorMessages,
              oldCount: result.data?.old,
              newCount: result.data?.new,
              activeListings: normalizeListings
            }
          });
        })
        .catch(() => {
          dispatch({
            type: messages.GET_MESSAGES_FAIL,
            payload: "Something went wrong!"
          });
        });
    };
  },

  getMessageDetail(listingId, emailConversationId, isNotRead) {
    return (dispatch, getState) => {
      const {
        teams: { currentTeam }
      } = getState();
      const teamUrl = currentTeam?.value ? "/team" : "";
      const userIdParams = currentTeam?.value ? { user_id: currentTeam.value } : {};

      dispatch({
        type: messages.GET_MESSAGE_DETAIL_LOADING
      });

      axios
        .all([
          axios({
            method: "get",
            responseType: "json",
            url: `/members/json-api${teamUrl}/direct-messages/inbox`,
            params: {
              listing_message_id: listingId,
              ...userIdParams
            }
          }),
          axios({
            method: "get",
            responseType: "json",
            url: `/members/json-api${teamUrl}/direct-messages/history`,
            params: {
              listing_message_id: listingId,
              ...userIdParams
            }
          })
        ])
        .then(
          axios.spread((messageResult, historyResult) => {
            if (isNotRead) {
              dispatch(this.readMessage(emailConversationId));
            }

            dispatch({
              type: messages.GET_MESSAGE_DETAIL,
              payload: { messageDetail: messageResult.data, messages: historyResult.data.history }
            });
          })
        )
        .catch(() => {
          dispatch(this.toggleLoading("messageDetail", false));
        });
    };
  },

  readMessage(emailConversationId) {
    return dispatch => {
      dispatch(this.toggleLoading("readMessage", true));

      axios({
        method: "post",
        url: "/members/json-api/direct-messages/read",
        data: [+emailConversationId]
      })
        .then(() => {
          /*
          dispatch({ type: messages.READ_MESSAGE, payload: emailConversationId });
          */
          dispatch(this.toggleLoading("readMessage", false));
        })
        .catch(() => {
          dispatch(this.toggleLoading("readMessage", false));
        });
    };
  },

  unreadMessage(emailConversationId) {
    return dispatch => {
      dispatch(this.toggleLoading("unreadMessage", true));

      axios({
        method: "post",
        url: "/members/json-api/direct-messages/unread",
        data: [+emailConversationId]
      })
        .then(() => {
          dispatch({ type: messages.UNREAD_MESSAGE, payload: emailConversationId });
        })
        .catch(() => {
          dispatch(this.toggleLoading("unreadMessage", false));
        });
    };
  },

  archiveMessage(emailConversationId, messageId) {
    return dispatch => {
      dispatch(this.toggleLoading("archiveMessage", false));

      axios({
        method: "post",
        url: "/members/json-api/direct-messages/archive",
        data: [+emailConversationId]
      })
        .then(() => {
          dispatch({ type: messages.ARCHIVE_MESSAGE, payload: emailConversationId });
          dispatch(this.getMessageDetail(messageId, emailConversationId));
        })
        .catch(() => {
          dispatch(this.toggleLoading("archiveMessage", false));
        });
    };
  },
  unArchiveMessage(emailConversationId, messageId) {
    return dispatch => {
      dispatch(this.toggleLoading("unArchiveMessage", false));

      axios({
        method: "post",
        url: "/members/json-api/direct-messages/unarchive",
        data: [+emailConversationId]
      })
        .then(() => {
          dispatch({ type: messages.UN_ARCHIVE_MESSAGE, payload: emailConversationId });
          dispatch(this.getMessageDetail(messageId, emailConversationId));
        })
        .catch(() => {
          dispatch(this.toggleLoading("unArchiveMessage", false));
        });
    };
  },

  sendMessage(messageData, team) {
    return dispatch => {
      dispatch(this.toggleLoading("sendMessage", true));
      const userIdParams = team.value ? { user_id: team.value } : {};
      const teamUrl = team.value ? "/team" : "";

      axios({
        method: "post",
        responseType: "json",
        url: `/members/json-api${teamUrl}/direct-messages/reply-message`,
        data: { ...messageData, ...userIdParams }
      })
        .then(result => {
          dispatch({
            type: messages.SEND_MESSAGE,
            payload: {
              message: { ...result.data.message, lawyer_image: messageData.lawyer_image, is_lawyer: messageData.is_lawyer }
            }
          });
        })
        .catch(err => {
          dispatch({
            type: messages.SEND_MESSAGE_FAIL,
            payload: err.response
          });
        });
    };
  }
};
