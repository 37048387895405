export const ACTIVE_BLOCK_NAMES = {
  MESSAGES: "messages",
  CONVERSATION: "conversation",
  DETAIL: "detail",
  DETAIL_DESKTOP: "detailDesktop"
};

export const TABS = {
  ALL: "all",
  ARCHIVED: "archived"
};
