import React from "react";
import { useTranslation } from "react-i18next";

import { Textarea } from "../textarea";
import styles from "./replyBox.module.css";

export const ReplyBox = React.memo(({ submitFormAnswer }) => {
  const { t } = useTranslation();

  const onEnterPress = (e, callback) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      callback();
    }
  };

  return (
    <>
      <div className="down-line" />
      <div className={styles.replyBox}>
        {/*
        <div className={styles.replyDisclaimer}>
          <span className={styles.disclaimerText}>Legal disclaimer:</span> There are many variations of passages of Lorem Ipsum available,
          but the majority have suffered alteration in some form, by injected humour, or randomised words which don&#39;t look even slightly
          believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn&#39;t anything embarrassing hidden in
          the middle of text.
        </div>
        */}
        <p className={styles.replyTitle}>Your reply</p>
        <Textarea
          buttonText={t("dashboard_qa_single_submitreply")}
          className={styles.textarea}
          onSubmit={submitFormAnswer}
          onKeyDown={onEnterPress}
          reply
        />
      </div>
      <div className="down-line" />
    </>
  );
});
