import React from "react";
import cn from "classnames";
import queryString from "query-string";

import { TABS } from "../../../../constants/messages";
import { Radio } from "../../../common/radio";
import styles from "./menu.module.css";

const Menu = ({ history, getMessages, setIsFirstLoading, team }) => {
  const searchParams = queryString.parse(history.location.search);
  const linkActiveArchive = searchParams.tab === TABS.ARCHIVED ? styles.linkActive : "";
  const linkActiveAll = searchParams.tab === TABS.ALL ? styles.linkActive : "";

  const handleClickArchive = () => {
    history.push({
      pathname: "/inbox",
      search: queryString.stringify({ ...searchParams, tab: "archived" }),
      state: {
        update: true
      }
    });
    getMessages(searchParams.messageId, true, team);
    setIsFirstLoading(true);
  };

  const handleClickAll = () => {
    history.push({
      pathname: "/inbox",
      search: queryString.stringify({ ...searchParams, tab: "all" }),
      state: {
        update: true
      }
    });

    getMessages(searchParams.messageId, false, team);
    setIsFirstLoading(true);
  };

  return (
    <div className={styles.menu}>
      <Radio
        mainClass={styles.indent}
        className={cn("button--clear", styles.link, linkActiveAll)}
        checked={searchParams.tab === TABS.ALL}
        onChange={handleClickAll}
      >
        <span>All Messages</span>
      </Radio>
      <Radio
        mainClass={styles.indent}
        className={cn("button--clear", styles.link, linkActiveArchive)}
        checked={searchParams.tab === TABS.ARCHIVED}
        onChange={handleClickArchive}
      >
        <span>Archive</span>
      </Radio>
    </div>
  );
};

export default Menu;
