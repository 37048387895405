import React from "react";
import { format, isSameDay } from "date-fns";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { parseDateToYearMonthDay } from "../../../../helpers/parseDate";
import { getNameWebsiteFromUrl, isHaveNumber } from "../../../../helpers";
import { ReactComponent as LetterIcon } from "../../../../media/icons/letter.svg";
import { ReactComponent as PhoneIcon } from "../../../../media/icons/phone.svg";
import { ReactComponent as GoToIcon } from "../../../../media/icons/goTo.svg";
import styles from "./detail.module.css";

const Detail = ({ messageDetail }) => {
  const {
    name_full: fullName = "",
    pro_email: proEmail,
    pro_phone: proPhone,
    attorney = "",
    public_url: publicUrl,
    added,
    listing_status: listingStatus,
    listing_id: listingId,
    listing_url: listingUrl,
    updated,
    listings_image_name: listingImageName
  } = messageDetail;

  const currentName = attorney;
  const currentEmail = proEmail;
  const currentPhone = proPhone;
  const dateBetween = added ? parseDateToYearMonthDay(added) : added;
  const isSameDateAdded = added ? isSameDay(new Date(added), new Date()) : false;
  const { t } = useTranslation();

  const renderInitials = name => {
    return name
      .split(" ")
      .slice(0, 2)
      .map(n => n[0])
      .join("");
  };

  return (
    <>
      {fullName && (
        <>
          <div className={styles.nameContent}>
            <div className={styles.initials}>{renderInitials(fullName)}</div>
            <div className={styles.name}>{fullName}</div>
          </div>
          <div className={styles.action}>
            <LetterIcon className={styles.actionIcon} />
            <span className={styles.actionText}>{currentEmail}</span>
          </div>
          {isHaveNumber(currentPhone) && (
            <div className={styles.action}>
              <PhoneIcon className={styles.actionIconPhone} />
              <span className={styles.actionText}>{currentPhone}</span>
            </div>
          )}

          <hr className={styles.hr} />

          <span className={styles.label}>First message received</span>
          {isSameDateAdded ? <span className={styles.added}>Today</span> : <span className={styles.date}>{dateBetween}</span>}
          {added && <span className={styles.added}>{format(new Date(added), "EEEE MMM dd, yyyy")}</span>}

          <hr className={styles.hr} />
          <div className={styles.nameContent}>
            {listingImageName ? (
              <img className={styles.image} src={listingImageName} alt="Attorney name" />
            ) : (
              <div className={styles.initials}>{renderInitials(currentName)}</div>
            )}
            <div>
              <span className={styles.listingDetail}>{currentName}</span>
            </div>
          </div>

          <div className={styles.source}>
            <span className={styles.label}>{t("dashboard_proview_message_source")}:</span>
            <span className={styles.subLabel}>listing contact form on</span>
            {listingUrl && (
              <a href={listingUrl} className={styles.listingUrl} target="_blank" rel="noopener noreferrer">
                {getNameWebsiteFromUrl(listingUrl)}
              </a>
            )}
          </div>

          <span className={styles.label}>Status</span>
          {listingStatus === "Active" ? (
            <a href={listingUrl} className={styles.listingUrl} target="_blank" rel="noopener noreferrer">
              {t("active")}
            </a>
          ) : (
            <span className={styles.status}>
              {listingStatus}
              <a href={publicUrl} target="_blank" rel="noopener noreferrer" className={styles.publicUrl}>
                <GoToIcon className={styles.icon} />
              </a>
            </span>
          )}

          <span className={styles.label}>Listing last updated on</span>
          <span className={styles.lastEditDate}>
            {updated ? format(new Date(updated), "MMM dd, yyyy") : ""}
            <Link to={`/listings/${listingId}`} className={styles.editListing} target="_blank" rel="noopener noreferrer">
              Edit Listing
            </Link>
          </span>
        </>
      )}
    </>
  );
};

export default Detail;
