import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useHistory, NavLink } from "react-router-dom";

import styles from "./tabs.module.css";
import SelectInput from "../../../common/selectInput";

const Tabs = ({ className }) => {
  const [filterType, setFilterType] = useState({});

  const all = { value: "/listings", label: "All" };
  const organizations = { value: "/my-organizations", label: "Organizations" };
  const jobs = { value: "/my-jobs", label: "Jobs" };
  const events = { value: "/my-events", label: "Events" };

  const filterOptions = [all, organizations, jobs, events];

  const history = useHistory();

  const onChangeFilter = value => {
    history.push(value.value);
  };

  useEffect(() => {
    if (filterType.value !== history.location.pathname) {
      const findListingTab = filterOptions.find(item => item.value === history.location.pathname);
      setFilterType(findListingTab);
    }
  }, [history.location.pathname]);

  return (
    <div className={cn(styles.filter, className)}>
      <div className={styles.filterMobile}>
        <span className={styles.label}>Filter by:</span>
        <SelectInput
          classNameWrap={styles.selectWrapper}
          className={styles.select}
          name="country"
          placeholder="Filter By"
          onChange={value => onChangeFilter(value)}
          options={filterOptions}
          value={filterType}
        />
      </div>

      <div className={styles.filterDesktop}>
        {filterOptions.map(item => {
          return (
            <NavLink to={item.value} key={item.value} activeClassName={styles.filterButtonActive} className={styles.filterButton}>
              {item.label}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default Tabs;
