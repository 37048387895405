import React from "react";

import ListingContent from "../listingContent";
import EventsListing from "../eventsListing";
import eventsTestBanner from "../../../media/images/eventsTestBanner.jpg";

const list = [
  {
    title: "ISTQB® Foundation Exam and Training with Designer_1",
    description:
      "We are looking for a UI Designer who would work close with our team at https://kulga.co/ to continue the website design...",
    position: "Chicago, IL",
    time: "18.00 - 23.00 PM (US ET)",
    banner: eventsTestBanner
  },
  {
    title: "ISTQB® Foundation Exam and Training with Designer_2",
    description:
      "We are looking for a UI Designer who would work close with our team at https://kulga.co/ to continue the website design...",
    position: "Chicago, IL",
    time: "18.00 - 23.00 PM (US ET)",
    banner: eventsTestBanner
  },
  {
    title: "ISTQB® Foundation Exam and Training with Designer_3",
    description:
      "We are looking for a UI Designer who would work close with our team at https://kulga.co/ to continue the website design...",
    position: "Chicago, IL",
    time: "18.00 - 23.00 PM (US ET)",
    banner: eventsTestBanner
  }
];

const Events = ({ isExpand, onClick, isActive, handleClickAll }) => {
  return (
    <>
      {isExpand ? (
        <EventsListing isExpand list={list} />
      ) : (
        <ListingContent title="Events" count="122" onClick={onClick} isActive={isActive} handleClickAll={handleClickAll}>
          <EventsListing list={list} />
        </ListingContent>
      )}
    </>
  );
};

export default Events;
