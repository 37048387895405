import { referralRequestDetail } from "../constants/actionTypes";

const defaultModals = {
  matchesInfo: false,
  missingInfo: false,
  question: false,
  message: false,
  successMessage: false,
  questionOpenToOther: false,
  contactInfo: false
};

const defaultState = {
  case: {},
  error: null,
  followups: [],
  matches: [],
  /*
  practices: [],
   */
  modals: {
    ...defaultModals
  },
  categoriesProfessions: {},
  preloader: false,
  history: [],
  messageModalName: "",
  isFailedEmailVerification: false,
  newsletters: {},
  contactInfoData: {}
};

export default function(state = defaultState, action) {
  const { type, payload } = action;
  switch (type) {
    case referralRequestDetail.GET_CASE_SUCCESS:
      return {
        ...state,
        case: payload.lead,
        followups: payload.followups.reduce((total, current) => {
          return total.find(item => item.practice_followup_id === current.practice_followup_id)
            ? total
            : [
                ...total,
                {
                  ...current,
                  answer: payload.followups
                    .filter(elem => elem.practice_followup_id === current.practice_followup_id)
                    .map(item => item.answer)
                }
              ];
        }, []),
        matches: payload.matches,
        history: payload.history,
        newsletters: payload.newsletters,
        preloader: false,
        isFailedEmailVerification: payload.isFailedEmailVerification
      };

    case referralRequestDetail.GET_CASE_PRACTICES:
      return {
        ...state,
        categoriesProfessions: payload
      };

    case referralRequestDetail.GET_CASE_FAIL:
      return {
        ...state,
        preloader: false,
        error: payload
      };

    case referralRequestDetail.SUBMIT_CASE_DATA_SUCCESS:
      return {
        ...state,
        case: payload,
        preloader: false,
        modals: {
          ...defaultModals
        }
      };

    case referralRequestDetail.SUBMIT_CASE_DATA_FAIL:
      return {
        ...state,
        error: payload,
        preloader: false
      };

    case referralRequestDetail.SET_CASE_PRELOADER:
      return {
        ...state,
        preloader: payload
      };

    case referralRequestDetail.UPDATED_FOLLOWUPS:
      return {
        ...state,
        preloader: false,
        modals: {
          ...defaultModals
        }
      };

    case referralRequestDetail.CLEAR_ALL_MODALS:
      return {
        ...state,
        modals: {
          ...defaultModals
        },
        messageModalName: ""
      };

    case referralRequestDetail.TOGGLE_ACTIVITY_OF_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.modal]: payload.isActive
        }
      };

    case referralRequestDetail.SEND_MATCH_MESSAGE:
      return {
        ...state,
        modals: {
          ...state.modals,
          message: false,
          successMessage: true
        },
        preloader: false
      };

    case referralRequestDetail.TOGGLE_ACTIVITY_MESSAGE_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          message: payload.isActive
        },
        messageModalName: payload.name
      };

    case referralRequestDetail.SET_CONTACT_INFO_DATA:
      return {
        ...state,
        contactInfoData: payload
      };

    case referralRequestDetail.CHANGE_CONTACT_INFO:
      return {
        ...state,
        contactInfoData: {},
        modals: {
          ...defaultModals
        },
        preloader: false,
        case: { ...state.case, ...payload }
      };

    case referralRequestDetail.CHANGE_CONTACT_INFO_FAIL:
      return {
        ...state,
        preloader: false
      };

    default:
      return {
        ...state
      };
  }
}
