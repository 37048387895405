import React from "react";
import { useTranslation } from "react-i18next";

import { normalizeFollowupQuestionOptions } from "../../../../helpers";
import TextArea from "../../../common/textarea";
import SelectInput from "../../../common/selectInput";
import DatePickerInput from "../../../common/datePickerInput";
import styles from "./renderQuestions.module.css";

export const RenderQuestions = ({ questions, errors, touched, formik, values, handleBlur }) => {
  const { t } = useTranslation();

  const renderLabel = ({ index, question }) => {
    return (
      <>
        <div className={styles.question}>
          <span className={styles.questionNumber}>{index + 1}.)</span> {question.question}
        </div>

        <div className={styles.myAnswer}>{t("dashboard_referrals_details_my_answer")}</div>
      </>
    );
  };

  if (!questions.length) {
    return null;
  }

  return questions.map((question, index) => {
    if (question.answer_type === "text" || question.answer_type === "integer") {
      return (
        <TextArea
          error={errors?.[index]}
          isInvalid={errors?.[index] && touched?.[index]}
          id={index}
          name={index}
          label={renderLabel({ index, question })}
          onBlur={e => formik.setFieldTouched(e.target.name, true)}
          onChange={e => formik.setFieldValue(e.target.name, { ...values[index], answer: e.target.value })}
          required={!!question.is_required}
          styleProps={{ wrapper: styles.wrapper }}
          value={values[index]?.answer || ""}
          key={question.lead_followup_id}
          className={styles.textArea}
        />
      );
    }

    if (question.answer_type === "date") {
      return (
        <DatePickerInput
          error={errors?.index}
          classNameWrap={styles.wrapperDatePicker}
          disableFuture
          id={`answers.${index}`}
          isInvalid={errors?.[index] && touched?.[index]}
          label={renderLabel({ index, question })}
          name={`answers.${index}`}
          onBlur={e => formik.setFieldTouched(e.target.name, true)}
          onChange={value => formik.setFieldValue(index, { ...values[index], answer: value })}
          onError={error => error && formik.errors?.[index] !== error && formik.setFieldError(index, error)}
          required={!!question.is_required}
          key={question.lead_followup_id}
          value={values[index]?.answer}
          placeholder="YYYY/MM/DD"
        />
      );
    }

    if (question.answer_type === "checkboxes" || question.answer_type === "select") {
      return (
        <SelectInput
          classNameWrap={styles.wrapper}
          getOptionLabel={option => option.label}
          id={`select-answer-${index}`}
          label={renderLabel({ index, question })}
          name={`select-answer-${index}`}
          onBlur={() => handleBlur(`answers[${index}]`)}
          onChange={selection => {
            formik.setFieldValue(index, { ...values[index], answer: selection.answer, label: selection.label });
          }}
          options={normalizeFollowupQuestionOptions(question, true)}
          value={[values[index]]}
          key={question.lead_followup_id}
        />
      );
    }

    return null;
  });
};
