import React, { useEffect, useRef, useState } from "react";

import Input from "../input";
import Dropdown from "../dropdown";
import styles from "./video.module.css";

const Video = ({ introVideo, submitChanges, validation, showRequiredText }) => {
  if (Object.keys(validation).length && !validation.enabled) {
    return null;
  }

  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const containerRef = useRef();

  const [value, setValue] = useState("");
  const isRequired = validation.required;
  const isEmpty = !introVideo.length;
  const isRequiredText = validation.required && isEmpty && showRequiredText;
  const title = validation?.custom_name || "Video Introduction";

  const handleSubmit = () => {
    submitChanges({ intro_video: value }, "intro_video", "save_intro_video");
  };

  const handleChange = e => {
    setValue(e.target.value);

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  useEffect(() => {
    if (introVideo) {
      setValue(introVideo);
    }
  }, []);

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);
      if (isEdited) {
        handleSubmit();
        setIsEdited(false);
      }
    }
  };

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (isTouched) {
          setIsTouched(false);

          if (isEdited) {
            handleSubmit();
            setIsEdited(false);
          }
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [isEdited]);

  return (
    <div ref={containerRef}>
      <Dropdown
        isRequired={isRequired}
        title={title}
        description="Add video about your business and organization"
        isRequiredText={isRequiredText}
        isShowArrow={false}
        isActiveDropDown
        handleMouseMove={handleMouseMove}
        handleMouseLeave={handleMouseLeave}
        handleTouchStart={handleTouchStart}
      >
        <div className={styles.videoItem}>
          <div className={styles.flexItem}>
            <div className={styles.videoContent}>
              <span className={styles.name}>Link to your Youtube video</span>

              <Input
                placeholder="Ex https://youtube.com/introduction-video"
                id="introVideo"
                name="introVideo"
                onChange={handleChange}
                value={value}
                classNameWrap={styles.inputWrap}
                className={styles.input}
              />
            </div>
          </div>
        </div>
      </Dropdown>
    </div>
  );
};
export default Video;
