import React, { useRef } from "react";
import cn from "classnames";

import { ReactComponent as CameraIcon } from "../../../media/icons/photo-listing.svg";
import { ReactComponent as CloseIcon } from "../../../media/icons/close-cross.svg";
import { ReactComponent as PencilIcon } from "../../../media/icons/edit-pencil-small.svg";
import styles from "./detailImages.module.css";

const DetailImages = ({ id, onUpload, validation, images, deleteImage }) => {
  if (Object.keys(validation).length && !validation.enabled) {
    return null;
  }
  const defaultImages = [1, 2, 3, 4, 5];

  const renderImages = [...images, ...defaultImages]
    .slice(0, 5)
    .map(item => <DetailImagesItem key={item} id={id} onUpload={onUpload} image={item} deleteImage={deleteImage} />);
  return (
    <div>
      <h3 className={styles.titleContent}>Detail Image</h3>
      <p className={styles.descriptionContent}>Create image at 1800 x 1360 px. Keep images under 3MB and JPEG if possible.</p>
      <div className={styles.flex}>{renderImages}</div>
    </div>
  );
};

const DetailImagesItem = ({ id, onUpload, image, deleteImage }) => {
  const input = useRef();

  const handleFileInput = e => {
    e.persist();
    const { files } = e.target;

    handleUploadImage(files[0]);
  };

  const handleUploadImage = files => {
    if (files) {
      const formData = new FormData();

      formData.append("listing_id", id);
      formData.append("image_body", files);
      formData.append("command", "additional_image");
      formData.append("is_default", "0");

      onUpload(formData);
    }
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDrop = e => {
    e.preventDefault();

    if (e.dataTransfer) {
      if (e.dataTransfer.items) {
        if (e.dataTransfer.items[0].kind === "file") {
          const file = e.dataTransfer.items[0].getAsFile();

          handleUploadImage(file);
        }
      } else {
        handleUploadImage(e.dataTransfer.files[0]);
      }
    }
  };

  const handleOpenFile = () => {
    input.current.click();
  };

  const handleDeleteImage = event => {
    event.stopPropagation();
    deleteImage(id, image.image_id);
  };

  return (
    <>
      {image.src ? (
        <div className={styles.container}>
          <img src={image.src} alt="cover" />
          <div className={cn(styles.content, styles.contentActive)}>
            <button type="button" className={styles.photoCamera} onClick={handleDeleteImage}>
              <CloseIcon className={styles.closeIcon} />
            </button>
          </div>
        </div>
      ) : (
        <div
          tabIndex={-1}
          role="button"
          className={styles.container}
          onDrop={onDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onClick={handleOpenFile}
        >
          <div className={styles.content}>
            <button type="button" className={styles.photoCamera}>
              <CameraIcon className={styles.cameraIcon} />

              <span className={cn(styles.edit, styles.editCover)}>
                <PencilIcon />
              </span>
            </button>
            <h6 className={styles.title}>Click or Drop Image</h6>
          </div>
        </div>
      )}

      <input
        ref={input}
        accept=".png, .jpg, .jpeg"
        onChange={e => handleFileInput(e)}
        id="upload"
        name="upload"
        className={styles.fileInput}
        type="file"
      />
    </>
  );
};

export default DetailImages;
