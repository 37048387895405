import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./title.module.css";

export const Title = () => {
  const { t } = useTranslation();

  return <h2 className={styles.h2}>{t("dashboard_referrals_editor_details_title")}</h2>;
};
