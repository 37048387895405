import React, { useCallback, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ReactComponent as LogooutIcon } from "../../../../media/icons/logout-main.svg";
import { enableScroll, disableScroll } from "../../../../helpers";
import { actions as teamsActions } from "../../../../actions/teams";
import styles from "./detailNavigation.module.css";

export const DetailNavigation = ({ onCloseHandler, keycloak }) => {
  const { currentTeam } = useSelector(state => state.teams);
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const setCurrentTeam = useCallback(team => dispatch(teamsActions.setCurrentTeam(team)), [dispatch]);
  const history = useHistory();
  const { t } = useTranslation();

  const handleClick = () => {
    if (currentTeam.value) {
      setCurrentTeam({});
    }
  };

  const compareRef = e => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      onCloseHandler();
    }
  };

  useEffect(() => {
    document.addEventListener("click", compareRef);
    return () => {
      document.removeEventListener("click", compareRef);
    };
  });

  useEffect(() => {
    const handleUrlChange = () => {
      onCloseHandler();
    };

    const unlisten = history.listen(handleUrlChange);

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    disableScroll();
    return () => {
      enableScroll();
    };
  }, []);

  return (
    <>
      <nav ref={wrapperRef} className={styles.nav}>
        {/*
        <Link className={styles.link} to="/">
          Home
        </Link>
        */}
        <Link className={styles.link} to="/account" onClick={handleClick}>
          My Account
        </Link>
        <Link className={styles.link} to="/listings?page=0" onClick={handleClick}>
          {t("dashboard_header_nav_listings")}
        </Link>

        <Link className={styles.link} to="/paid-leads?page=0&tab=notpurchased" onClick={handleClick}>
          Business Referrals
        </Link>
        {/*
        <Link className={styles.link} to="/teams" onClick={handleClick}>
          My Teams
        </Link>

        <Link className={styles.link} to="/manage-notification" onClick={handleClick}>
          {t("dashboard_nav_notifications")}
        </Link>

        <Link className={styles.link} to="/analytics" onClick={handleClick}>
          Analytics
        </Link>
        */}
        {/*
        <Link className={styles.link} to="/payments" onClick={handleClick}>
          Payments
        </Link>
        */}

        <hr className={styles.hr} />
        <button
          type="button"
          className={cn(styles.logout, "button--clear")}
          onClick={() => keycloak.logout({ redirectUri: `${window.location.origin}/login` })}
        >
          <LogooutIcon />
          Log Out
        </button>
      </nav>
      <div className={styles.background} />
    </>
  );
};
