import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";

import { NotificationListing } from "../common/notification";
import styles from "./listingDetail.module.css";
import { OverlayPreloader } from "../common/preloader";
import { ListingNotification } from "./listingNotification";
import { actions as listingActions } from "../../actions/listing";
import { actions as paymentActions } from "../../actions/payment";
import { apiKey } from "../../constants/stripe";
import { Modal } from "../common/modal";
import { AddCard } from "../common/payment/addCard";
import EditCard from "../common/payment/editCard";
import { modals as modalsConstant, indexEdit, validationNames, statuses } from "../../constants/listings";
import { getValidationData, getFillingFields } from "../../helpers";
import { Sidebar } from "../common/sidebar";

// modals
import { Phone } from "./modals/phone";
import { Photo } from "./modals/photo";
import { Notifications } from "./modals/notifications";
import { Upgrade } from "../listings/components/modals/upgrade";
import { Payment } from "../listings/components/modals/payment";
import Confirm from "../listings/components/modals/confirm";
import { Unpause } from "../listings/components/modals/unpause";
import { PaymentSuccess } from "../listings/components/modals/paymentSuccess";
import { UnpauseSuccess } from "../listings/components/modals/unpauseSuccess";
import { DowngradeSuccess } from "../listings/components/modals/downgradeSuccess";
import { Downgrade } from "../listings/components/modals/downgrade";
import { DowngradePeriod } from "../listings/components/modals/downgradePeriod";
import { DowngradePeriodSuccess } from "../listings/components/modals/downgradePeriodSuccess";
import { Pause } from "../listings/components/modals/pause";
import { PauseSuccess } from "../listings/components/modals/pauseSuccess";
import { Delete } from "../listings/components/modals/delete";
import { DeleteSuccess } from "../listings/components/modals/deleteSuccess";
// modals end
// components
import ProductControls from "./productControls";
import PageNames from "./pageNames";
import Breadcrumbs from "./breadcrumbs";
import ProductActions from "./productActions";
import ProductDetails from "./productDetails";
import ListingInfo from "./listingInfo";
import ListingInfoEvents from "./listingInfoEvents";
import LanguagesNew from "./languages";
import InsuranceNew from "./incurances";
// import AssociationsNew from "./associations";
import AssociationsNew from "./associationsNew";
import EducationsNew from "./educations";
import LicensesNew from "./licenses";
import LocationsNew from "./locations";
import JobInformation from "./jobInformation";
import JobSkills from "./jobSkills";
import PracticesNew from "./practices";
import SocialsNew from "./socials";
import BioNew from "./bio";
import AboutCompany from "./aboutCompany";
import CalendlyNew from "./calendly";
import Video from "./video";
import Consultation from "./consultation";
import { actions as teamsActions } from "../../actions/teams";
import EventsTickets from "./eventsTickets";
import EventsAudiences from "./eventsAudiences";
import EventsSchedules from "./eventsSchedules";
// end components

const stripePromise = loadStripe(apiKey);

const Membership = ({ match, utilityStates, utilityCountries }) => {
  const [indexToEdit, setIndexToEdit] = useState(indexEdit);
  const [memberInputData, setMemberData] = useState({});
  const [showRequiredText, setShowRequiredText] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const deleteImage = useCallback((listingId, imageId) => dispatch(listingActions.deleteImage(listingId, imageId)), [dispatch]);
  const getMemberData = useCallback(listingId => dispatch(listingActions.getMemberData(listingId, history)), [dispatch, history]);
  const getMemberDataNew = useCallback(listingId => dispatch(listingActions.getMemberDataNew(listingId, history)), [dispatch, history]);
  const getDetailProfilePage = useCallback(() => dispatch(listingActions.getDetailProfilePage(history)), [dispatch, history]);
  const setMembershipClear = useCallback(() => dispatch(listingActions.setMembershipClear()), [dispatch]);
  const getConfig = useCallback(() => dispatch(listingActions.getConfig()), [dispatch]);
  const submitChanges = useCallback(
    (memberData, section, isCloseModals, isReload) =>
      dispatch(listingActions.submitChanges(memberData, section, history, isCloseModals, isReload)),
    [dispatch, history]
  );

  const autoSaveListing = useCallback(
    (memberData, section, command) => dispatch(listingActions.autoSaveListing(memberData, section, history, command)),
    [dispatch, history]
  );

  const submitImages = useCallback(
    (memberData, section, allMemberData) => dispatch(listingActions.submitImages(memberData, section, history, allMemberData)),
    [dispatch, history]
  );

  const uploadImage = useCallback(imageData => dispatch(listingActions.uploadImage(imageData)), [dispatch]);
  const uploadSocialImage = useCallback(imageData => dispatch(listingActions.uploadSocialImage(imageData)), [dispatch]);
  const addCard = useCallback((cardData, isFirst) => dispatch(paymentActions.addCard(cardData, isFirst)), [dispatch]);
  const cleanUpgradeOptions = useCallback(() => dispatch(listingActions.cleanUpgradeOptions()), [dispatch]);
  const clearTokenError = useCallback(() => () => dispatch(paymentActions.clearTokenError()), [dispatch]);
  const chargeForMembership = useCallback(
    (paymentData, paymentType) => dispatch(paymentActions.chargeForMembership(paymentData, paymentType, false, history)),
    [dispatch]
  );
  const downgradeMembership = useCallback(ids => dispatch(listingActions.downgradeMembership(ids, true, false, history)), [dispatch]);
  const unDowngradeMembership = useCallback(ids => dispatch(listingActions.unDowngradeMembership(ids, true, false, history)), [dispatch]);
  const getTimeZones = useCallback(() => dispatch(listingActions.getTimeZones()), [dispatch]);
  const getDirectoriesProfessions = useCallback(() => dispatch(listingActions.getDirectoriesProfessions()), [dispatch]);
  const downgradeMembershipHow = useCallback(ids => dispatch(listingActions.downgradeMembershipHow(ids, true)), [dispatch]);
  const pauseMembership = useCallback(id => dispatch(listingActions.pauseListing(id, false, history, true)), [dispatch]);
  const unpauseMembership = useCallback(id => dispatch(listingActions.unPauseListing(id, true, false, history)), [dispatch]);
  const deleteCard = useCallback(cardToDelete => dispatch(paymentActions.deleteCard(cardToDelete)), [dispatch]);
  const setErrorToken = useCallback(err => dispatch(paymentActions.setErrorToken(err)), [dispatch]);
  const getUpgradeOptions = useCallback((id, coupon) => dispatch(listingActions.getUpgradeOptions(id, coupon)), [dispatch]);
  const updateActiveUpgradeOptions = useCallback(activeOption => dispatch(listingActions.updateActiveUpgradeOptions(activeOption)), [
    dispatch
  ]);
  const getPendingPayment = useCallback(id => dispatch(listingActions.getPendingPayment(id)), [dispatch]);
  const getRenewOptions = useCallback((id, coupon) => dispatch(listingActions.getRenewOptions(id, coupon)), [dispatch]);
  const getPaymentMethods = useCallback(() => dispatch(paymentActions.getPaymentMethods()), [dispatch]);
  const selectCard = useCallback(card => dispatch(paymentActions.selectCard(card)), [dispatch]);
  const updateCard = useCallback(card => dispatch(paymentActions.updateCard(card)), [dispatch]);
  const updateDefaultSource = useCallback(cardId => dispatch(paymentActions.updateDefaultSource(cardId)), [dispatch]);
  const clearPaymentState = useCallback(() => dispatch(paymentActions.clearState()), [dispatch]);
  const publishListing = useCallback(id => dispatch(listingActions.publishListing(id, false, history)), [dispatch]);
  const clearSuccess = useCallback(() => dispatch(listingActions.clearSuccess()), [dispatch]);
  const clearAllModals = useCallback(() => dispatch(listingActions.clearAllModals()), [dispatch]);
  const toggleActivityOfModal = (modal, isActive) => dispatch(listingActions.toggleActivityOfModal(modal, isActive));
  const activeNotificationModal = (modal, data) => dispatch(listingActions.activeNotificationModal(modal, data));
  const clearSavedPaymentData = useCallback(() => dispatch(listingActions.clearSavedPaymentData()), [dispatch]);
  const setSavedPaymentData = useCallback(() => dispatch(listingActions.setSavedPaymentData()), [dispatch]);
  const deleteMembership = useCallback(memberData => dispatch(listingActions.deleteMembership(memberData, false, history, true)), [
    dispatch
  ]);
  const getProfessions = useCallback(() => dispatch(listingActions.getProfessions()), [dispatch]);
  const resetDraft = useCallback(listingId => dispatch(listingActions.resetDraft(listingId, history)), [dispatch]);
  const setCurrentTeam = useCallback(team => dispatch(teamsActions.setCurrentTeam(team)), [dispatch]);
  const isProfilePage = history.location.pathname === "/profile";
  const isOrganizationsPage = history.location.pathname === "/my-organizations";
  const isEventsOrJobsOrOrganizations = ["/my-jobs", "/my-events", "/my-organizations"].some(item =>
    history.location.pathname.includes(item)
  );

  const { id } = match.params;

  const getData = useCallback(() => {
    if (id) {
      if (isEventsOrJobsOrOrganizations) {
        getMemberDataNew(id);
      } else {
        getMemberData(id);
      }
    }
  }, [getMemberData, id, history.location.pathname]);

  const {
    defaultSource,
    preloaders: paymentPreloaders,
    success: paymentSuccess,
    paymentMethods,
    selectedCard,
    error: { stripeTokenError: errorToken, addCard: errorAddCard }
  } = useSelector(state => state.payment);

  const {
    timeZones,
    directoriesProfessions,
    directoryPractices,
    error,
    preloaders,
    photoError,
    success,
    memberData,
    upgradeOptions,
    activeUpgradeOptions,
    showUpgradeOptionsError,
    modals,
    validations,
    insuranceGroups,
    savedPaymentData,
    notificationModalData,
    languagesList,
    practices: practicesList
    // listingConfig
  } = useSelector(state => state.listing);
  useEffect(() => {
    if (!isProfilePage) {
      getData();
      getConfig();
    } else {
      // get profile page
      getDetailProfilePage();
    }

    getTimeZones();
    getDirectoriesProfessions();
    getProfessions();

    return () => {
      setMembershipClear();
      setCurrentTeam({ value: "" });
    };
  }, [history.location.pathname]); // eslint-disable-line

  useEffect(() => {
    setMemberData(memberData);
  }, [memberData]); // eslint-disable-line

  const {
    attorney,
    associations,
    description,
    education,
    lawfirms,
    listing_url: listingUrl,
    licenses,
    languages,
    product_name: productName,
    practices,
    socials,
    title,
    website,
    insurances,
    status,
    listing_id: listingId,
    is_premium,
    is_draft: isDraft,
    parent_id: parentId,
    paused,
    bookmeetings,
    intro_video: introVideo,
    consultation,
    jobs,
    parent_company: parentCompany,
    tags,
    _canceled_to_date: canceledDate,
    change_status: changeStatus,
    publish_status: publishStatus,
    listings_smp_cf_image_url: socialPhoto,
    _profession: listingType,
    listings_image_name: listingsImageName,
    images = []
  } = memberData;
  const isPremium = is_premium === "true" || is_premium === true;

  const { t } = useTranslation();
  const isJobListing = listingType === "Jobs";
  const isEvents = listingType === "Events";
  const isHideSidebar = isProfilePage || isEventsOrJobsOrOrganizations;

  useEffect(() => {
    const getDocumentTitleText = () => {
      if (isEvents) {
        return "Event Listing - Heritage Web Dashboard";
      }

      if (isJobListing) {
        return "Job Listing - Heritage Web Dashboard";
      }

      if (isOrganizationsPage) {
        return "Organization Listing - Heritage Web Dashboard";
      }

      if (isProfilePage) {
        return "Profile Listing - Heritage Web Dashboard";
      }

      return t("dashboard_listings_metatitle");
    };

    document.title = getDocumentTitleText();
  }, []);

  const handleBlur = (formik, fieldName) => formik.setFieldTouched(fieldName, true);

  const handleSubmitListing = (newData, section) => {
    const { _help, validations: validationsDetail, filling_options, ...res } = memberInputData;

    return submitChanges(
      {
        ...res,
        ...newData
      },
      section
    );
  };

  const handleAutoSaveListing = (newData, section, command) => {
    return autoSaveListing({ listing_id: memberInputData.listing_id, ...newData }, section, command);
  };

  const handleSubmitImage = (newData, section) => {
    const { _help, validations: validationsDetail, filling_options, ...res } = memberInputData;

    return submitImages(newData, section, res);
  };

  const handleRemoveListingItem = (collection, identifier, currentId, isIndex) => {
    const newCollection = [...memberInputData[collection]];
    const { _help, validations: validationsDetail, filling_options, ...res } = memberInputData;

    let filterCollection = [];

    if (isIndex) {
      filterCollection = newCollection.filter((item, index) => {
        return String(index) !== String(currentId);
      });
    } else {
      filterCollection = newCollection.filter(item => {
        return item[identifier] !== currentId;
      });
    }

    return submitChanges({
      ...res,
      [collection]: filterCollection
    });
  };

  const openModal = (modalName, editTarget) => {
    if (editTarget) {
      setIndexToEdit({ ...indexToEdit, [modalName]: typeof editTarget !== "string" ? editTarget : editTarget.toString() });
    } else {
      setIndexToEdit(indexEdit);
    }
    toggleActivityOfModal(modalName, true);
  };

  const handleDelete = () => {
    deleteMembership(memberInputData);
  };

  const handleActiveNotificationModal = (modalName, data) => {
    activeNotificationModal(modalName, data);
  };

  const openAddNotificationsModal = modalName => {
    toggleActivityOfModal(modalName, true);
  };

  const normalizeInsurances = () => {
    return insurances?.reduce((total, insurance) => {
      const network = insuranceGroups.networks.find(item => String(insurance.network_id) === String(item.insurance_networks_id));
      const company = insuranceGroups.companies.find(item => String(insurance.company_id) === String(item.insurance_company_id));
      const plan = insuranceGroups.plans.find(item => String(insurance.plan_id) === String(item.insurance_plan_id));

      total.push({
        plan,
        network,
        company,
        other_plan: insurance.other_plan,
        other_company: insurance.other_company,
        other_network: insurance.other_network
      });

      return total;
    }, []);
  };

  const closeModal = modalName => {
    setIndexToEdit({ ...indexToEdit, [modalName]: null });
    setMemberData(memberData);
    toggleActivityOfModal(modalName, false);
  };

  const getOptions = () => {
    if (modals.payPending) {
      return getPendingPayment;
    }
    if (modals.upgrade) {
      return getUpgradeOptions;
    }
    return getRenewOptions;
  };

  const isSuccessNotes = Object.values(success).some(value => !!value);
  const defaultImage = images.filter(item => item.src).find(item => String(item.is_default) === "1");
  const isNotFilledAllFields = getFillingFields({
    validations,
    defaultImage,
    attorney,
    practices,
    languages,
    description,
    website,
    title,
    lawfirms,
    licenses,
    educations: education,
    associations,
    socials,
    selectedCalendly: bookmeetings,
    socialPhoto
  });

  const validationCoverImage = validations.length && getValidationData(validations, validationNames.coverImage).enabled;

  return (
    <>
      <NotificationListing success={isSuccessNotes} onClose={clearSuccess}>
        {t("dashboard_common_saved")}
      </NotificationListing>
      <NotificationListing error={error} onClose={clearSuccess}>
        {error || "Something went wrong"}
      </NotificationListing>
      {(preloaders.memberData || preloaders.deleteMembership || preloaders.downgrade || (isProfilePage && preloaders.profile)) && (
        <OverlayPreloader />
      )}

      {preloaders.memberData !== true && preloaders.memberData !== null && (
        <>
          <div className={cn("wrapper", styles.wrapper, isHideSidebar ? styles.wrapperExpand : "")}>
            <PageNames />
            {!isProfilePage && <Breadcrumbs listingName={attorney} />}
            <div className={styles.flexContent}>
              <div className={styles.container}>
                <div className={cn(styles.content, isHideSidebar ? styles.contentExpand : "")}>
                  <ProductActions
                    setShowRequiredText={setShowRequiredText}
                    showRequiredText={showRequiredText}
                    publishListing={publishListing}
                    isNotFilledAllFields={isNotFilledAllFields}
                    listingId={listingId}
                    isDraft={isDraft}
                    parentId={parentId}
                    resetDraft={resetDraft}
                  />
                  <ProductDetails
                    openModal={openModal}
                    title={productName}
                    url={listingUrl}
                    status={status}
                    paused={paused}
                    isPremium={isPremium}
                    changeStatus={changeStatus}
                    publishStatus={publishStatus}
                    isDraft={isDraft}
                  >
                    <ProductControls
                      listingId={listingId}
                      openModal={openModal}
                      isPendingPayment={statuses.pendingPayment === status}
                      isExpired={status === statuses.expired}
                      publishStatus={publishStatus}
                      isPremium={isPremium}
                      canceledDate={canceledDate}
                      paused={paused}
                      isProfilePage={isProfilePage}
                    />
                  </ProductDetails>

                  {validationCoverImage ? (
                    <ListingInfoEvents
                      defaultImage={defaultImage}
                      onClick={() => openModal(modalsConstant.photo)}
                      showRequiredText={showRequiredText}
                      validationName={getValidationData(validations, validationNames.name)}
                      validationBusiness={getValidationData(validations, validationNames.business)}
                      validationWebsite={getValidationData(validations, validationNames.website)}
                      validationPhoto={getValidationData(validations, validationNames.pictures)}
                      attorney={attorney}
                      title={title}
                      website={website}
                      submitChanges={handleAutoSaveListing}
                      handleSubmitImage={handleSubmitImage}
                      isActiveModalPhoto={modals.socialPhoto}
                      socialPhoto={socialPhoto}
                      validationCoverImage={getValidationData(validations, validationNames.coverImage)}
                      validationAdditionalImages={getValidationData(validations, validationNames.additionalImages)}
                      uploadSocialImage={uploadSocialImage}
                      id={listingId}
                      socialImagePreloaders={preloaders.socialImage}
                      images={images}
                      uploadImage={uploadImage}
                      deleteImage={deleteImage}
                      done
                    />
                  ) : (
                    <ListingInfo
                      defaultImage={defaultImage}
                      onClick={() => openModal(modalsConstant.photo)}
                      showRequiredText={showRequiredText}
                      validationName={getValidationData(validations, validationNames.name)}
                      validationBusiness={getValidationData(validations, validationNames.business)}
                      validationWebsite={getValidationData(validations, validationNames.website)}
                      validationPhoto={getValidationData(validations, validationNames.pictures)}
                      attorney={attorney}
                      title={title}
                      website={website}
                      submitChanges={handleAutoSaveListing}
                      handleSubmitImage={handleSubmitImage}
                      isActiveModalPhoto={modals.photo}
                      isJobListing={isJobListing}
                      listingsImageName={listingsImageName}
                      images={images}
                      uploadImage={uploadImage}
                      id={listingId}
                      deleteImage={deleteImage}
                      done
                    />
                  )}

                  <>
                    <BioNew
                      description={description}
                      validation={getValidationData(validations, validationNames.bio)}
                      showRequiredText={showRequiredText}
                      // submitChanges={handleSubmitListing}
                      submitChanges={handleAutoSaveListing}
                      isEvents={isEvents}
                      isJobListing={isJobListing}
                      done
                    />
                    <JobInformation
                      // submitChanges={handleSubmitListing}
                      submitChanges={handleAutoSaveListing}
                      showRequiredText={showRequiredText}
                      states={utilityStates}
                      countries={utilityCountries}
                      jobs={jobs && jobs.length ? jobs[0] : {}}
                      validation={getValidationData(validations, validationNames.job)}
                      done
                    />
                    <PracticesNew
                      // submitChanges={handleSubmitListing}
                      submitChanges={handleAutoSaveListing}
                      handleRemove={handleRemoveListingItem}
                      selectedLicenses={memberData.practices}
                      validation={getValidationData(validations, validationNames.specialties)}
                      selectedPractices={practices}
                      directoryPractices={directoryPractices}
                      showRequiredText={showRequiredText}
                      isSingleField={isEvents || isJobListing}
                      professions={practicesList.find(item => item.title.includes(listingType)) || {}}
                      done
                    />
                    {/* For Event start */}
                    <EventsSchedules
                      // submitChanges={handleSubmitListing}
                      submitChanges={handleAutoSaveListing}
                      handleRemove={handleRemoveListingItem}
                      validation={getValidationData(validations, validationNames.events)}
                      selectedEvents={memberData.event_schedules || []}
                      showRequiredText={showRequiredText}
                      timeZones={timeZones}
                      done
                    />
                    <EventsTickets
                      // submitChanges={handleSubmitListing}
                      submitChanges={handleAutoSaveListing}
                      handleRemove={handleRemoveListingItem}
                      selectedEvents={memberData.event_tickets || []}
                      validation={getValidationData(validations, validationNames.events)}
                      showRequiredText={showRequiredText}
                      done
                    />
                    {/* For Event end */}
                    <LanguagesNew
                      // submitChanges={handleSubmitListing}
                      submitChanges={handleAutoSaveListing}
                      handleRemove={handleRemoveListingItem}
                      selectedLanguages={languages}
                      languagesList={languagesList}
                      defaultImage={defaultImage}
                      validation={getValidationData(validations, validationNames.language)}
                      attorney={attorney}
                      showRequiredText={showRequiredText}
                      done
                    />
                    <JobSkills
                      // submitChanges={handleSubmitListing}
                      submitChanges={handleAutoSaveListing}
                      handleRemove={handleRemoveListingItem}
                      selectedTags={tags}
                      validation={getValidationData(validations, validationNames.tags)}
                      done
                    />
                    <LicensesNew
                      // submitChanges={handleSubmitListing}
                      submitChanges={handleAutoSaveListing}
                      selectedLicenses={memberData.licenses}
                      states={utilityStates}
                      countries={utilityCountries}
                      validation={getValidationData(validations, validationNames.licenses)}
                      showRequiredText={showRequiredText}
                      done
                    />
                    <InsuranceNew
                      // submitChanges={handleSubmitListing}
                      submitChanges={handleAutoSaveListing}
                      insurances={normalizeInsurances()}
                      insuranceGroups={insuranceGroups}
                      selectedInsurances={memberData.insurances}
                      validation={getValidationData(validations, validationNames.insurance)}
                      showRequiredText={showRequiredText}
                      done
                    />
                    <EducationsNew
                      submitChanges={handleAutoSaveListing}
                      selectedEducations={education}
                      validation={getValidationData(validations, validationNames.educations)}
                      showRequiredText={showRequiredText}
                      done
                    />

                    <AssociationsNew
                      submitChanges={handleAutoSaveListing}
                      selectedAssociations={memberData.associations}
                      validation={getValidationData(validations, validationNames.associations)}
                      showRequiredText={showRequiredText}
                      done
                    />
                    {/* Communication || Community Affiliations */}
                    <EventsAudiences
                      submitChanges={handleAutoSaveListing}
                      selectedEvents={memberData.communities || []}
                      validation={getValidationData(validations, validationNames.communities)}
                      showRequiredText={showRequiredText}
                      directoriesProfessions={directoriesProfessions}
                      done
                    />
                    <LocationsNew
                      submitChanges={handleAutoSaveListing}
                      selectedLocations={lawfirms}
                      states={utilityStates}
                      countries={utilityCountries}
                      validation={getValidationData(validations, validationNames.locations)}
                      showRequiredText={showRequiredText}
                      done
                    />
                    <CalendlyNew
                      selectedCalendly={bookmeetings}
                      submitChanges={handleAutoSaveListing}
                      validation={getValidationData(validations, validationNames.calendar)}
                      showRequiredText={showRequiredText}
                      done
                    />
                    <Consultation
                      consultation={consultation}
                      submitChanges={handleAutoSaveListing}
                      validation={getValidationData(validations, validationNames.freeConsultation)}
                      showRequiredText={showRequiredText}
                      done
                    />
                    <Video
                      introVideo={introVideo}
                      // submitChanges={handleSubmitListing}
                      submitChanges={handleAutoSaveListing}
                      validation={getValidationData(validations, validationNames.video)}
                      showRequiredText={showRequiredText}
                      done
                    />

                    <AboutCompany
                      // submitChanges={handleSubmitListing}
                      submitChanges={handleAutoSaveListing}
                      states={utilityStates}
                      countries={utilityCountries}
                      showRequiredText={showRequiredText}
                      parentCompany={parentCompany && parentCompany.length ? parentCompany[0] : {}}
                      validation={getValidationData(validations, validationNames.parentOrganization)}
                      done
                    />

                    <SocialsNew
                      submitChanges={handleAutoSaveListing}
                      handleRemove={handleRemoveListingItem}
                      selectedSocials={socials}
                      validation={getValidationData(validations, validationNames.socialMedia)}
                      validationYelp={getValidationData(validations, validationNames.yelp)}
                      validationLinkedinCompany={getValidationData(validations, validationNames.linkedInCompany)}
                      validationLinkedInProfile={getValidationData(validations, validationNames.linkedInProfile)}
                      showRequiredText={showRequiredText}
                    />
                    <ListingNotification
                      memberData={memberData}
                      openModal={openAddNotificationsModal}
                      handleActiveNotificationModal={handleActiveNotificationModal}
                    />
                  </>

                  <ProductActions
                    isBottomPosition
                    setShowRequiredText={setShowRequiredText}
                    publishListing={publishListing}
                    isNotFilledAllFields={isNotFilledAllFields}
                    listingId={listingId}
                    isDraft={isDraft}
                    parentId={parentId}
                    resetDraft={resetDraft}
                  />
                </div>
              </div>
              {!isHideSidebar && <Sidebar className={styles.sidebar} />}
            </div>
          </div>
        </>
      )}

      <>
        <Elements stripe={stripePromise}>
          <>
            {modals.addCard && (
              <Modal className="zIndex4" closeModal={() => closeModal(modalsConstant.addCard)} clearAllModals={clearAllModals}>
                <AddCard
                  errorAddCard={errorAddCard}
                  addCard={addCard}
                  clearTokenError={clearTokenError}
                  countries={utilityCountries}
                  closeModal={() => closeModal(modalsConstant.addCard)}
                  errorToken={errorToken}
                  isFirst={!paymentMethods?.length}
                  setErrorToken={setErrorToken}
                  paymentPreloaders={paymentPreloaders}
                  paymentSuccess={paymentSuccess}
                />
              </Modal>
            )}

            {selectedCard && modals.editCard && (
              <Modal className="zIndex4" closeModal={() => closeModal(modalsConstant.editCard)} clearAllModals={clearAllModals}>
                <EditCard
                  closeModal={() => closeModal(modalsConstant.editCard)}
                  countries={utilityCountries}
                  currentCardDetails={selectedCard}
                  paymentPreloaders={paymentPreloaders}
                  paymentSuccess={paymentSuccess}
                  updateCard={updateCard}
                />
              </Modal>
            )}
          </>
        </Elements>

        {(modals.upgrade || modals.payPending || modals.renew) && (
          <Upgrade
            cleanUpgradeOptions={cleanUpgradeOptions}
            itemToEdit={memberData}
            modals={modals}
            preloaders={preloaders}
            getUpgradeOptions={getOptions()}
            getRenewOptions={getRenewOptions}
            activeUpgradeOptions={activeUpgradeOptions}
            updateActiveUpgradeOptions={updateActiveUpgradeOptions}
            showUpgradeOptionsError={showUpgradeOptionsError}
            setItemToEdit={() => {}}
            upgradeOptions={upgradeOptions}
            clearAllModals={clearAllModals}
            closeModal={closeModal}
            openModal={openModal}
            savedPaymentData={savedPaymentData}
            clearSavedPaymentData={clearSavedPaymentData}
            setSavedPaymentData={setSavedPaymentData}
          />
        )}

        {modals.payment && (
          <Payment
            addCard={addCard}
            countries={utilityCountries}
            chargeForMembership={chargeForMembership}
            deleteCard={deleteCard}
            defaultSource={defaultSource}
            getPaymentMethods={getPaymentMethods}
            itemToEdit={memberData}
            modals={modals}
            paymentMethods={paymentMethods}
            paymentPreloaders={paymentPreloaders}
            closeModal={() => closeModal(modalsConstant.payment)}
            activeUpgradeOptions={activeUpgradeOptions}
            selectedCard={selectedCard}
            selectCard={selectCard}
            updateDefaultSource={updateDefaultSource}
            updateCard={updateCard}
            clearAllModals={clearAllModals}
            openModal={openModal}
            savedPaymentData={savedPaymentData}
            clearSavedPaymentData={clearSavedPaymentData}
            isPendingPayment={statuses.pendingPayment === status}
            isExpired={statuses.expired === status}
          />
        )}

        {modals.confirm && (
          <Confirm
            openModal={openModal}
            closeModal={() => closeModal(modalsConstant.confirm)}
            clearAllModals={clearAllModals}
            activeUpgradeOptions={activeUpgradeOptions}
            modals={modals}
            chargeForMembership={chargeForMembership}
            itemToEdit={memberData}
            selectedCard={selectedCard}
            defaultSource={defaultSource}
            paymentPreloaders={paymentPreloaders}
            clearSavedPaymentData={clearSavedPaymentData}
          />
        )}

        {modals.downgradeSuccess && <DowngradeSuccess closeModal={clearAllModals} clearAllModals={clearAllModals} />}

        {modals.downgrade && (
          <Downgrade
            closeModal={clearAllModals}
            clearAllModals={clearAllModals}
            itemToEdit={memberData}
            preloadersDowngradeMembership={preloaders.downgrade}
            downgradeMembership={downgradeMembership}
          />
        )}

        {modals.paymentSuccess && (
          <PaymentSuccess
            closeModal={() => closeModal(modalsConstant.paymentSuccess)}
            clearPaymentState={clearPaymentState}
            getListing={() => getMemberData(id)}
            modals={modals}
            activeUpgradeOptions={activeUpgradeOptions}
            setMembershipClear={setMembershipClear}
            updateActiveUpgradeOptions={updateActiveUpgradeOptions}
            clearAllModals={clearAllModals}
            clearSavedPaymentData={clearSavedPaymentData}
          />
        )}

        {(modals.photo || modals.socialPhoto) && (
          <Photo
            closeModal={clearAllModals}
            deleteImage={deleteImage}
            id={listingId}
            images={images}
            loading={preloaders.photo}
            photoError={photoError}
            uploadImage={uploadImage}
            uploadSocialImage={uploadSocialImage}
            clearAllModals={clearAllModals}
            validation={getValidationData(validations, validationNames.pictures)}
            validationSocialPhoto={getValidationData(validations, validationNames.coverImage)}
            isSocialPhoto={modals.socialPhoto}
            socialPhoto={socialPhoto}
            handleSubmitListing={handleSubmitListing}
            listingsImageName={listingsImageName}
          />
        )}

        {modals.phone && (
          <Phone
            closeModal={() => closeModal(modalsConstant.phone)}
            handleBlur={handleBlur}
            loading={preloaders.modal}
            memberInputData={memberInputData}
            submitChanges={submitChanges}
            success={success}
            clearAllModals={clearAllModals}
          />
        )}

        {modals.unpauseSuccess && <UnpauseSuccess closeModal={clearAllModals} clearAllModals={clearAllModals} />}

        {modals.unpause && (
          <Unpause
            closeModal={clearAllModals}
            clearAllModals={clearAllModals}
            itemToEdit={memberInputData}
            handleUnpause={unpauseMembership}
            preloadersUnpauseMembership={preloaders.unpause}
          />
        )}

        {modals.deleteSuccess && <DeleteSuccess closeModal={clearAllModals} clearAllModals={clearAllModals} />}

        {modals.delete && (
          <Delete
            closeModal={clearAllModals}
            clearAllModals={clearAllModals}
            itemToEdit={memberInputData}
            handleDelete={handleDelete}
            preloadersDeleteMembership={preloaders.deleteMembership}
            handlePause={() => openModal(modalsConstant.pause)}
          />
        )}

        {modals.pause && (
          <Pause
            closeModal={clearAllModals}
            clearAllModals={clearAllModals}
            itemToEdit={memberInputData}
            handlePause={pauseMembership}
            preloadersPauseMembership={preloaders.pause}
          />
        )}
        {modals.pauseSuccess && <PauseSuccess closeModal={clearAllModals} clearAllModals={clearAllModals} />}

        {modals.downgradePeriodSuccess && <DowngradePeriodSuccess closeModal={clearAllModals} clearAllModals={clearAllModals} />}
        {modals.downgradePeriod && (
          <DowngradePeriod
            closeModal={clearAllModals}
            clearAllModals={clearAllModals}
            itemToEdit={memberInputData}
            preloaders={preloaders.downgrade}
            unDowngradeMembership={unDowngradeMembership}
            downgradeMembershipHow={downgradeMembershipHow}
          />
        )}

        <Notifications
          modals={modals}
          clearAllModals={clearAllModals}
          memberData={memberData}
          notificationModalData={notificationModalData}
        />
      </>
    </>
  );
};

export default Membership;
