import React, { useState, useEffect, useMemo } from "react";
import cn from "classnames";
import { ReactComponent as SearchIcon } from "../../../media/icons/search.svg";
import { ReactComponent as Arrow } from "../../../media/icons/arrow-grey.svg";
import { Modal } from "../../common/modal";
import styles from "./practicesModal.module.css";

const PracticesModal = ({ closeModal, profession, handleSelectPractice }) => {
  const [searchPracticesValue, setSearchPracticesValue] = useState("");
  const [parentPractices, setParentPractices] = useState([]);
  const [childPractices, setChildPractices] = useState({});
  const [searchPractices, setSearchPractices] = useState([]);
  const [countParent, setCountParents] = useState(0);
  const list = [];

  const flattenChildren = arr => {
    return arr.reduce((acc, node) => {
      if (node.parent_id) {
        acc.push(node);
      }

      if (node.children && node.children.length > 0) {
        acc.push(...flattenChildren(node.children));
      }

      return acc;
    }, []);
  };

  const flattenedArray = useMemo(() => flattenChildren(profession.children), [profession.children]);

  const handleSearchPractices = value => {
    const findPractices = value
      ? flattenedArray.filter(
          item => item.title.toLowerCase().includes(value.toLowerCase()) || item.parent_title.toLowerCase().includes(value.toLowerCase())
        )
      : [];

    setSearchPractices(findPractices);
    setSearchPracticesValue(value);
  };

  useEffect(() => {
    if (profession.children) {
      setParentPractices(profession);
    }
  }, [profession]);

  const handleSelect = item => {
    if (item.children.length) {
      setChildPractices(item);
    } else {
      handleSelectPractice(item.practice_id);
    }
  };

  useEffect(() => {
    if (searchPractices.length || childPractices.children?.length) {
      const parentPractice = document.getElementsByClassName("parent_practice_title");
      setCountParents(parentPractice.length);
    } else if (countParent) {
      setCountParents(0);
    }
  }, [searchPractices.length, childPractices.children?.length]);

  return (
    <Modal closeModal={closeModal} clearAllModals={closeModal} classNameMain={styles.modal}>
      {searchPracticesValue ? (
        <>
          <div className={cn(styles.inputWrap, styles.inputWrapModal)}>
            <SearchIcon className={styles.searchIcon} />
            <input
              type="text"
              className={cn(styles.input, styles.inputModal)}
              placeholder="Search by Practices"
              value={searchPracticesValue}
              onChange={event => handleSearchPractices(event.target.value)}
            />
          </div>

          {searchPractices.length ? (
            <div className={styles.categories}>
              <div
                className={cn(
                  styles.category,
                  styles.categoryListing,
                  countParent >= 28 ? styles.categoryListingColumn : styles.categoryListingSearchContent
                )}
              >
                {searchPractices.map(item => {
                  list.push(item.parent_title);

                  if (item.parent_parent_title) {
                    list.push(item.parent_parent_title);
                  }

                  const renderParentTitle = () => {
                    if (
                      list.filter(listItem => listItem === item.parent_title).length === 1 &&
                      item.parent_practice_id
                      // !searchPractices.find(itemPractice => itemPractice.parent_title === item.title)
                    ) {
                      return (
                        <>
                          {item.parent_parent_title &&
                          item.parent_parent_title !== item.parent_title &&
                          list.filter(listItem => listItem === item.parent_parent_title).length === 1 ? (
                            <button
                              key={`${item.parent_parent_title}-parent_parent_practice_id=${item.parent_parent_practice_id}`}
                              type="button"
                              className={cn(styles.categoryItem, styles.categoryItemDisable, "parent_practice_title")}
                              onClick={() => handleSelectPractice(item.parent_parent_practice_id)}
                            >
                              {item.parent_parent_title}
                            </button>
                          ) : null}

                          <button
                            key={`${item.parent_title}-parent_practice_id=${item.parent_practice_id}`}
                            type="button"
                            className={cn(styles.categoryItem, styles.categoryItemDisable, "parent_practice_title")}
                            onClick={() => handleSelectPractice(item.parent_practice_id)}
                          >
                            {item.parent_title}
                          </button>
                        </>
                      );
                    }
                    return null;
                  };

                  return (
                    <>
                      {renderParentTitle()}

                      {!searchPractices.find(itemPractice => itemPractice.parent_title === item.title) ? (
                        <button
                          key={`${item.title}-practice_children_${item.practice_id}`}
                          type="button"
                          className={styles.categoryItem}
                          onClick={() => handleSelectPractice(item.practice_id)}
                        >
                          {item.title}
                        </button>
                      ) : null}
                    </>
                  );
                })}
              </div>
            </div>
          ) : (
            "Oops! No practices matched your search. Maybe try using different terms?"
          )}
        </>
      ) : (
        <>
          {!childPractices.title ? (
            <>
              <div className={cn(styles.inputWrap, styles.inputWrapModal)}>
                <SearchIcon className={styles.searchIcon} />
                <input
                  type="text"
                  className={cn(styles.input, styles.inputModal)}
                  placeholder="Search by Practices"
                  value={searchPracticesValue}
                  onChange={event => handleSearchPractices(event.target.value)}
                />
              </div>
              <div className={styles.categories}>
                <div className={cn(styles.category, styles.categoryListing, styles.categoryListingColumn)}>
                  {parentPractices?.children?.map(item => {
                    return (
                      <button key={item.title} type="button" className={styles.categoryItem} onClick={() => handleSelect(item)}>
                        {item.title}
                      </button>
                    );
                  })}
                </div>
              </div>
            </>
          ) : (
            <div>
              <div className={styles.heading}>
                <button type="button" className={styles.viewAll} onClick={() => setChildPractices({})}>
                  <Arrow />
                  {childPractices.title}
                </button>
                <button type="button" className={styles.selectParent} onClick={() => handleSelectPractice(childPractices.practice_id)}>
                  Select {childPractices.title}
                </button>
              </div>
              <div className={styles.categories}>
                <div
                  className={cn(
                    styles.category,
                    styles.categoryListing,
                    countParent >= 30 ? styles.categoryListingColumn : styles.categoryListingSearch
                  )}
                >
                  {childPractices.children.map(item => {
                    return (
                      <>
                        <button
                          key={item.title}
                          type="button"
                          className={cn(styles.categoryItem, styles.categoryItemBold, "parent_practice_title")}
                          onClick={() => handleSelectPractice(item.practice_id)}
                        >
                          {item.title}
                        </button>
                        {item.children
                          ? item.children.map(itemChildren => (
                              <>
                                <button
                                  key={itemChildren.title}
                                  type="button"
                                  className={cn(styles.categoryItem, "parent_practice_title")}
                                  onClick={() => handleSelectPractice(itemChildren.practice_id)}
                                >
                                  {itemChildren.title}
                                </button>

                                {itemChildren.children
                                  ? itemChildren.children.map(subChildren => (
                                      <>
                                        <button
                                          key={subChildren.title}
                                          type="button"
                                          className={styles.categoryItem}
                                          onClick={() => handleSelectPractice(subChildren.practice_id)}
                                        >
                                          {subChildren.title}
                                        </button>
                                      </>
                                    ))
                                  : null}
                              </>
                            ))
                          : null}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default PracticesModal;
