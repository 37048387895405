import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { ReactComponent as EmptyIcon } from "../../../../media/icons/empty-listings.svg";
import { actions as mainActions } from "../../../../actions/main";
import styles from "./emptyState.module.css";

export const EmptyState = () => {
  const dispatch = useDispatch();

  const toggleNewListing = useCallback(isActive => dispatch(mainActions.toggleNewListing(isActive)), [dispatch]);
  const handleOpenNewListing = () => {
    toggleNewListing(true);
  };

  return (
    <div className={styles.wrapper}>
      <EmptyIcon className={styles.icon} />

      <button type="button" onClick={handleOpenNewListing} className={styles.button}>
        Publish Your Listing
      </button>
    </div>
  );
};
