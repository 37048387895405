import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { UserInfo } from "./userInfo";
import { Info } from "./info";
import { Description } from "./description";
import { OverlayPreloader } from "../common/preloader";
import { Notification } from "../common/notification";
import { useCallAllHooks } from "./hooks";
import { priceFormatter } from "../../helpers";
import { getPracticeAreas } from "../../helpers/getPracticeAreas";
import { useDocTitle } from "../../helpers/hooks";
import { Purchase } from "./purchase";
import { AlreadyPurchased } from "./alreadyPurchased";
import { AddCardComponent } from "./modals/addCard";
import { Payment } from "./modals/payment";
import { Success } from "./modals/success";
import { Confirm } from "./modals/confirm";
import { EditCardComponent } from "./modals/editCard";
import { FollowUpQuestions } from "./followUpQuestions";
import styles from "./payPerLeadDetail.module.css";
import { Sidebar } from "../common/sidebar";

const PayPerLeadDetail = ({ match }) => {
  const { t } = useTranslation();

  const { error, lead, loading, success, coupon, practices, practicesLoading } = useSelector(state => state.payPerLeadDetail);
  const { modals } = useSelector(state => state.payPerLeadDetail);

  const {
    added,
    city,
    email,
    lead_id,
    state,
    summary,
    name,
    practice1,
    practice2,
    price,
    phone,
    practice3,
    purchased,
    website_url,
    isPurchasedByTheSameProfessional,
    open_to_other_professionals: openToOtherProfessionals
  } = lead;

  const currentPrice = coupon.code ? coupon.price : price;
  const practicesTitle = getPracticeAreas([practice1, practice2, practice3], practices);

  const { successNotification, errorNotification } = useCallAllHooks({
    success,
    lead_id,
    error,
    match
  });

  const renderPurchase = isShowDescription => {
    if (purchased) {
      return null;
    }

    return <Purchase price={price} loading={loading} leadId={lead_id} isShowDescription={isShowDescription} />;
  };

  useDocTitle("dashboard_ppl_metatitle");

  return (
    <>
      {successNotification && <Notification success>{successNotification}</Notification>}
      {errorNotification && <Notification error>{errorNotification}</Notification>}
      <AddCardComponent />
      <EditCardComponent />
      {modals.payment && <Payment price={priceFormatter.format(price || 0)} leadId={lead_id} />}
      {modals.confirm && (
        <Confirm
          price={priceFormatter.format(price || 0)}
          leadId={lead_id}
          description={summary}
          currentPrice={priceFormatter.format(currentPrice || 0)}
          lead={{ ...lead, price: priceFormatter.format(currentPrice || 0), practicesTitle }}
        />
      )}
      {modals.success && <Success price={priceFormatter.format(currentPrice || 0)} leadId={lead_id} />}

      <div className={styles.mainBox}>
        {/* {!preloaders.getGlobalInfo && <InfoBlockSection pplStatus={pplStatus} />} */}

        <div className={styles.max}>
          <h1 className={styles.title}>{t("dashboard_single_lead_h1")}</h1>
          <div className={styles.lead}>
            {renderPurchase(true)}
            {purchased && !isPurchasedByTheSameProfessional && <AlreadyPurchased />}
            {(loading || practicesLoading) && <OverlayPreloader overlayClassName="sectionOverlay" />}
            <UserInfo email={email} name={name} phone={phone} added={added} leadId={lead_id} />

            <Info
              state={state}
              city={city}
              practicesTitle={practicesTitle}
              websiteUrl={website_url}
              openToOtherProfessionals={openToOtherProfessionals}
            />

            <Description summary={summary} />
            <FollowUpQuestions />
            {renderPurchase()}
          </div>
        </div>
        <Sidebar className={styles.sidebar} />
      </div>
    </>
  );
};

export default PayPerLeadDetail;
