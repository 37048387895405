import React from "react";
import cn from "classnames";

import { useTranslation } from "react-i18next";
import styles from "./resetAllFilters.module.css";

export const ResetAllFilters = React.memo(({ setActiveFilters }) => {
  const { t } = useTranslation();

  return (
    <button type="button" className={cn("button--clear", styles.filter)} onClick={setActiveFilters}>
      {t("dashboard_qa_all_resetfilter")}
    </button>
  );
});
