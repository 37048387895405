import React, { useRef } from "react";
import cn from "classnames";

import styles from "./coverImage.module.css";
import { ReactComponent as CameraIcon } from "../../../media/icons/photo-listing.svg";
import { ReactComponent as PencilIcon } from "../../../media/icons/edit-pencil-small.svg";
// import { createImageDeliveryUrl } from "../../../helpers";

const CoverImage = ({ id, onUpload, image }) => {
  const input = useRef();

  const handleFileInput = e => {
    e.persist();
    const { files } = e.target;

    handleUploadImage(files[0]);
  };

  const handleUploadImage = files => {
    if (files) {
      const formData = new FormData();

      formData.append("image_body", files);
      formData.append("listing_id", id);
      formData.append("command", "upload_social_media_picture");

      onUpload(formData);
    }
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDrop = e => {
    e.preventDefault();

    if (e.dataTransfer) {
      if (e.dataTransfer.items) {
        if (e.dataTransfer.items[0].kind === "file") {
          const file = e.dataTransfer.items[0].getAsFile();

          handleUploadImage(file);
        }
      } else {
        handleUploadImage(e.dataTransfer.files[0]);
      }
    }
  };

  const handleOpenFile = () => {
    input.current.click();
  };

  return (
    <>
      <h5 className={styles.mainTitle}>Main Image</h5>
      <div
        tabIndex={-1}
        role="button"
        className={styles.container}
        onDrop={onDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onClick={handleOpenFile}
      >
        {/* createImageDeliveryUrl() */}
        {image ? <img src={image} alt="cover" /> : null}

        {image ? (
          <div className={cn(styles.content, styles.contentActive)}>
            <button type="button" className={styles.photoCamera}>
              <PencilIcon className={styles.cameraIcon} />
            </button>

            <h6 className={styles.title}>Edit images</h6>
          </div>
        ) : (
          <div className={styles.content}>
            <button type="button" className={styles.photoCamera}>
              <CameraIcon className={styles.cameraIcon} />

              <span className={cn(styles.edit, styles.editCover)}>
                <PencilIcon />
              </span>
            </button>

            <h6 className={styles.title}>Click or Drop Image</h6>
            <p className={styles.description}>Create image at 1800 x 1360 px. Keep images under 3MB and JPEG if possible.</p>
          </div>
        )}

        <div className={cn(styles.content, image ? styles.contentActive : "")}>
          <button type="button" className={styles.photoCamera}>
            {image ? <PencilIcon className={styles.cameraIcon} /> : <CameraIcon className={styles.cameraIcon} />}

            {!image && (
              <span className={cn(styles.edit, styles.editCover)}>
                <PencilIcon />
              </span>
            )}
          </button>

          <h6 className={styles.title}>{image ? "Edit images" : "Click or Drop Image"}</h6>
          {!image && <p className={styles.description}>Create image at 1800 x 1360 px. Keep images under 3MB and JPEG if possible.</p>}
        </div>
      </div>

      <input
        ref={input}
        accept=".png, .jpg, .jpeg"
        onChange={e => handleFileInput(e)}
        id="upload"
        name="upload"
        className={styles.fileInput}
        type="file"
      />
    </>
  );
};

export default CoverImage;
