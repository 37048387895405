import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { OverlayPreloader } from "../common/preloader";
import { actions as homeActions } from "../../actions/home";
import { actions as listingActions } from "../../actions/listing";
import { useDocTitle } from "../../helpers/hooks";
import { Notification, Notification as Note } from "../common/notification";
import { Listings } from "./listings/index";
import { Messages } from "./messages";
import { Questions } from "./questions";
import { ReferralRequests } from "./referralRequests";
import { AddListing } from "./addListing";
import { PaymentStripe } from "./payment";
import { Modals } from "./modals";
import styles from "./home.module.css";

const HomeComponent = ({ countries, states }) => {
  const dispatch = useDispatch();
  const getDashboardInfo = useCallback(() => dispatch(homeActions.getDashboardInfo()), [dispatch]);
  const addListingAssistant = useCallback(data => dispatch(homeActions.addListingAssistant(data)), [dispatch]);
  const removeListingAssistantSuccess = useCallback(() => dispatch(homeActions.removeListingAssistantSuccess()), [dispatch]);
  const clearDashboardData = useCallback(() => homeActions.clearDashboardData(), []);
  const clearAllModals = useCallback(() => dispatch(listingActions.clearAllModals()), [dispatch]);
  const clearErrors = useCallback(() => dispatch(listingActions.clearErrors()), [dispatch]);
  const toggleActivityOfModal = (modal, isActive) => dispatch(listingActions.toggleActivityOfModal(modal, isActive));

  const { client } = useSelector(state => state.main, shallowEqual);
  const { success, preloaders: listingPreloaders, error } = useSelector(state => state.listing);
  const { preloaders, successListingAssistant } = useSelector(state => state.home);
  const { dashboardData } = useSelector(state => state.home, shallowEqual);
  const { listings } = dashboardData;
  const [itemToEdit, setItemToEdit] = useState(null);
  const [notes, setNotes] = useState({ deleteSuccess: false });

  const {
    globalInfo: {
      listings_count: listingsCount,
      referral_requests_pending_import: referralRequestsPendingImport,
      referral_requests_count: referralRequestsCount,
      new_messages: newMessages,
      unread_messages: unreadMessages
    }
  } = useSelector(state => state.main);

  const isShowOnlyListingBanner =
    String(unreadMessages) === "0" &&
    String(listingsCount) === "0" &&
    String(referralRequestsPendingImport) === "0" &&
    String(referralRequestsCount) === "0" &&
    String(newMessages) === "0";

  useDocTitle(client ? "dashboard_home_clientview_metatitle" : "dashboard_home_metatitle");

  useEffect(() => {
    getDashboardInfo();

    return () => {
      clearDashboardData();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (success.deleteMembership) {
      setNotes({ deleteSuccess: true });
      setTimeout(() => setNotes({ deleteSuccess: false }), 7000);
    }
  }, [success]); // eslint-disable-line

  const openModal = (modalName, index) => {
    if (Number.isInteger(index)) {
      setItemToEdit(listings[index]);
    }

    toggleActivityOfModal(modalName, true);
  };

  const closeModal = modalName => {
    toggleActivityOfModal(modalName, false);
  };

  return (
    <React.Fragment>
      {notes.deleteSuccess && <Note success>Listing Deleted</Note>}
      {success.downgradeSuccess && <Note success>Downgrade Successful</Note>}
      {(preloaders.dashboard || listingPreloaders.memberships) && <OverlayPreloader overlayClassName={styles.overlay} />}
      {error && (
        <Notification error time={4000} close={clearErrors}>
          {error}
        </Notification>
      )}

      <PaymentStripe countries={countries} closeModal={closeModal} clearAllModals={clearAllModals} />

      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.rightSide}>
            <AddListing
              addListingAssistant={addListingAssistant}
              loading={preloaders.listingAssistant}
              successListingAssistant={successListingAssistant}
              removeListingAssistantSuccess={removeListingAssistantSuccess}
            />
            {!isShowOnlyListingBanner && (
              <>
                <Listings listings={listings} openModal={openModal} listingPreloaders={listingPreloaders} />
                <Messages />
                <ReferralRequests states={states} />
                <Questions />
              </>
            )}
          </div>
          {/*
          {!isShowOnlyListingBanner && <Sidebar />}
          */}
        </div>
      </div>

      <Modals itemToEdit={itemToEdit} openModal={openModal} closeModal={closeModal} setItemToEdit={setItemToEdit} countries={countries} />
    </React.Fragment>
  );
};

export default HomeComponent;
