import React from "react";
import cn from "classnames";

import styles from "./tile.module.css";

const Tile = ({ className, children }) => {
  return <div className={cn(styles.tile, className)}>{children}</div>;
};

export default Tile;
