import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
// import cn from "classnames";

import styles from "./pageNames.module.css";

const PageNames = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const searchParams = queryString.parse(history.location.search);
  // const { pathname } = history.location;
  // const listingsName = "/listings/";
  // const listingsNameLength = listingsName.length;
  // const isListingDetailName = pathname.includes(listingsName) && pathname.length !== listingsNameLength;

  return (
    <div className={styles.main}>
      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/" exact>
        {/* {t("dashboard_header_nav_home")} */}
        Feed
      </NavLink>

      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/saved" exact>
        {/* {t("dashboard_header_nav_home")} */}
        Saved
      </NavLink>
      {/*
      <NavLink
        activeClassName={styles.activeLink}
        className={cn(styles.link, isListingDetailName ? styles.linkListingDetail : "")}
        to="/listings?page=0"
      >
        {isListingDetailName ? <>Listing</> : <>{t("dashboard_header_nav_listings")}</>}
      </NavLink>
      */}

      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/inbox">
        {searchParams.tab === "archived" ? "Archived messages" : "All messages"}
      </NavLink>
      {/*

      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/paid-leads">
        {t("dashboard_header_nav_leads")}
      </NavLink>
      */}

      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/question-answer">
        {t("dashboard_header_nav_qa")}
      </NavLink>

      {/*
      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/account">
        My account
      </NavLink>
      */}
      {/*
      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/analytics">
        Analytics
      </NavLink>


      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/payments">
        Payments
      </NavLink>
   
      <NavLink activeClassName={styles.activeLink} className={styles.link} to="/manage-notification">
        Manage notifications
      </NavLink>
      */}
    </div>
  );
};

export default PageNames;
