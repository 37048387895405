import React from "react";
import cn from "classnames";

import { customStyles as selectStyles } from "../../../constants/select";
import styles from "./select.module.css";
import SelectInput from "../../common/selectInput";

const SelectComponent = ({
  options,
  name,
  error,
  label,
  isInvalid,
  inputId,
  handleBlur,
  handleChange,
  isRequired,
  value,
  getOptionValue,
  getOptionLabel,
  classNameWrap,
  placeholder,
  noOptionsMessage,
  classNameError
}) => {
  return (
    <SelectInput
      classNameWrap={cn(styles.selectWrap, classNameWrap)}
      className={styles.select}
      classNameInvalid={styles.selectInvalid}
      error={error}
      isInvalid={isInvalid}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      inputId={inputId}
      label={label}
      name={name}
      options={options}
      onBlur={handleBlur}
      onChange={handleChange}
      required={isRequired}
      customStyles={selectStyles}
      value={value}
      placeholder={placeholder}
      isNewSelectedColor
      classNameError={cn(styles.errorText, classNameError)}
      noOptionsMessage={noOptionsMessage}
    />
  );
};

export default SelectComponent;
