import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { disableScroll, enableScroll } from "../../../helpers";
import Checkbox from "../../common/checkbox";
import { OverlayPreloader } from "../../common/preloader";
import styles from "./filter.module.css";

const Filter = ({
  setActiveFilter,
  loading,
  appliedCount,
  practices,
  listActivePractice,
  setListActivePractice,
  setListActiveNetwork,
  listActiveNetwork,
  directories
}) => {
  useEffect(() => {
    disableScroll();

    return () => {
      enableScroll();
    };
  }, []);
  const isEmptyFilters = listActivePractice.length || listActiveNetwork.length;
  const { t } = useTranslation();

  const resetFilters = () => {
    setListActivePractice([]);
    setListActiveNetwork([]);
  };

  const getActiveDirectory = name => {
    return listActiveNetwork.includes(name);
  };

  const onCheckDirectory = name => {
    if (getActiveDirectory(name)) {
      const newOptions = listActiveNetwork.filter(item => item !== name);
      setListActiveNetwork(newOptions);
    } else {
      setListActiveNetwork([...listActiveNetwork, name]);
    }
  };

  const getAllActiveDirectory = () => {
    return listActiveNetwork && listActiveNetwork.length === directories.length;
  };

  const handleAllDirectory = () => {
    if (getAllActiveDirectory()) {
      setListActiveNetwork([]);
    } else {
      const ids = directories.map(item => item.dir_id);

      setListActiveNetwork(ids);
    }
  };

  const directoriesList = directories?.map(({ dir_id, title }) => {
    return (
      <div key={`${dir_id}-${title}`}>
        <Checkbox
          id={title}
          checked={getActiveDirectory(dir_id)}
          onChange={() => onCheckDirectory(dir_id)}
          big
          className={styles.checkboxLabel}
          key={dir_id}
        >
          {title}
        </Checkbox>
      </div>
    );
  });

  const renderListingDirectories = () => {
    return (
      <>
        <h6 className={styles.categoriesSubTitle}>Directories</h6>

        {directoriesList}

        <div key="profession_group_id">
          <Checkbox
            id="profession_group_id"
            checked={getAllActiveDirectory()}
            onChange={handleAllDirectory}
            big
            className={styles.checkboxLabel}
          >
            All
          </Checkbox>
        </div>
      </>
    );
  };

  const getActiveSpecialty = name => {
    return listActivePractice.includes(name);
  };

  const onCheckSpecialty = name => {
    if (getActiveSpecialty(name)) {
      const newOptions = listActivePractice.filter(item => item !== name);
      setListActivePractice(newOptions);
    } else {
      setListActivePractice([...listActivePractice, name]);
    }
  };

  const getAllActiveSpecialty = () => {
    return listActivePractice && listActivePractice.length === practices.length;
  };

  const handleAllSpecialty = () => {
    if (getAllActiveSpecialty()) {
      setListActivePractice([]);
    } else {
      const ids = practices.map(item => item.practice_id);

      setListActivePractice(ids);
    }
  };

  const practicesList = practices?.map(({ practice_id, title }) => {
    return (
      <div key={`${practice_id}-${title}`}>
        <Checkbox
          id={title}
          checked={getActiveSpecialty(practice_id)}
          onChange={() => onCheckSpecialty(practice_id)}
          big
          className={styles.checkboxLabel}
          key={practice_id}
        >
          {title}
        </Checkbox>
      </div>
    );
  });

  const renderListingSpecialty = () => {
    return (
      <>
        <h6 className={styles.categoriesSubTitle}>Specialty</h6>

        {practicesList}

        <div key="profession_group_id">
          <Checkbox
            id="profession_group_id"
            checked={getAllActiveSpecialty()}
            onChange={handleAllSpecialty}
            big
            className={styles.checkboxLabel}
          >
            All
          </Checkbox>
        </div>
      </>
    );
  };

  return (
    <div className={styles.filter}>
      {loading && <OverlayPreloader />}
      <div className={styles.filterContent}>
        <div className={styles.filterHeading}>
          <h4 className={styles.filterTitle}>Filters</h4>
          <button type="button" className={styles.filterClose} onClick={() => setActiveFilter(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path d="M13 1L1 13" stroke="#1C1D40" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M1 1L13 13" stroke="#1C1D40" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
        <div className={styles.filterContainer}>
          <div className={styles.categories}>
            <>{renderListingSpecialty()}</>
            <>{renderListingDirectories()}</>
          </div>
        </div>
        <div className={styles.filterActions}>
          <button type="button" className={styles.filterResetAll} disabled={!isEmptyFilters} onClick={resetFilters}>
            Reset All
          </button>
          <button type="button" className={styles.filterApply} onClick={() => setActiveFilter(false)} disabled={!isEmptyFilters}>
            {t("dashboard_common_button_apply")} <>{appliedCount ? <>({appliedCount})</> : ""}</>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
