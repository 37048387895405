import React from "react";
import { Link } from "react-router-dom";

import logo from "../../../media/icons/logo.svg";

export const Logo = () => {
  return (
    <Link to="/" className="header-logo-top">
      <img src={logo} alt="home" />
    </Link>
  );
};
