import React, { useCallback } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { SidebarTile } from "../sidebarTile";
import sideImage from "../../../media/images/publicPeople.png";
import styles from "./sidebar.module.css";
import { actions as mainActions } from "../../../actions/main";

export const Sidebar = ({ className }) => {
  const { t } = useTranslation();
  const {
    globalInfo: {
      // listings_count: listingsCount,
      referral_requests_pending_import: referralRequestsPendingImport,
      referral_requests_count: referralRequestsCount
    }
  } = useSelector(state => state.main);

  const isShowReferralRequestItem = Number(referralRequestsPendingImport) > 0 || Number(referralRequestsCount) > 0;
  // const isShowPaidLeadsItem = Number(listingsCount) > 0;
  const dispatch = useDispatch();

  const toggleNewListing = useCallback(isActive => dispatch(mainActions.toggleNewListing(isActive)), [dispatch]);
  const handleOpenNewListing = () => {
    toggleNewListing(true);
  };

  return (
    <aside className={cn(styles.aside, className)}>
      <SidebarTile
        title={t("dashboard_sidebar_block1_title")}
        text={t("dashboard_sidebar_block1_description")}
        linkName={t("dashboard_sidebar_block1_button")}
        img={sideImage}
        isButton
        onClick={handleOpenNewListing}
      />

      {isShowReferralRequestItem && (
        <SidebarTile
          title={t("dashboard_sidebar_block2_title")}
          text={t("dashboard_sidebar_block2_description")}
          linkName={t("dashboard_sidebar_block2_button")}
          linkUrl="https://help.heritageweb.com/en/collections/2597815-paid-leads-service"
          isLink
        />
      )}
      {/*
      {isShowPaidLeadsItem && (
        <SidebarTile
          title={t("dashboard_sidebar_block3_title")}
          text={t("dashboard_sidebar_block3_description")}
          linkName={t("dashboard_sidebar_block3_button")}
          linkUrl="https://help.heritageweb.com/en/collections/2597815-paid-leads-service"
          isLink
        />
      )}
      */}
    </aside>
  );
};
