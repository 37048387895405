import React from "react";
import { useSelector } from "react-redux";

import Checkbox from "../../../common/checkbox";
import { FILTERS } from "../../../../constants/questionsAnswers";
import { WEBSITE } from "../../../../constants/regex";
import styles from "./categories.module.css";

const areEqual = (prevProps, nextProps) => {
  return nextProps.activeFilters.categories.length === prevProps.activeFilters.categories.length;
};

export const Categories = React.memo(({ activeFilters, handleFilter }) => {
  const questions = useSelector(state => state.questions);
  const { qa, categories } = questions;
  const questionCategories = [...new Set(qa?.map(question => question.website_url.replace(WEBSITE, "").split("/")[0]))];
  const categoriesForFilter = categories?.filter(category => questionCategories.includes(category.title.toLowerCase()) && category);

  if (!categoriesForFilter?.length) {
    return null;
  }

  return (
    <ul className={styles.ulList}>
      <li className={styles.ulHead}>Network</li>
      <li>
        <ul className={styles.ulList}>
          {categoriesForFilter.map(({ category_id: id, title }) => (
            <li key={id}>
              <Checkbox
                checked={activeFilters.categories.includes(String(id)?.toLowerCase())}
                className={styles.checkbox}
                id={title}
                name={title}
                onChange={() => handleFilter(FILTERS.CATEGORIES, String(id)?.toLowerCase())}
              >
                {title}
              </Checkbox>
            </li>
          ))}
        </ul>
      </li>
    </ul>
  );
}, areEqual);
