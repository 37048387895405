import React from "react";

import { ReactComponent as ClockIcon } from "../../../media/icons/clock.svg";
import styles from "./timezones.module.css";

const TimeZone = () => {
  return (
    <div className={styles.content}>
      <h3 className={styles.title}>_Time zone</h3>

      <div className={styles.description}>
        <p>Pacific time (US & Canada)</p>
        <p>
          <ClockIcon />
          11:46 pm for Ryan Howard
        </p>
      </div>
    </div>
  );
};

export default TimeZone;
